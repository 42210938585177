import styled from "@emotion/styled";
import { Box, Grid } from "@mui/material";
import React, { useEffect } from "react";
import { Card } from "../../components";
import useAxios from "../../hooks/useAxios";
import { useState } from "react";


function Dashboard() {
  const SummaryItem = styled(Box)(({ theme }) => ({}));
  const [data, setData] = useState({
    total_application: 0,
    application_finished: 0,
    application_in_progress: 0,
    application_not_started: 0,
    application_rejected_by_admin: 0,
    application_declined_by_driver: 0,
    application_more_than_two_months_old: 0
  })
  const axiosApi = useAxios()

  useEffect(() => {
    axiosApi('/company/dashboard').then(res => setData(res))
  }, [])



  function finishedPercentage(partialValue, totalValue) {
    if (totalValue === 0) return 0
    return (100 * partialValue) / totalValue;
  }




  return (
    <>
      <Box sx={{ margin: { xs: "0 30px" } }}>
        {/* <Button onClick={RefreshToken}>Click</Button> */}
        <Grid container spacing={3}>
          <Grid item md={4} xs={12}>
            <SummaryItem>
              <Card
                titleNumber={data?.total_application}
                title="Total Applications Sent"
                percentagePending={data?.total_application - data?.application_finished}
                percentageTotal={parseInt(finishedPercentage(data?.application_finished, data?.total_application))}
                percentageBarValue={finishedPercentage(data?.application_finished, data?.total_application)}
              />
            </SummaryItem>
          </Grid>
          <Grid item md={4} xs={12}>
            <SummaryItem>
              <Card
                titleNumber={data?.application_finished}
                title="Total Applications Finished"
                percentagePending={data?.total_application - data?.application_finished}
                percentageTotal={parseInt(finishedPercentage(data?.application_finished, data?.total_application))}
                percentageBarValue={finishedPercentage(data?.application_finished, data?.total_application)}
              />
            </SummaryItem>
          </Grid>
          <Grid item md={4} xs={12}>
            <SummaryItem>
              <Card
                titleNumber={data?.application_in_progress}
                title="Applications In Progress"
                percentagePending={data?.total_application - data?.application_in_progress}
                percentageTotal={parseInt(finishedPercentage(data?.application_in_progress, data?.total_application))}
                percentageBarValue={finishedPercentage(data?.application_in_progress, data?.total_application)}
              />
            </SummaryItem>
          </Grid>
          <Grid item md={4} xs={12}>
            <SummaryItem>
              <Card
                titleNumber={data?.application_not_started}
                title="Applications Not Started"
                percentagePending={data?.application_not_started}
                percentageTotal={parseInt(finishedPercentage(data?.application_not_started, data?.total_application))}
                percentageBarValue={finishedPercentage(data?.application_not_started, data?.total_application)}
              />
            </SummaryItem>
          </Grid>
          <Grid item md={4} xs={12}>
            <SummaryItem>
              <Card
                titleNumber={data?.application_rejected_by_admin}
                title="Applications Rejected By Admin"
                percentagePending={21}
                percentageTotal={parseInt(finishedPercentage(data?.application_rejected_by_admin, data?.total_application))}
                percentageBarValue={finishedPercentage(data?.application_rejected_by_admin, data?.total_application)}
              />
            </SummaryItem>
          </Grid>
          <Grid item md={4} xs={12}>
            <SummaryItem>
              <Card
                titleNumber={data?.application_declined_by_driver}
                title="Applications Declined By Driver"
                percentagePending={21}
                percentageTotal={parseInt(finishedPercentage(data?.application_declined_by_driver, data?.total_application))}
                percentageBarValue={finishedPercentage(data?.application_declined_by_driver, data?.total_application)}
              />
            </SummaryItem>
          </Grid>
          <Grid item md={4} xs={12}>
            <SummaryItem>
              <Card
                titleNumber={data?.application_more_than_two_months_old}
                title="Applications More Than Two Months old"
                // percentagePending={21}
                percentageTotal={parseInt(finishedPercentage(data?.application_more_than_two_months_old, data?.total_application))}
                percentageBarValue={finishedPercentage(data?.application_more_than_two_months_old, data?.total_application)}
              />
            </SummaryItem>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default Dashboard;
