import React, { useRef, useState } from "react";
import * as yup from "yup";
import { Grid, Box, Typography, Button } from "@mui/material";

import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import InputField from "../../components/FormikControl/Text_input/TextInput";
import { Add, Remove } from "@mui/icons-material";
import styled from "@emotion/styled";
import { Formik, Form, FieldArray } from "formik";
import PhoneNumber from "../../components/FormikControl/PhoneNumber/PhoneNumber";
import ControlledAccordions from "../../components/FormikControl/Accordian/Accordian";
import { SelectField } from "../../components/FormikControl/Select_input/Select";
import useAxios from "../../hooks/useAxios";
import { ApplicantLoginActions } from '../../redux/reducers/ApplicantLogin-Slice'
import { useDispatch } from "react-redux";
import { ModalButton } from "../../components";
import { getAllCountries, getStates } from "../../assets/json/country";


import { toast } from "react-toastify";

const PersonalForm = ({ auth, initValue, disableForm, setvalue }) => {

  const [previous_addressID, setPrevious_addressID] = useState([])

  // initValue.previous_address.map((item) => item.id)

  const formikRef = useRef();
  const dispatch = useDispatch()
  const AxiosApi = useAxios();

  console.log(previous_addressID)
  const PersonalFormValidation = yup.object().shape({
    user: yup.object({
      phone: yup.string().required("Required"),
      last_name: yup.string().required("Required"),
      email: yup.string().email("Invalid email").required("Required"),
    }),
    dob: yup.string().required("Required"),
    country: yup.string().required("Required"),
    current_address: yup.object({
      line_1: yup.string().required("Required"),
      city: yup.string().required("Required"),
      state: yup.string().required("Required"),
      country: yup.string().required("Required"),
      zipcode: yup
        .string()
        .matches(/[0-9]/, "Enter Zipcode")
        .required("Required"),
      residence_from: yup.string().required("Required"),
    }),
    previous_address: yup.array().of(yup.object({
      line_1: yup.string().required("Required"),
      city: yup.string().required("Required"),
      state: yup.string().required("Required"),
      country: yup.string().required("Required"),
      zipcode: yup
        .string()
        .matches(/[0-9]/, "Enter Zipcode")
        .required("Required"),
      residence_from: yup.string().required("Required"),
      residence_to: yup.string().required("Required"),
    })),
  });




  function ReturnNull(value) {
    return value === "" ? null : value;
  }

  const modifyResponse = (res) => {
    return JSON.parse(JSON.stringify(res).replace(/\:null/gi, "\:\"\"")) /* eslint-disable-line */
  }

  const handleNext = (values) => {
    let id = values.id;
    let payload_obj = {
      id: values.id,
      user: {
        ...values.user,
        phone: ReturnNull(values.user.phone),
        first_name: values.user.first_name,
        middle_name: values.user.middle_name,
        last_name: values.user.last_name,
        email: values.user.email,
      },
      status: initValue.status === "completed" ? "completed" : "in_progress",
      pseudo_name: values.pseudo_name,
      mothers_maiden_name: values.mothers_maiden_name,
      other_email: values.other_email,
      dob: ReturnNull(values.dob),
      country: values.country,
      current_address: {
        ...values.current_address,
        residence_from: ReturnNull(values.current_address.residence_from),
        residence_to: null,
      },
      previous_address: values?.previous_address?.map((item) => (
        {
          ...item,
          residence_from: ReturnNull(item.residence_from),
          residence_to: ReturnNull(item.residence_to)
        }
      )),
      delete_previous_address: previous_addressID,
      permanent_address: {
        ...values.permanent_address,
        residence_from: ReturnNull(values.permanent_address.residence_from),
        residence_to: null,
      },
    };

    AxiosApi.patch(`/application/${id}/`, payload_obj).then((res) => {
      dispatch(ApplicantLoginActions.applicationData(modifyResponse(res)))
      setvalue(1);
    });
  };

  const checkForError = () => {
    let obj = formikRef?.current?.errors ? formikRef?.current?.errors : {};

    if (Object.keys(obj).length !== 0) {
      toast.error("Please Fill Required Fields");
    }
  };

  const updateDelete = (id) => {
    if (id) setPrevious_addressID([...previous_addressID, id])
  }


  const updatedCountries = getAllCountries().map((country) => ({
    title: country,
    value: country,
  }));


  const updatedStates = (countryId) => {
    const List = getStates(countryId)[0];
    return List.states.map((item) => ({
      title: item,
      value: item,
    }));
  };

  const Item = styled(Box)({});

  return (
    <Box>
      <Formik
        initialValues={initValue}
        validationSchema={PersonalFormValidation}
        onSubmit={handleNext}
        innerRef={formikRef}
      >
        {(formik) => (
          <Form>
            <Box
              sx={{
                padding: "0.9rem",
                borderRadius: "10px",
                marginBottom: "2rem",
                backgroundColor: "#fff",

                // boxShadow: "0 5px 32px 5px #f6f6f6",
              }}
            >
              <Typography
                sx={{
                  fontSize: "1.2rem",
                  marginLeft: "0.5rem",
                  marginBottom: "1rem",
                }}
              >
                Personal Information
              </Typography>
              <Grid
                container
                sx={{ justifyContent: "space-between" }}
                rowGap={1.5}
              >
                <InputField
                  name="user.first_name"
                  label="First Name*"
                  placeholder="Enter First Name"
                  disabled={true}
                />
                <InputField
                  name="user.middle_name"
                  label="Middle Name"
                  placeholder="Enter Middle Name"
                  disabled={disableForm}
                />
                <InputField
                  name="user.last_name"
                  label="Last Name*"
                  placeholder="Enter Last Name"
                  disabled={disableForm}
                />
                <InputField
                  name="pseudo_name"
                  label="Pseudo Name(Optional)"
                  placeholder="Enter Pseudo Name"
                  disabled={disableForm}
                />
                <InputField
                  name="mothers_maiden_name"
                  label="Mother's Maiden Name(Optional)"
                  placeholder="Enter Mother's Maiden Name"
                  disabled={disableForm}
                />
                <InputField
                  name="dob"
                  label="Date of Birth*"
                  type="date"
                  disabled={disableForm}
                />
                <InputField
                  name="user.email"
                  label="Email Address*"
                  placeholder="Enter Email Address"
                  disabled={true}
                />
                <InputField
                  name="other_email"
                  label="Alternate Email Address"
                  placeholder="Alternate Email Address"
                  disabled={disableForm}
                />
                <PhoneNumber
                  name="user.phone"
                  label="Phone Number*"
                  handleChange={(e) => formik.setFieldValue("user.phone", e)}
                  disable={disableForm}
                />
                <SelectField
                  name="country"
                  label="Country*"
                  options={updatedCountries}
                  placeholder="Select Country"
                  disabled={disableForm}
                />
              </Grid>
            </Box>
            <Box sx={{ borderRadius: "10px" }}>
              <ControlledAccordions
                name="current_address"
                title="Current Address*"
                subTitle={`Click to ${disableForm ? "view" : "add"
                  } Current Address`}
                formik={{ ...formik }}
                cardState={true}
                disabled={disableForm}
                initCountry={initValue?.current_address?.country}
              />
              <FieldArray name="previous_address">
                {({ push, remove }) => (
                  formik.values?.previous_address.length > 0 &&
                  formik.values?.previous_address.map((value, index) => {
                    return (
                      <Box key={index} boxShadow={1} sx={{ borderRadius: "5px", marginBottom: '2rem' }}>
                        <Grid
                          container
                          sx={{ justifyContent: "space-between", borderRadius: "20px", padding: { xs: "0.8rem", md: "2rem" } }}
                          rowGap={1.5}
                          key={index}

                        >
                          <Grid item xs={12} >
                            <Typography
                              sx={{ fontWeight: "600", color: "rgba(0, 0, 0, 0.6)", paddingLeft: { xs: "0.4rem", md: "0.5rem" } }}
                            >
                              Previous Address [ {index + 1} ]
                            </Typography>
                          </Grid>

                          <InputField
                            name={`previous_address.${index}.line_1`}
                            label="Address Line 1*"
                            placeholder={"Enter Address"}
                            disabled={disableForm}
                          />

                          <InputField
                            name={`previous_address.${index}.line_2`}
                            label="Address Line 2"
                            placeholder={"Enter Address"}
                            disabled={disableForm}
                          />
                          <SelectField
                            name={`previous_address.${index}.country`}
                            label="Country*"
                            options={updatedCountries}
                            formik={{ ...formik }}
                            disabled={disableForm}
                            setCountryFieldArray={true}
                          />
                          <SelectField
                            name={`previous_address.${index}.state`}
                            label="State*"
                            options={formik?.values?.previous_address[index].country ? updatedStates(formik?.values?.previous_address[index].country) : []}
                            disabled={disableForm}
                          />
                          <InputField
                            name={`previous_address.${index}.city`}
                            label="City*"
                            placeholder={"Enter City"}
                            disabled={disableForm}
                          />
                          <InputField
                            name={`previous_address.${index}.zipcode`}
                            label="Zipcode*"
                            placeholder={"Enter Zipcode"}
                            disabled={disableForm}
                            type="number"
                          />
                          <InputField
                            name={`previous_address.${index}.residence_from`}
                            label="Residence From*"
                            type="date"
                            disabled={disableForm}
                          />
                          <InputField
                            name={`previous_address.${index}.residence_to`}
                            label="Residence To*"
                            type="date"
                            disabled={disableForm}
                          />
                          <Grid container>
                            <Grid item xs={12} md={6}>
                              <Item sx={{
                                display: 'flex',
                                justifyContent: { xs: 'flex-end', md: 'flex-start' }
                              }}>
                                {formik.values?.previous_address.length > 1 ?
                                  <Button
                                    variant="outlined"
                                    endIcon={<Remove />}

                                    sx={{
                                      fontSize: { xs: "0.7rem", md: "1rem" },
                                      color: "#d32f2f",
                                      border: "1px solid #d32f2f",
                                      borderRadius: "20px",
                                      '&:hover': {
                                        border: "1px solid #d32f2f",
                                        background: '#d32f2f',
                                        color: '#fff'
                                      },
                                      marginTop: '10px',
                                      textTransform: 'none'

                                    }}
                                    onClick={() => { updateDelete(value?.id); remove(index) }}>Remove Previous Address [ {index + 1} ]</Button>
                                  : null}
                              </Item>



                            </Grid>
                            <Grid item xs={12} md={6}>
                              <Item sx={{
                                display: 'flex',
                                justifyContent: 'flex-end'
                              }}>
                                <Button
                                  variant="contained"
                                  endIcon={<Add />}
                                  sx={{
                                    fontSize: { xs: "0.7rem", md: "1rem" },

                                    background:
                                      "linear-gradient(90deg,  rgba(0,41,185,1) 0%, rgba(2,119,250,1) 100%)",
                                    // width: "100%",
                                    borderRadius: "20px",
                                    color: "#fff",
                                    marginTop: '10px',

                                    // height: "2.6em",
                                  }}
                                  onClick={() => push({
                                    line_1: "",
                                    line_2: "",
                                    city: "",
                                    state: "",
                                    country: "",
                                    zipcode: "",
                                    residence_from: "",
                                    residence_to: "",
                                  })}>Add Previous Address</Button>
                              </Item>


                            </Grid>

                          </Grid>
                        </Grid>
                      </ Box>
                    )
                  })
                )}

              </FieldArray>

              <ControlledAccordions
                name="permanent_address"
                title="Permanent Address"
                subTitle={`Click to ${disableForm ? "view" : "add"
                  } Permanent Address`}
                formik={{ ...formik }}
                disabled={disableForm}
                initCountry={initValue?.permanent_address?.country}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                width: { xs: "100%", md: "100%" },
                justifyContent: "space-between",
                margin: "0 0 0 auto",
                padding: "0 10px 20px 10px",
              }}
            >

              {disableForm ? (
                <Button
                  variant="contained"
                  endIcon={<KeyboardDoubleArrowRightIcon />}
                  sx={{
                    fontSize: { xs: "0.9rem", md: "1rem" },
                    margin: "0 0 0 auto",
                    background:
                      "linear-gradient(90deg,  rgba(0,41,185,1) 0%, rgba(2,119,250,1) 100%)",
                    width: { xs: "100%", md: "fit-content" },
                    borderRadius: "20px",
                    color: "#fff",
                    height: "2.6em",
                  }}
                  onClick={() => setvalue(1)}
                >
                  Next
                </Button>
              ) : (
                <Button
                  variant="contained"
                  endIcon={<KeyboardDoubleArrowRightIcon />}
                  sx={{
                    fontSize: { xs: "0.9rem", md: "1rem" },
                    margin: "0 0 0 auto",
                    background:
                      "linear-gradient(90deg,  rgba(0,41,185,1) 0%, rgba(2,119,250,1) 100%)",
                    width: { xs: "100%", md: "fit-content" },
                    borderRadius: "20px",
                    color: "#fff",
                    height: "2.6em",

                    // padding: { xs: "20px 10px" },
                  }}
                  onClick={checkForError}
                  type="submit"
                >
                  Save & Next
                </Button>
              )}
            </Box>
            {auth.role[0] === "A" ? <Box sx={{ width: { xs: "100%", md: "fit-content" }, padding: "0 10px 20px 10px", }}> <ModalButton /></Box> : null}
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default PersonalForm;
