import { createTheme } from "@mui/material";

export const theme = createTheme({
  palette: {
    primary: {
      main: "#1760a5",
      light: "skyblue",
      button: "#0277fa",
    },
    custom: {
      main: "#eaeaea",
      white: "#fff",
    },
  },
});
