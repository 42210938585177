import React, { useState } from 'react';
import Popover from '@mui/material/Popover';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import { changeStatus, changeStatusEmployees } from '../../redux/thunks/ManageApplicants-thunk'
import { useDispatch } from "react-redux";
import { createAction } from '../../redux/thunks/ManageApplicants-thunk';
import useAxios from '../../hooks/useAxios';
import { ManageUserActions } from '../../redux/reducers/ManageUser-Slice';
import { useNavigate } from "react-router-dom";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import { ThemeActions } from '../../redux/reducers/Theme-Slice'



export default function BasicPopover({ popOverProps, id, setvalue, type, name, application, document }) {

    const [anchorEl, setAnchorEl] = useState(null);
    const AxiosApi = useAxios()



    const navigate = useNavigate()

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const _popid = open ? 'simple-popover' : undefined;

    const dispatch = useDispatch()


    //  Prop Sample-------------------------------------------------------------------------------------------------------
    // const PopOver = {
    //     set_1: [
    //         { icon: <CheckCircleIcon sx={{ color: 'green' }} />, text: 'Approve' },
    //         { icon: <CancelIcon sx={{ color: '#d1060a' }} />, text: 'Reject' },
    //     ],
    //     set_2: [
    //         { icon: <EditIcon />, text: 'Edit' },
    //         { icon: <CircleNotificationsIcon sx={{ color: '#e8c91c' }} />, text: 'Alert' },
    //         { icon: <RestartAltIcon />, text: 'Rest' }
    //     ]

    // }

    const handleAction = (text, status, rehire) => {
        switch (text) {
            case 'Invite Application': {
                navigate("/inviteApplication", { state: { user: id, applicant: false } })
                break;
            }
            case 'Onboard Application': {
                navigate("/onboardApplication", { state: { user: id, applicant: false, name: name } })
                break;
            }
            case 'Invite Details': {
                navigate("/inviteApplication", { state: { user: application, applicant: false } })
                break;
            }
            case 'Onboard Details': {
                navigate("/onboardApplication", { state: { user: application, applicant: false, name: name } })
                break;
            }
            case 'Approve': {
                changeStatus(status, id, dispatch)
                break;
            }
            case 'Hired': {
                changeStatusEmployees(status, application, dispatch)
                break;
            }
            case 'Active': {
                changeStatusEmployees(status, application, dispatch)
                break;
            }

            case 'Rehire': {
                dispatch(ThemeActions.setModel({ showModel: true, id: application, type: text }))
                break;
            }

            case 'PIP Document': {
                dispatch(ThemeActions.setModel({ showModel: true, id: id, type: text, document: document }))
                break;
            }
            case 'Inactive': {
                changeStatusEmployees(status, application, dispatch)
                break;
            }
            case 'Terminate': {
                changeStatusEmployees(status, application, dispatch)
                break;
            }
            case 'Reject': {
                changeStatus(status, id, dispatch)
                break;
            }
            case 'Alert': {
                createAction(text, id, dispatch)
                break;
            }
            case 'Reset': {
                createAction(text, id, dispatch)
                break;
            }
            case 'Delete': {
                createAction(text, id, dispatch)
                break;
            }
            case 'Edit': {
                AxiosApi.get(`/company/hr/?id=${id}`).then((res) => {
                    dispatch(ManageUserActions.userData(res))
                    setvalue(1)
                })
                break;
            }

        }
    }

    return (
        <>
            <Button sx={{
                textTransform: "capitalize",
                fontSize: { xs: "0.9rem", md: "1rem" },
                padding: "0 0.3rem",
                borderRadius: "0.5rem",
                width: { xs: "100%", md: "fit-content" },
            }} onClick={handleClick} endIcon={type ? <KeyboardDoubleArrowRightIcon /> : ''}>
                {
                    type ?
                        'View Application'
                        : <MoreHorizIcon aria-describedby={id} />
                }

            </Button>
            <Popover
                id={_popid}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}

            >
                <Box>
                    <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                        {popOverProps.set_1.length && popOverProps.set_1.map((item, index) => (
                            <ListItem disablePadding key={index} >
                                <ListItemButton onClick={() => handleAction(item.text, item?.status, item?.rehire_status)}>
                                    <ListItemIcon sx={{ minWidth: '35px' }}>
                                        {item.icon}
                                    </ListItemIcon>
                                    <ListItemText primary={item.text} />
                                </ListItemButton>
                            </ListItem>
                        ))}
                        <Divider />
                        {popOverProps.set_2.length && popOverProps.set_2.map((item, index) => (
                            <ListItem disablePadding key={index}>
                                <ListItemButton onClick={() => handleAction(item.text, item?.status)} >
                                    <ListItemIcon sx={{ minWidth: '35px' }}>
                                        {item.icon}
                                    </ListItemIcon>
                                    <ListItemText primary={item.text} />
                                </ListItemButton>
                            </ListItem>
                        ))}
                    </List>
                </Box>
            </Popover>
        </ >
    );
}
