import React from 'react'
import { actionAxios } from "../../hooks/useAxios";

import {
    Box,
    Button,
    InputLabel,
    Modal,
    Stack,
    Typography,
    TextField
} from "@mui/material";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ThemeActions } from '../../redux/reducers/Theme-Slice'

function DeclineReasonModel() {
    const [reason, setReason] = useState('');

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const applicationData = useSelector(
        (state) => state.ApplicantLogin.applicationData
    );
    const decline_model = useSelector((state) => state.ThemeSlice.decline_model);





    const ResonField = () => {
        const handleChange = (val) => {
            setReason(val.target.value)
        }
        return (
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: { xs: 300, md: 400 },
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 2,
                    borderRadius: '20px'
                }}
            >
                <Typography
                    variant="h6"
                    sx={{ textAlign: "center", marginBottom: "10px", fontSize: { xs: '0.9rem', sm: '1rem' } }}
                >
                    Are you sure you want to cancel the Application?
                </Typography>
                <InputLabel sx={{ paddingLeft: "0.5rem", fontWeight: "600", fontSize: { xs: '0.9rem', sm: '1rem' } }}>
                    Reason to cancel
                </InputLabel>
                <TextField
                    fullWidth
                    id="filled-multiline-static"
                    multiline
                    rows={3}
                    placeholder={'Reason'}
                    InputProps={{
                        style: {
                            borderRadius: "20px",
                            backgroundColor: "#E8F3FE",
                            width: "100%",
                        },
                    }}
                    sx={{
                        variant: "outlined",
                        autoComplete: "off",
                        size: "small",
                        backgroundColor: "#E8F3FE",
                        borderRadius: "20px",
                        marginBottom: "10px",
                    }}
                    onChange={handleChange}
                    value={reason}
                />
                <Stack
                    direction="row"
                    spacing={2}
                    justifyContent="flex-end"
                    alignItems="center"
                >
                    <Button
                        variant="contained"
                        sx={{
                            background:
                                "linear-gradient(90deg,  rgba(0,41,185,1) 0%, rgba(2,119,250,1) 100%)",
                            borderRadius: "20px",
                            color: "#fff",
                            height: "2.6em",
                        }}
                        onClick={() => dispatch(ThemeActions.setDecline_model(false))}
                    >
                        No
                    </Button>
                    <Button
                        variant="outlined"
                        sx={{
                            borderRadius: "20px",
                            height: "2.6em",
                        }}
                        onClick={() =>
                            actionAxios
                                .patch(`/application/${applicationData.id}/`, {
                                    status: "declined",
                                    declined_reason: reason
                                })
                                .then(() => {
                                    dispatch(ThemeActions.setDecline_model(false))
                                    navigate("/application-status", { replace: true });
                                })
                        }
                    >
                        Yes
                    </Button>
                </Stack>
            </Box>
        )
    }






    return (
        <Modal
            open={decline_model}
            onClose={() => dispatch(ThemeActions.setDecline_model(false))}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            {ResonField()}
        </Modal>
    )
}

export default DeclineReasonModel