import { createSlice } from "@reduxjs/toolkit";


const initialState = {
    tableData: [],
    applicantData: [],
}

const ManageApplicantsSlice = createSlice({

    name: "ManageApplicants",
    initialState,
    reducers: {
        tableData(state, action) {
            state.tableData = action.payload
        },
        applicantData(state, action) {
            state.applicantData = action.payload
        },

    }

})


export const ManageApplicantActions = ManageApplicantsSlice.actions

export default ManageApplicantsSlice