import React, { useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useField } from "formik";
import { Grid, Box, InputLabel, Typography } from "@mui/material";
import "./phoneNumber.css";

function PhoneNumber({ label, name, handleChange, disabled }) {
  const [field, meta] = useField(name);
  const [phoneProp, setPhoneProp] = useState(meta);


  return (
    <Grid item xs={12} md={5}>
      <Grid item sx={{ width: "100%" }}>
        <Box>
          <InputLabel sx={{ paddingLeft: "0.5rem", fontWeight: "600" }}>
            {label}
          </InputLabel>
          <Box
            sx={{
              border:
                phoneProp.touched && meta.error ? "1px solid red" : "none",
              borderRadius: "0 20px 20px 0",
            }}
          >
            <PhoneInput
              {...field}
              country={"us"}
              value={field.value}
              onChange={(e) => handleChange(e)}
              onBlur={() => setPhoneProp({ ...meta, touched: true })}
              disabled={disabled}
            />
          </Box>
        </Box>
        {meta.error && (
          <Box>
            <Typography
              sx={{
                color: "#d32f2f",
                fontSize: "0.75rem",
                fontWeight: "400",
                marginLeft: "14px",
              }}
            >
              {meta.error}
            </Typography>
          </Box>
        )}
      </Grid>
    </Grid>
  );
}

export default PhoneNumber;
