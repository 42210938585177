import styled from "@emotion/styled";
import { Box, Button, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import MMSLogo from "../../../assets/images/mms-logo.png";
import { TextInput, PasswordInput, ButtonInput } from "../../../components";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import { Link } from "react-router-dom";
import useAxios from "../../../hooks/useAxios";
import CoverImage from "../../../assets/images/Cover.jpg";
import { useSelector } from "react-redux";

const FORGOT_PASSWORD_URL = "/accounts/password_reset/";

function ForgotPassword() {
  const AxiosApi = useAxios();

  const [message, setMessage] = useState("");

  const companyLogo = useSelector((state) => state.ThemeSlice?.companyLogo);

  const SUCCESS_MESSAGE = "Please check your mail to reset the password";

  const Item = styled(Box)({});
  const validationSchema = Yup.object({
    email: Yup.string().email("Enter Valid Email").required("Required").trim(),
  });
  const initialValues = {
    email: "",
  };

  return (
    <>
      <Box>
        <Grid container>
          <Grid item md={6}>
            <Item
              sx={{
                // justifyContent: "center",
                display: "flex",
                height: "100vh",
                flexDirection: "column",
              }}
            >
              <Box
                sx={{
                  width: "200px",
                  height: "200px",
                  margin: "0 auto 0 30px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Box
                  src={companyLogo ? companyLogo : MMSLogo}
                  component="img"
                  sx={{
                    width: !companyLogo ? "70px" : "200px",
                    maxHeight: "200px",
                    padding: "20px",
                    margin: "0 auto",
                  }}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  height: "100%",
                  flexDirection: "column",
                  width: "100%",
                  padding: "0 40px",
                  marginTop: "-70px",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: "600",
                    fontSize: "2.2em",
                    marginBottom: "10px",
                    color: "#3E3E3E",
                  }}
                >
                  Forgot Password
                </Typography>
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={(values) => {
                    AxiosApi.post(FORGOT_PASSWORD_URL, {
                      email: values.email.trim().toLowerCase(),
                    }).then(() => setMessage(SUCCESS_MESSAGE));
                  }}
                >
                  {(formik) => (
                    <Form>
                      <Box sx={{ marginBottom: "15px" }}></Box>
                      <Box sx={{ marginBottom: "20px" }}>
                        <TextInput
                          name="email"
                          type="email"
                          label="Email"
                          md={8}
                        />
                      </Box>
                      <ButtonInput
                        label="Forgot Password"
                        md={8}
                        type="submit"
                      />
                    </Form>
                  )}
                </Formik>
                <Typography
                  sx={{
                    marginTop: "30px",
                    color: "#000",
                    marginLeft: "20px",
                    color: "green",
                  }}
                >
                  {message}
                </Typography>
                <Link to="/login" style={{ textDecoration: "none" }}>
                  <Typography
                    sx={{
                      marginTop: "30px",
                      color: "#000",
                      marginLeft: "20px",
                    }}
                  >
                    Back To Login Page
                  </Typography>
                </Link>
              </Box>
            </Item>
          </Grid>
          <Grid item md={6}>
            <Item>
              <Box
                component="img"
                src={CoverImage}
                sx={{
                  width: "100%",
                  height: "100vh",
                  background: "rgba( 255, 255, 255, 0.1 )",
                  boxShadow: "0 8px 32px 0 rgba( 31, 38, 135, 0.37 )",

                  objectFit: "cover",
                  objectPosition: "60% 0%",
                }}
              />
            </Item>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default ForgotPassword;
