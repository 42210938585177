import { ManageApplicantActions } from '../reducers/ManageApplicants-Slice'
import { actionAxios } from "../../hooks/useAxios"
import { getUserTableData } from './ManageUsers-thunk'
import { toast } from 'react-toastify';
import { getCurrentEmployees } from '../thunks/ManageUsers-thunk';
import { ThemeActions } from '../reducers/Theme-Slice'


function humanize(str) {
    if (!str) return ''
    var i, frags = str.split('_');
    for (i = 0; i < frags.length; i++) {
        frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
    }
    return frags.join(' ');
}


function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}


const getTableData = () => {
    return async (dispatch) => {
        const modifyResponse = (res) => {
            let tempData = [];

            res.forEach((item) => (
                tempData.push({
                    id: item?.id,
                    name: `${capitalizeFirstLetter(item?.user?.first_name)} ${capitalizeFirstLetter(item?.user?.last_name)}`,
                    email: item?.user?.email,
                    status: humanize(item?.status),
                    invited: item?.created_at ? item?.created_at.split('T')[0] : '',
                    Declined: item?.declined_reason ? item?.declined_reason : 'Not Available'
                })
            ))
            return tempData;
        }

        const requests = async () => {
            let temData = []
            const endPoint = '/application/'
            temData = await actionAxios.get(endPoint)
                .then((res) => modifyResponse(res))
                .catch((err) => console.log(err))

            return temData;

        }

        await dispatch(ManageApplicantActions.tableData(await requests()))
    }
}

function getMessage(type) {
    switch (type) {
        case 'approved': {
            return 'Application Approved!!'
        }
        case 'rejected': {
            return 'Application Rejected!!'
        }
        case 'hired': {
            return 'Applicant Hired!!'
        }
        default: {
            return 'Success!!'
        }
    }
}

const changeStatus = async (status, id, dispatch) => {
    actionAxios.patch(`/application/${id}/`, { status: status, }).then(() => {
        dispatch(getTableData())
        toast.success(getMessage(status))

    })
}

const changeStatusEmployees = async (status, id, dispatch) => {
    actionAxios.patch(`/company/current_employee/`, { application: id, status: status, }).then(() => {
        dispatch(getCurrentEmployees())
        toast.success(getMessage(status))

    })
}

const changeRehireStatusEmployees = async (status, id, dispatch, handleClose, reason) => {
    actionAxios.patch(`/company/current_employee/`, { application: id, rehire_status: status, not_rehire_reason: reason }).then(() => {
        dispatch(getCurrentEmployees())
        handleClose()
        toast.success(getMessage(status))
    })
}

const createAction = async (type, id, dispatch) => {
    switch (type) {
        case 'Alert': {
            actionAxios.get(`/application/alert_applicant?app_id=${id}`).then(() => {
                dispatch(getTableData())
                toast.success('Alert Sent!!')
            })
            break;
        }

        case 'Reset': {
            actionAxios.get(`/application/reset_applicant?app_id=${id}`).then(() => {
                dispatch(getTableData())
                toast.success('Aplication Reset Success!!')
            })
            break;
        }

        case 'Delete': {
            actionAxios.delete(`/company/hr/${id}/`).then(() => {
                dispatch(getUserTableData())
                toast.success('Delete Success!!')
            })
            break;
        }

        default: {
            console.log('Cannot Identify type')
        }
    }

}

const getApplicantData = (id) => {
    return async (dispatch) => {


        const requests = async () => {

            const endPoint = `/application/${id}/`
            await actionAxios.get(endPoint)
                .then((res) => { return res; })
                .catch((err) => { console.log(err); return {}; })


        }

        await dispatch(ManageApplicantActions.applicantData(await requests()))
    }
}


export { getTableData, getApplicantData, changeStatus, createAction, changeStatusEmployees, changeRehireStatusEmployees }