import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { Box, Modal, Typography, Button, TextField } from '@mui/material';
import { ThemeActions } from '../../redux/reducers/Theme-Slice'
import { changeRehireStatusEmployees } from '../../redux/thunks/ManageApplicants-thunk'
import { toast } from 'react-toastify';
import UploadIcon from '@mui/icons-material/Upload';
import {
    SimpleDocumentInput
} from "../../components/FormikControl/Text_input/TextInput";
import useAxios from '../../hooks/useAxios';
import downloadFile from "../../utils/downloadFile";
import { ManageUserActions } from '../../redux/reducers/ManageUser-Slice'
import LoadingButton from '@mui/lab/LoadingButton';




function ConfirmationModel() {
    const [reason, setReason] = useState('')
    const [document, setDocument] = useState('')
    const [showReason, setShowReason] = useState(false)
    const [loading, setLoading] = useState(false)

    const AxiosApi = useAxios()

    const model = useSelector((state) => state.ThemeSlice.model);
    const dispatch = useDispatch()






    const handleClose = () => {
        if (reason) setReason('')
        if (showReason) setShowReason(false)
        if (document) setDocument('')
        dispatch(ThemeActions.setModel({ showModel: false }))
    }


    const RehireComponent = () => {


        const handleChange = (val) => {
            setReason(val.target.value)
        }

        const handleAction = (type) => {
            if (type === 'No' && !reason && showReason) return toast.warn('Provide Reason to Continue!')
            if (type === 'No' && !reason) return setShowReason(true)
            changeRehireStatusEmployees(type, model.id, dispatch, handleClose, reason)
        }
        return (
            <>
                <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ fontSize: '1.1rem', letterSpacing: '1px', textAlign: 'center', marginBottom: showReason ? '1rem' : '2rem' }}>
                    Employee good for Rehire?
                </Typography>
                {showReason ? <TextField
                    fullWidth
                    id="filled-multiline-static"
                    multiline
                    rows={3}
                    placeholder={"If No? Enter the Reason"}
                    value={reason}
                    onChange={handleChange}
                    InputProps={{
                        style: {
                            borderRadius: "20px",
                            backgroundColor: "#E8F3FE",
                            width: "100%",
                        },
                    }}
                    sx={{
                        variant: "outlined",
                        autoComplete: "off",
                        size: "small",
                        backgroundColor: "#E8F3FE",
                        borderRadius: "20px",
                        marginBottom: "10px",
                    }}
                /> : null}

                <Box sx={{ display: 'flex', justifyContent: 'space-between', width: { xs: '99%', md: '80%' }, margin: '0.5rem auto  0.5rem auto' }}>
                    {!showReason && <Button variant='contained' sx={{
                        width: '7rem', background:
                            "linear-gradient(90deg,  rgba(0,41,185,1) 0%, rgba(2,119,250,1) 100%)",
                        borderRadius: "10px",
                        color: "#fff",
                    }} onClick={() => handleAction('Yes')}>
                        Yes
                    </Button>}

                    <Button variant={showReason ? 'contained' : 'outlined'} sx={{
                        width: '7rem', background:
                            showReason ? "linear-gradient(90deg,  rgba(0,41,185,1) 0%, rgba(2,119,250,1) 100%)" : "",
                        borderRadius: "10px",
                        color: showReason ? "#fff" : "rgba(2,119,250,1)",
                        margin: showReason ? '0 auto' : ''
                    }} onClick={() => handleAction('No')}>
                        {showReason ? 'Save' : 'No'}
                    </Button>
                </Box>
            </>
        )
    }


    const documentComponent = () => {
        const handleUpload = () => {
            if (!document) return toast.warn('Select document to Continue!')
            setLoading(true)
            let formData = new FormData()
            formData.append('employee', model.id)
            formData.append('document', document)
            AxiosApi.post('/company/pip_document/', formData)
                .then((res) => {
                    setLoading(false)
                    dispatch(ManageUserActions.editCurrentEmployee({ id: model.id, res: res }))
                    handleClose()
                })
                .catch((err) => console.log(err))
        }

        const handleDowload = () => {
            if (model.document.length) downloadFile(
                `/company/pip_document/download/${model.document[0].id}`,
                model.document[0].document_filename,
                AxiosApi
            );
        }
        const handleDelete = () => {
            if (model.document.length) AxiosApi.delete(`/company/pip_document/?id=${model.document[0].id}`).then(() => {
                dispatch(ManageUserActions.editCurrentEmployee({ id: model.id, res: '' }))
                dispatch(ThemeActions.setModel({ ...model, document: [] }))
            })
        }
        return (
            <>
                <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ fontSize: '1.1rem', letterSpacing: '1px', textAlign: 'center', marginBottom: '1rem' }}>
                    PIP Document
                </Typography>
                <SimpleDocumentInput
                    id="pip_document"
                    name="pip_document"
                    label="Upload PIP Document"
                    type="file"
                    onChange={(e) => setDocument(e.target.files[0])}
                    deleteId={model.document.length ? model.document[0].id : ''}
                    doc_name={model.document.length ? model.document[0].document_filename : ''}
                    handleDowload={handleDowload}
                    handleDelete={handleDelete}
                />
                <Box sx={{ width: '100%', display: 'flex', marginTop: '1rem' }}>
                    <LoadingButton loading={loading} sx={{
                        width: '8.5rem',
                        background:
                            loading ? "" : "linear-gradient(90deg,  rgba(0,41,185,1) 0%, rgba(2,119,250,1) 100%)",
                        color: "#fff",
                        borderRadius: "10px",
                        margin: '0 auto'
                    }} variant="contained" startIcon={<UploadIcon />} loadingPosition="start" onClick={handleUpload}>
                        {loading ? 'Uploading..' : 'Upload'}
                    </LoadingButton>
                </Box>
            </>
        )
    }





    const ComponentDecider = (type) => {
        switch (type) {
            case 'Rehire': return RehireComponent()
            case 'PIP Document': return documentComponent()
            default: return null
        }
    }


    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: { xs: 300, md: 400 },
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 2,
        borderRadius: '20px'
    };




    return (
        <Modal
            open={model.showModel || false}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                {ComponentDecider(model.type)}
            </Box>
        </Modal>
    )
}

export default ConfirmationModel