import axios from "axios"
import jwt_decode from 'jwt-decode';
import dayjs from 'dayjs';
import useAuth from "./useAuth"
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux'
import { ThemeActions } from "../redux/reducers/Theme-Slice"




let actionAxios = null



const useAxios = () => {

    const dispatch = useDispatch()
    const { auth, setAuth, logoutUser } = useAuth()


    //-------- This function will automate the error in toast from backend------//

    const errorHandling = (errors) => {
        if (errors instanceof Object) {
            for (const error in errors) {
                errorHandling(errors[`${error}`])
            }
        }
        else if (Array.isArray(errors)) {
            errors.map((item) => toast.error(item))
        }
        else {
            toast.error(errors);
        }

    }

    //-------- This function will Refresh the token on expire------//

    async function RefreshToken() {
        let access = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/accounts/token/refresh/`, {
            refresh: auth?.refreshToken,
        }, {
            headers: {
                "Content-Type": "application/json",
                "LMD_HOST_NAME": window.location.hostname
            }
        }).catch((err) => logoutUser())
            .then((res) => {
                localStorage.setItem('accessToken', res?.data?.access)
                setAuth({
                    ...auth,
                    accessToken: res?.data?.access,
                })
                return res.data.access
            })
        return access;



    }



    //-------- Creating instance of axios------//

    let axiosAPI = axios.create({
        baseURL: process.env.REACT_APP_API_BASE_URL
    });




    //-------- This will intercept every api request before it get generates from this system----------//

    axiosAPI.interceptors.request.use(async (request) => {
        console.log("request api");
        dispatch(ThemeActions.setSpinner(true))

        if (auth.refreshToken) {
            let user = jwt_decode(auth?.accessToken)

            let isExpired = dayjs.unix(user.exp).diff(dayjs()) < 1;

            if (!isExpired) {
                request.headers["Authorization"] = 'Bearer ' + auth.accessToken
                request.headers["LMD_HOST_NAME"] = window.location.hostname
                return request
            }
            request.headers["Authorization"] = 'Bearer ' + await RefreshToken()

        }
        request.headers["LMD_HOST_NAME"] = window.location.hostname




        return request

    }, function (error) {
        return Promise.reject(error);
    });


    //-------- This will intercept every api response in this system----------//

    axiosAPI.interceptors.response.use((response) => {
        dispatch(ThemeActions.setSpinner(false))
        console.log("response api");
        return (response.data);
    }, (error) => {

        dispatch(ThemeActions.setSpinner(false))

        if (error?.response?.status >= 500 && error?.response?.status <= 599)
            toast.error("Server Error");
        else if (error?.response?.status === 404)
            toast.error("Not Found");
        // else if (error?.response?.data.detail === "Authentication credentials were not provided.")
        //     console.log("Authentication credentials were not provided.")
        else
            errorHandling({ ...error?.response?.data })

        return Promise.reject(error);
    });



    actionAxios = axiosAPI                            // creating axios instance for non react component 
    return axiosAPI

}


export { actionAxios }

export default useAxios