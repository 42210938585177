import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    spinner: false,
    notification: [],
    companyInfo: '',
    companyLogo: '',
    model: { showModel: false, id: '', type: '', document: [] },
    decline_model: false,
}

const ThemeSlice = createSlice({
    name: "theme",
    initialState,
    reducers: {
        setSpinner(state, action) {
            state.spinner = action.payload
        },
        setNotification(state, action) {
            state.notification = action.payload
        },
        setCompanyInfo(state, action) {
            state.companyInfo = action.payload
        },
        setCompanyLogo(state, action) {
            state.companyLogo = action.payload
        },
        setModel(state, action) {
            state.model = action.payload
        },
        setDecline_model(state, action) {
            state.decline_model = action.payload
        },

    }
})


export const ThemeActions = ThemeSlice.actions

export default ThemeSlice