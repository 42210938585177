import React from 'react'
import { actionAxios } from '../hooks/useAxios';



function blobToDataURL(blob, callback) {
  var a = new FileReader();
  a.onload = function (e) {
    callback(e.target.result);
  };
  a.readAsDataURL(blob);
}




function downloadFile(url, name, AxiosApi) {

  let fileName = name?.slice(name.lastIndexOf("-") + 1)

  AxiosApi.get(url, {
    responseType: "blob",
    docName: fileName
  })
    .then((response) => {
      // const url = URL.createObjectURL(new Blob([response]));
      // console.log("download type: ",typeof new Blob([response]));
      // const link = document.createElement('a');
      // link.href = url;
      // link.download = fileName
      // link.click();




      blobToDataURL(new Blob([response], { type: response.type }), function (dataurl) {

        const link = document.createElement('a')
        link.href = dataurl
        link.download = fileName
        link.click();
      });


    })

}

export default downloadFile