import styled from "@emotion/styled";
import { Edit } from "@mui/icons-material";
import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { getApplicationData } from "../../redux/thunks/ApplicantLogin-thunk";
import Logo from "../../assets/images/loader.gif";
import useAuth from "../../hooks/useAuth";

function ApplicationStatus() {
  const { auth } = useAuth();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getApplicationData("Applicant"));
  }, []);

  const companyLogo = useSelector(
    (state) => state.ThemeSlice?.companyLogo
  );

  const applicationData = useSelector(
    (state) => state.ApplicantLogin.applicationData
  );

  const Item = styled(Box)({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "1rem",
  });
  function capitalizeFirstLetter(str) {
    console.log(str)
    if (!str) return ''
    var i, frags = str.split('_');
    for (i = 0; i < frags.length; i++) {
      frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
    }
    return frags.join(' ');
  }


  const closeTab = () => { window.opener = null; window.open("", "_self", ''); window.close(); };




  return (
    <>
      <Box sx={{ padding: "0 1rem 1rem 1rem" }}>
        {auth.role[0] === "A" ? (
          <Box
            component="img"
            src={companyLogo ? companyLogo : Logo}
            sx={{
              padding: "10px 5px",
              width: companyLogo ? "150px" : "70px",
            }}
          />
        ) : null}

        <Box
          sx={{
            background: "rgba( 255, 255, 255, 0.1 )",

            boxShadow: "0 5px 32px 5px #e2e4f8",

            backdropFilter: "blur( 20px )",
            webkitBackdropFilter: "blur( 20px )",
            borderRadius: "10px",
            border: "1px solid rgba( 255, 255, 255, 0.18 )",
            margin: { xs: "0 10px 20px 10px", sm: "0 30px 20px 30px" },
            padding: { xs: "20px 0", md: "20px 50px" },
            borderRadius: "10px",

            height: "auto",
          }}
        >
          <Grid container>
            <Grid item xs={12}>
              <Item>
                {applicationData.status === "completed" ? (
                  <Typography
                    component={"p"}
                    variant={"h6"}
                    sx={{
                      marginRight: { xs: "1rem", md: "3rem" },
                      marginLeft: { xs: "1rem", md: "3rem" },
                      fontSize: { xs: "1.1rem", md: "1.5rem" },
                      color: "green",
                    }}
                  >
                    Form Submitted Successfully
                  </Typography>
                ) : (
                  ""
                )}
                {applicationData.status === "declined" ||
                  applicationData.status === "rejected" ? (
                  <Typography
                    component={"p"}
                    variant={"h6"}
                    sx={{
                      marginRight: { xs: "1rem", md: "3rem" },
                      marginLeft: { xs: "1rem", md: "3rem" },
                      fontSize: { xs: "1.1rem", md: "1.5rem" },
                      color: "red",
                    }}
                  >
                    {`Form ${capitalizeFirstLetter(applicationData.status)}`}
                  </Typography>
                ) : null}
              </Item>
            </Grid>
            {applicationData.status === "completed" || applicationData.status === "on_boarded" ? (
              <Grid item xs={12}>
                <Item>
                  <Typography
                    component={"p"}
                    sx={{
                      textAlign: "center",

                      marginRight: { xs: "1rem", md: "3rem" },
                      marginLeft: { xs: "1rem", md: "3rem" },
                      fontSize: { xs: "1.1rem", md: "1.5rem" },
                    }}
                  >
                    Thank you for submitting the form. We’ll get back to you
                    soon
                  </Typography>
                </Item>
              </Grid>
            ) : null}
            <Grid item xs={12}>
              <Item>
                <Typography
                  component={"p"}
                  sx={{
                    marginRight: { xs: "1rem", md: "3rem" },
                    marginLeft: { xs: "1rem", md: "3rem" },
                    fontSize: { xs: "1.1rem", md: "1.3rem" },
                  }}
                >
                  Application Status : <b>{capitalizeFirstLetter(applicationData.status)}</b>
                </Typography>
              </Item>
            </Grid>
            <Box sx={{ minHeight: "50px" }}>{""}</Box>
          </Grid>
          <Stack
            direction="row"
            spacing={2}
            justifyContent="center"
            alignItems="center"
          >
            {applicationData.status === "completed" ? (
              <Link to="/inviteApplication" style={{ textDecoration: "none" }}>
                <Button
                  variant="contained"
                  sx={{
                    background:
                      "linear-gradient(90deg,  rgba(0,41,185,1) 0%, rgba(2,119,250,1) 100%)",
                    borderRadius: "20px",
                    color: "#fff",
                    height: "2.6em",
                  }}
                  onClick={() => { }}
                  endIcon={<Edit />}
                >
                  Edit Application
                </Button>
              </Link>
            ) : null}
            {applicationData.status === "on_boarded" ? (
              <Link to="/onboardApplication" style={{ textDecoration: "none" }}>
                <Button
                  variant="contained"
                  sx={{
                    background:
                      "linear-gradient(90deg,  rgba(0,41,185,1) 0%, rgba(2,119,250,1) 100%)",
                    borderRadius: "20px",
                    color: "#fff",
                    height: "2.6em",
                  }}
                  onClick={() => { }}
                  endIcon={<Edit />}
                >
                  Edit Application
                </Button>
              </Link>
            ) : null}

            <Button
              variant="outlined"
              sx={{
                borderRadius: "20px",
                height: "2.6em",
              }}
              onClick={closeTab}
            >
              Close
            </Button>
          </Stack>
        </Box>
      </Box>
    </>
  );
}

export default ApplicationStatus;
