import React, { useState } from "react";
import * as yup from "yup";
import { Grid, Box, Typography, Button } from "@mui/material";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import InputField from "../../components/FormikControl/Text_input/TextInput";
import { Formik, Form, FieldArray } from "formik";
import { SelectField } from "../../components/FormikControl/Select_input/Select";
import useAxios from "../../hooks/useAxios";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useDispatch, useSelector } from "react-redux";

import {
  ModalButton,
  RadioButtonInput,
  RadioButtonSelect,
} from "../../components";

import Checkbox from "@mui/material/Checkbox";
import styled from "@emotion/styled";
import { KeyboardDoubleArrowLeft } from "@mui/icons-material";
import { ApplicantLoginActions } from '../../redux/reducers/ApplicantLogin-Slice'

function ExperienceForm({
  auth,
  initValue,
  disabled,
  setvalue,
}) {

  const dispatch = useDispatch()

  const [showExperience, setShowExperience] = useState(initValue.details.cmvd_since ? true : false);

  const applicationData = useSelector((state) => state.ApplicantLogin.applicationData);

  const EmploymentHistoryFormValidation = yup.object().shape({
    details: yup.object({
      work_previous: yup.string().required("Required"),
      other_work_previous: yup.string().when('work_previous', {
        is: 'Other',
        then: yup.string()
          .required('Required'),
        otherwise: yup.string(),
      }),
      cmvd_since: yup.string().required("Required"),
      cmve: yup.string().required("Required"),
    }),

    equipment_experience: yup.array().of(
      yup.object({
        equipment_type: yup.string().required("Required"),
        drive_from: yup.string().required("Required"),
        drive_to: yup.string().required("Required"),
      })),
  });



  const AxiosApi = useAxios();




  const handleNext = (values) => {
    if (initValue?.details?.cmvd_since) {
      AxiosApi.put(`/application/experience/${values.id}`, values).then((res) => {
        dispatch(ApplicantLoginActions.experience(res))
        setvalue(2);
      })
    } else {
      let obj = { ...values, applicant: applicationData?.id }
      AxiosApi.post(`/application/experience`, auth.role[0] === "A" ? obj : values).then((res) => {
        dispatch(ApplicantLoginActions.experience(res))
        setvalue(2);
      })
    }
  };


  const equipmentTypes = [
    {
      value: "box_truck",
      title: "Box Truck",
    },
    {
      value: "sprinter_van",
      title: "Sprinter Van",
    },
    {
      value: "step_van",
      title: "Step Van",
    },
    {
      value: "uber",
      title: "Uber",
    },
  ];

  const handleChange = (e, push, remove, formik, type) => {
    const { value } = e.target;
    if (e.target.checked) {
      push({
        equipment_type: value,
        drive_from: "",
        drive_to: ""
      },);

      formik?.setFieldValue('details.sb_eqp', [...formik.values.details.sb_eqp, value])
    } else {
      formik?.values?.equipment_experience.map(
        (value, index) => value.equipment_type === type && remove(index)
      );
      formik?.setFieldValue('details.sb_eqp', [...formik.values.details.sb_eqp.filter((item) => item !== value)])
    }
  };



  const Item = styled(Box)({});



  const handleEmptyNext = () => {
    if (showExperience === false) setvalue(2)
  }

  return (
    <Box>
      <Formik
        initialValues={initValue}
        validationSchema={EmploymentHistoryFormValidation}
        onSubmit={handleNext}
      >
        {(formik) => (
          <Form>
            <Box
              sx={{
                padding: "0.9rem",
                borderRadius: "10px",
                marginBottom: "2rem",
                backgroundColor: "#fff",
              }}
            >
              {console.log(formik)}
              <Typography
                sx={{
                  fontSize: "1.2rem",
                  marginLeft: "0.5rem",
                  marginBottom: "1rem",
                }}
              >
                Driving and Equipment Experience
              </Typography>
              <RadioButtonSelect
                setShowField={setShowExperience}
                name={`question_1`}
                RadioText="Do you have any professional Experience?"
                optionOne="Yes"
                optionTwo="No"
                defaultValue={initValue.details.cmvd_since ? 'Yes' : 'No'}
                disabled={disabled}
              />
              {showExperience ? (
                <Grid
                  container
                  sx={{ justifyContent: "space-between" }}
                  rowGap={1.5}
                >
                  <RadioButtonInput
                    name={`details.work_previous`}
                    RadioText="Have you worked previously with the following?"
                    defaultValue={formik.values?.details?.work_previous}
                    optionOne="Amazon"
                    optionTwo="FedEx"
                    optionThree="Other"
                    optionFour="None"
                    formik={{ ...formik }}
                    disabled={disabled}
                  />
                  {formik.values?.details?.work_previous === 'Other' ? (
                    <Grid item xs={12}>
                      <InputField
                        name="details.other_work_previous"
                        label="Other*"
                        placeholder="Other Experience"
                        disabled={disabled}
                      />
                    </Grid>
                  ) : null}
                  <Grid item xs={12}>
                    <InputField
                      name="details.cmvd_since"
                      label="Commercial Motor Vehicle Driver Since(Enter Year)*"
                      placeholder="YYYY"
                      disabled={disabled}
                      type="number"
                    />
                  </Grid>
                  <InputField
                    name="details.cmve"
                    label="Years of Commercial Motor Vehicle Experience*"
                    placeholder="Number of Years"
                    disabled={disabled}
                    type="number"
                  />

                  <Grid item xs={12}>
                    <Typography
                      sx={{
                        fontSize: "1.1rem",
                        fontWeight: "500",
                        margin: "1.4rem 0 0 0.5rem",
                      }}
                    >
                      Select the type of equipment you have operated
                    </Typography>
                  </Grid>

                  <FieldArray name="equipment_experience">
                    {({ push, remove }) => (
                      <>
                        <Grid item xs={12} sm={12}>
                          <Item
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              flexDirection: { xs: "column", md: "row" },
                            }}
                          >

                            <FormControlLabel
                              control={
                                <Checkbox
                                  name={`box_truck`}
                                  checked={formik.values?.details?.sb_eqp.includes('box_truck')}
                                  onChange={(e) =>
                                    handleChange(
                                      e,
                                      push,
                                      remove,
                                      formik,
                                      "box_truck"
                                    )
                                  }
                                  value="box_truck"
                                />
                              }
                              label="Box Truck"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  onChange={(e) =>
                                    handleChange(
                                      e,
                                      push,
                                      remove,
                                      formik,
                                      "sprinter_van"
                                    )
                                  }
                                  name={`sprinter_van`}
                                  value="sprinter_van"
                                  checked={formik.values?.details?.sb_eqp.includes('sprinter_van')}
                                />
                              }
                              label="Sprinter Van"
                            />

                            <FormControlLabel
                              control={
                                <Checkbox
                                  onChange={(e) =>
                                    handleChange(
                                      e,
                                      push,
                                      remove,
                                      formik,
                                      "step_van"
                                    )
                                  }
                                  name={`step_van`}
                                  value="step_van"
                                  checked={formik.values?.details?.sb_eqp.includes('step_van')}
                                />
                              }
                              label="Step-Van"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  onChange={(e) =>
                                    handleChange(
                                      e,
                                      push,
                                      remove,
                                      formik,
                                      "uber"
                                    )
                                  }
                                  name={`uber`}
                                  value="uber"
                                  checked={formik.values?.details?.sb_eqp.includes('uber')}
                                />
                              }
                              label="Uber"
                            />

                          </Item>
                        </Grid>

                        {formik?.values?.equipment_experience.length > 0 &&
                          formik?.values?.equipment_experience.map((value, index) => {
                            return (
                              <Grid container key={index}>
                                <Grid item xs={12} sm={12}>
                                  <Item
                                    sx={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <Typography
                                      sx={{
                                        fontSize: "1.1rem",
                                        fontWeight: "500",
                                        margin: "1.4rem 0 0.8rem 0.5rem",
                                      }}
                                    >
                                      Equipment : {index + 1}
                                    </Typography>
                                  </Item>
                                </Grid>
                                <Grid item xs={12}>
                                  <Item
                                    sx={{
                                      display: "flex",
                                      flexDirection: "column",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <SelectField
                                      name={`equipment_experience.${index}.equipment_type`}
                                      label="Equipment Type"
                                      options={equipmentTypes}
                                      disabled={true}
                                    />

                                    <InputField
                                      name={`equipment_experience.${index}.drive_from`}
                                      label="From*"
                                      type="date"
                                      disabled={disabled}
                                    />
                                    <InputField
                                      name={`equipment_experience.${index}.drive_to`}
                                      label="To*"
                                      type="date"
                                      disabled={disabled}
                                    />
                                  </Item>
                                </Grid>
                              </Grid>
                            );
                          })}
                      </>
                    )}
                  </FieldArray>
                </Grid>
              ) : null}
            </Box>

            <Box
              sx={{
                display: "flex",
                width: { xs: "100%", md: "100%" },
                justifyContent: "space-between",
                margin: "0 0 0 auto",
                padding: "0 10px 20px 10px",
                flexDirection: { xs: "column", md: "row" },
              }}
            >


              {disabled ? (
                <>
                  <Item
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                    }}
                  >
                    <Button
                      variant="contained"
                      startIcon={<KeyboardDoubleArrowLeft />}
                      sx={{
                        fontSize: { xs: "0.9rem", md: "1rem" },
                        margin: "0 0 0 auto",
                        background:
                          "linear-gradient(90deg,  rgba(0,41,185,1) 0%, rgba(2,119,250,1) 100%)",
                        width: { xs: "100%", md: "fit-content" },
                        borderRadius: "20px",
                        color: "#fff",
                        height: "2.6em",
                      }}
                      onClick={() => setvalue(0)}
                    >
                      Previous
                    </Button>
                  </Item>
                  <Item
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    <Button
                      variant="contained"
                      endIcon={<KeyboardDoubleArrowRightIcon />}
                      sx={{
                        fontSize: { xs: "0.9rem", md: "1rem" },
                        margin: { xs: "1rem 0 0 auto", md: "0 0 0 auto" },
                        background:
                          "linear-gradient(90deg,  rgba(0,41,185,1) 0%, rgba(2,119,250,1) 100%)",
                        width: { xs: "100%", md: "fit-content" },
                        borderRadius: "20px",
                        color: "#fff",
                        height: "2.6em",
                      }}
                      onClick={() => setvalue(2)}
                    >
                      Next
                    </Button>
                  </Item>
                </>
              ) : (
                <>
                  <Item
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                    }}
                  >
                    <Button
                      variant="contained"
                      startIcon={<KeyboardDoubleArrowLeft />}
                      sx={{
                        fontSize: { xs: "0.9rem", md: "1rem" },
                        margin: "0 0 0 auto",
                        background:
                          "linear-gradient(90deg,  rgba(0,41,185,1) 0%, rgba(2,119,250,1) 100%)",
                        width: { xs: "100%", md: "fit-content" },
                        borderRadius: "20px",
                        color: "#fff",
                        height: "2.6em",
                      }}
                      onClick={() => setvalue(0)}
                    >
                      Previous
                    </Button>
                  </Item>
                  <Item
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    <Button
                      variant="contained"
                      endIcon={<KeyboardDoubleArrowRightIcon />}
                      sx={{
                        fontSize: { xs: "0.9rem", md: "1rem" },
                        margin: { xs: "1rem 0 0 auto", md: "0 0 0 auto" },
                        background:
                          "linear-gradient(90deg,  rgba(0,41,185,1) 0%, rgba(2,119,250,1) 100%)",
                        borderRadius: "20px",
                        width: { xs: "100%", md: "fit-content" },
                        color: "#fff",
                        height: "2.6em",
                      }}
                      onClick={handleEmptyNext}
                      type="submit"
                    >
                      Save & Next
                    </Button>
                  </Item>
                </>
              )}
            </Box>
            {auth.role[0] === "A" ?
              <Box sx={{ width: { xs: "100%", md: "fit-content" }, padding: "0 10px 20px 10px", }}>
                <ModalButton />
              </Box>

              : null}
          </Form>
        )}
      </Formik>
    </Box>
  );
}

export default ExperienceForm;
