var CryptoJS = require("crypto-js");


//------------------ Encrypt--------------------//

function encrypt(data) {
    var ciphertext = CryptoJS.AES.encrypt(JSON.stringify(data), process.env.REACT_APP_STORAGE_SECRET_KEY)?.toString();
    return ciphertext
}


//------------------ Decrypt---------------------//

function decrypt(ciphertext) {
    var bytes = CryptoJS.AES.decrypt(ciphertext?.toString(), process.env.REACT_APP_STORAGE_SECRET_KEY);
    var decryptedData = JSON.parse(bytes?.toString(CryptoJS.enc.Utf8));
    return decryptedData
}



export { encrypt, decrypt }