import { createSlice } from "@reduxjs/toolkit";


const initialState = {
    tableData: [],
    userData: '',
    currentEmployees: []
}

const ManageUsersSlice = createSlice({

    name: "ManageUsers",
    initialState,
    reducers: {
        tableData(state, action) {
            state.tableData = action.payload
        },
        userData(state, action) {
            state.userData = action.payload
        },
        currentEmployees(state, action) {
            state.currentEmployees = action.payload
        },
        editCurrentEmployee(state, action) {
            const removeDoc = (data) => {
                let temp = []
                state.currentEmployees.forEach((item) => {
                    if (item.id === data.id) {
                        temp.push({ ...item, pip_document: [] })
                    } else {
                        temp.push(item)
                    }
                })
                console.log(temp)
                state.currentEmployees = temp
            }

            const addDoc = (data) => {
                let temp = []
                state.currentEmployees.forEach((item) => {
                    if (item.id === data.id) {
                        temp.push({ ...item, pip_document: [data.res] })
                    } else {
                        temp.push(item)
                    }
                })
                console.log(temp)
                state.currentEmployees = temp
            }


            if (state.currentEmployees.length) {
                if (action.payload.res) return addDoc(action.payload)
                if (!action.payload.res) return removeDoc(action.payload)
            }
        }

    }

})


export const ManageUserActions = ManageUsersSlice.actions

export default ManageUsersSlice