import React from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import styled from "@emotion/styled";
import MoreOptionIcon from "../../components/PopOver/PopOver";
import { useNavigate } from "react-router-dom";
import ContactMailIcon from '@mui/icons-material/ContactMail';
import ArticleIcon from '@mui/icons-material/Article';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';


function GridTable({ tableProps, setvalue }) {
  const navigate = useNavigate();
  const { tableHead, tableBody, viewApplication, popOver, type, md, smTitleWide = '22%' } =
    tableProps;

  const BodyTypography = styled(Typography)({
    fontWeight: "500",
    textAlign: "center",
    wordBreak: "break-all"
  });
  const RehireTypography = styled(Typography)({
    fontWeight: "500",
    textAlign: "center",
    wordBreak: "break-all",
    cursor: 'pointer'
  });

  const TitleTypography = styled(Typography)({
    fontSize: { md: "1rem", lg: "1.2rem" },
    fontWeight: "600",
    marginRight: "0.5rem",
  });

  const capitalizeString = (str) => {
    var i, frags = str.split('_');
    for (i = 0; i < frags.length; i++) {
      frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
    }
    return frags.join(' ');
  };

  function _objectWithoutProperties(obj, keys) {
    var target = {};
    for (var i in obj) {
      if (keys.indexOf(i) >= 0) continue;
      if (!Object.prototype.hasOwnProperty.call(obj, i)) continue;
      target[i] = obj[i];
    }
    return target;
  }





  const PopOverForm = {
    set_1: [
      {
        icon: <ContactMailIcon sx={{ color: "#1760a5" }} />,
        text: "Invite Application",
        status: "invite",
      },
    ],
    set_2: [
      {
        icon: <ArticleIcon sx={{ color: "#1760a5" }} />,
        text: "Onboard Application",
        status: "onboard",
      },
    ],
  };


  const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#eaeaea',
      color: 'rgba(0, 0, 0, 0.87)',
      boxShadow: theme.shadows[1],
      fontSize: 13,

    },
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
    },
  }));

  return (
    <Box sx={{ height: { xs: "100vh", md: "60vh" } }}>
      <Grid container sx={{ marginTop: "1.5rem" }}>
        <Grid
          container
          sx={{
            backgroundColor: "#c1cbd6",
            borderRadius: "0.3rem",
            padding: "0.5rem 1rem",
            display: { xs: "none", sm: "none", md: "flex" },
          }}
        >
          {tableHead.map(({ title, wide }, index) => (
            <Grid item md={wide} key={index}>
              <Typography
                component={"p"}
                sx={{
                  fontSize: { md: "0.8rem", lg: "1rem" },
                  fontWeight: "500",
                  textAlign: "center",
                }}
              >
                {title}
              </Typography>
            </Grid>
          ))}
        </Grid>
        <Grid
          container
          sx={{
            marginTop: "0.5rem",
            maxHeight: { xs: "100vh", md: "50vh" },
            overflowY: "auto",
            overflowX: "hidden",
            paddingBottom: { xs: "1rem", md: "0" },
            alignItems: "flex-start",
          }}
          rowGap={{ xs: 3, md: 1 }}
        >
          {tableBody.map((item, index) => {
            let newItem = _objectWithoutProperties(item, ["id", "not_rehire_reason", "application", "pip_document", "Declined"]);
            return (
              <Grid
                container
                sx={{
                  padding: {
                    xs: "0.2rem 0rem 0.2rem 0.3rem",
                    md: "0.2rem 0rem 0.2rem 1rem",
                  },
                  margin: { xs: "0 auto", md: "0 0" },
                  borderRadius: { xs: "0.5rem", md: "0" },
                  width: { xs: "93%", md: "100%" },
                  "&:hover": {
                    background: "rgb(193, 203, 214, 0.3)",
                  },
                  alignItems: "center",
                  alignSelf: "start",
                }}
                key={index}
                rowSpacing={{ xs: 1, md: 0 }}
                boxShadow={{ xs: 2, md: 0 }}
              >
                <Grid item md={0.5} xs={12}>
                  <Box sx={{ display: { xs: "flex", md: "block" } }}>
                    <TitleTypography
                      component={"p"}
                      sx={{
                        display: { md: "none" },
                        textAlign: "right",
                        width: smTitleWide,
                        fontSize: { xs: "0.7rem", md: "0.8rem" },
                      }}
                    >
                      {"SL. No:"}
                    </TitleTypography>
                    <BodyTypography
                      component={"p"}
                      sx={{
                        fontSize: { xs: "0.7rem", md: "0.95rem" },
                        textAlign: { xs: "left", md: "center" },
                      }}
                    >
                      {index + 1}
                    </BodyTypography>
                  </Box>
                </Grid>
                {Object.keys(newItem).map((keys, num) => (
                  <Grid item md={md[num]} xs={12} key={num}>
                    <Box
                      sx={{
                        display: { xs: "flex", md: "block" },
                        alignItems: "center",
                      }}
                    >
                      <TitleTypography
                        component={"p"}
                        sx={{
                          display: { md: "none" },
                          width: smTitleWide,
                          textAlign: "right",
                          fontSize: { xs: "0.7rem", md: "1rem" },
                        }}
                      >{`${capitalizeString(keys)} :`}</TitleTypography>
                      {item[keys] === 'No' || item[keys] === 'Declined' ?
                        <LightTooltip title={item?.not_rehire_reason || item?.Declined}>
                          <RehireTypography
                            component={"p"}
                            sx={{
                              fontSize: { xs: "0.7rem", md: "0.8rem" },
                              textAlign: { xs: "left", md: "center" },
                            }}

                          >
                            {item[keys]}
                          </RehireTypography>
                        </LightTooltip>
                        : <BodyTypography
                          component={"p"}
                          sx={{
                            fontSize: { xs: "0.7rem", md: "0.8rem" },
                            textAlign: { xs: "left", md: "center" },
                          }}
                        >
                          {item[keys]}
                        </BodyTypography>}

                    </Box>
                  </Grid>
                ))}

                {viewApplication && (
                  <Grid
                    item
                    md={2}
                    xs={9}
                    sx={{ display: "flex", justifyContent: "center" }}

                  >
                    <MoreOptionIcon popOverProps={PopOverForm} type="form" id={item?.id} name={item?.name} />
                  </Grid>
                )}
                <Grid item md={1} xs={3}>
                  <MoreOptionIcon
                    popOverProps={popOver}
                    id={item.id}
                    setvalue={setvalue}
                    application={item?.application}
                    document={item?.pip_document}
                    name={item?.name}
                  />
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      </Grid>
    </Box>
  );
}

export default GridTable;
