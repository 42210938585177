import { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { decrypt } from "../utils/encrypt_decrypt";

const ProtectedRoute = (props) => {

    const allowedRoles = props.allowedRoles
    const [access, setAccess] = useState(true)

    let role = localStorage.getItem('role') ? decrypt(localStorage.getItem('role')) : []




    // -------This function will check if every role in array from backend is present in mentioned array in App.js  

    useEffect(() => {

        role.forEach(item => {
            if (!allowedRoles.includes(item))
                setAccess(false)
        })

    }, [])




    return (
        <>
            {access && <Outlet />}
        </>
    );
}

export default ProtectedRoute;