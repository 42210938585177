import ReactDOM from 'react-dom';
import styles from "./spinner.module.css"


import React from 'react'
import { useSelector } from "react-redux";

function Spinner() {


  const spinnerSlice = useSelector((state) => state.ThemeSlice)


  return ReactDOM.createPortal(spinnerSlice.spinner && <div className={styles.topmostDiv}> <div className={styles.parentDiv}><div className={styles.loader}></div>  </div></div>
    , document.getElementById("spinner_view"))

}

export default Spinner