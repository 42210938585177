import React, { useState } from "react";
import * as yup from "yup";
import { Grid, Box, Typography, Button } from "@mui/material";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import InputField, {
  DocumentInput,
  SingleDocumentInput,
} from "../../components/FormikControl/Text_input/TextInput";

import { Formik, Form } from "formik";

import useAxios from "../../hooks/useAxios";

import { useNavigate } from "react-router-dom";
import { ModalButton } from "../../components";

import { toast } from "react-toastify";

const DocumentForm = ({ auth, disableForm, applicantData, documentData }) => {
  const AxiosApi = useAxios();
  const navigate = useNavigate();

  const [SSN, setSSN] = useState("");
  const [birthCertificate, setBirthCertificate] = useState("");

  const [doc_id, setDoc_id] = useState({
    license_front: documentData?.license_front?.id,
    license_back: documentData?.license_back?.id,
    passport: documentData?.passport?.id,
    medical_certificate: documentData?.medical_certificate?.id,
    ssn: documentData?.ssn?.id,
    birth_certificate: documentData?.birth_certificate?.id,
  });

  const driver_license_init = {
    license_front: documentData?.license_front?.document_filename,
    license_back: documentData?.license_back?.document_filename,
    expiration_date: documentData?.license_front?.expiration_date,
  };

  const medical_init = {
    medical_certificate: documentData?.medical_certificate?.document_filename,
    expiration_date: documentData?.medical_certificate?.expiration_date,
  };

  const passport_init = {
    passport: documentData?.passport?.document_filename,
    passport_expiry: documentData?.passport?.expiration_date,
  };

  const other_document = {
    ssn: documentData?.ssn.document_filename,
    birth_certificate: documentData?.birth_certificate?.document_filename,
  };

  const single_document = {
    ssn: documentData?.ssn.document_filename,
    birth_certificate: documentData?.birth_certificate?.document_filename,
  };

  const license_Schema = yup.object({
    license_front: yup.string().required("Required"),
    license_back: yup.string().required("Required"),
    expiration_date: yup.string().required("Required"),
  });

  const passport_Schema = yup.object().shape(
    {
      passport: yup.string().when("passport_expiry", {
        is: (passport_expiry) => passport_expiry,
        then: yup
          .string()
          .required("Passport is required if passport expiry is entered"),
      }),
      passport_expiry: yup.string().when("passport", {
        is: (passport) => passport,
        then: yup
          .string()
          .required("Passport expiry is required if Passport is Uploaded"),
      }),
    },
    [["passport_expiry", "passport"]]
  );

  const medical_Schema = yup.object().shape(
    {
      medical_certificate: yup.string().when("expiration_date", {
        is: (expiration_date) => expiration_date,
        then: yup
          .string()
          .required(
            "Medical certificate is required if  medical certificate expiry is entered"
          ),
      }),
      expiration_date: yup.string().when("medical_certificate", {
        is: (medical_certificate) => medical_certificate,
        then: yup
          .string()
          .required(
            "Medical certificate expiry is required if medical certificate is Uploaded"
          ),
      }),
    },
    [["expiration_date", "medical_certificate"]]
  );

  const doclist = {
    a: documentData?.passport?.id ? documentData?.passport?.id : "",
    b: documentData?.ssn?.id ? documentData?.ssn?.id : "",
    c: documentData?.birth_certificate?.id
      ? documentData?.birth_certificate?.id
      : "",
    d: documentData?.medical_certificate?.id
      ? documentData?.medical_certificate?.id
      : "",
    f: documentData?.license_front?.id ? documentData?.license_front?.id : "",
  };

  const schema = yup.object().shape(
    {
      a: yup.string().when(["b", "c"], {
        is: (b, c) => b || c,
        then: yup.string(),
        otherwise: yup.string().required("Required"),
      }),
      b: yup.string().when(["a", "c"], {
        is: (a, c) => a || c,
        then: yup.string(),
        otherwise: yup.string().required("Required"),
      }),
      c: yup.string().when(["a", "b"], {
        is: (a, b) => a || b,
        then: yup.string(),
        otherwise: yup.string().required("Required"),
      }),
      f: yup.string().required("Required"),
    },
    [
      ["a", "b"],
      ["a", "c"],
      ["b", "c"],
    ]
  );

  // const schema = yup.object().shape({
  //   f: yup.string().required("Required"),
  // });


  const handleSingleDocument = (type, doc_formik, val, formik) => {
    let formData = new FormData();
    formData.append("type", type);
    formData.append("document", type === "ssn" ? SSN : birthCertificate);
    formData.append("applicant", applicantData?.id);


    AxiosApi.post("/application/document", formData)
      .then((res) => {
        if (type === "ssn") {
          setDoc_id((prev) => ({ ...prev, ssn: res.id }));
          formik.setFieldValue(type, SSN);
        }
        if (type === "birth_certificate") {
          setDoc_id((prev) => ({ ...prev, birth_certificate: res.id }));
          formik.setFieldValue(type, birthCertificate);
        }
        toast.success('Document Saved Successfully!')
        doc_formik.setFieldValue(val, "done");

      })
      .catch((err) => {
        if (type === "ssn") document.getElementById("ssn").value = "";
        if (type === "birth_certificate")
          document.getElementById("birth_certificate").value = "";
        if (type === "ssn") document.getElementById("ssn").value = "";
      });
  };

  const handleSubmitDocument = () => {
    AxiosApi.patch(`/application/${applicantData?.id}/`, {
      status: "completed",
    }).then(() => {
      if (auth.role[0] !== "A") {
        navigate(-1);
      } else {
        navigate("/application-status", { replace: true });
      }
    });
  };

  // console.log("________________________", SSN);

  return (
    <Formik
      initialValues={doclist}
      validationSchema={schema}
      onSubmit={handleSubmitDocument}
    >
      {(doc_formik) => (
        <Box>
          <Box
            sx={{
              padding: "0.9rem",
              borderRadius: "10px",
              marginBottom: "2rem",
              backgroundColor: "#fff",
              boxShadow: "0 5px 32px 5px #f6f6f6",
            }}
          >
            <Typography
              sx={{
                fontSize: "1.2rem",
                marginLeft: "0.5rem",
                marginBottom: "1rem",
                color: "#d32f2f",
              }}
            >
              Mandatory Document*
            </Typography>

            <Formik
              initialValues={driver_license_init}
              validationSchema={license_Schema}
              onSubmit={(value) => {

                let formData1 = new FormData();
                formData1.append("type", "license_front");
                formData1.append("expiration_date", value.expiration_date);
                formData1.append("document", value.license_front);
                formData1.append("applicant", applicantData?.id);

                let formData2 = new FormData();
                formData2.append("type", "license_back");
                formData2.append("expiration_date", value.expiration_date);
                formData2.append("document", value.license_back);
                formData2.append("applicant", applicantData?.id);

                if (
                  typeof value.license_front === "string" &&
                  typeof value.license_back === "string"
                )
                  toast.error("No Changes in License");
                if (typeof value.license_front !== "string")
                  AxiosApi.post(
                    "/application/document_with_expiration",
                    formData1
                  )
                    .then((res) => {
                      setDoc_id((prev) => ({ ...prev, license_front: res.id }));
                      doc_formik.setFieldValue("f", "done");
                      toast.success('Document Saved Successfully!')
                    })
                    .catch((err) => {
                      document.getElementById("license_front").value = "";
                    });

                if (typeof value.license_back !== "string")
                  AxiosApi.post(
                    "/application/document_with_expiration",
                    formData2
                  )
                    .then((res) => {
                      setDoc_id((prev) => ({ ...prev, license_back: res.id }));
                      doc_formik.setFieldValue("f", "done");
                    })
                    .catch((err) => {
                      document.getElementById("license_back").value = "";
                    });
              }}
            >
              {(formik) => (
                <Form>
                  <Grid
                    container
                    sx={{ justifyContent: "space-between" }}
                    rowGap={1.5}
                  >
                    <Grid item xs={12} md={5}>
                      <DocumentInput
                        name="license_front"
                        label="Upload/Capture Driver License-Front*"
                        type="file"
                        formik={{ ...formik }}
                        deleteId={doc_id.license_front}
                        setDoc_id={setDoc_id}
                        doc_formik={doc_formik}
                        disableForm={disableForm}
                        doc_name={documentData?.license_front?.document_filename}
                        md={12}
                      />
                      <Box
                        sx={{
                          margin: "1rem 0.4rem 0.5rem auto",
                          width: "fit-content",
                        }}
                      >
                        <Button
                          onClick={() => {
                            formik.submitForm();
                          }}
                          variant="contained"
                          sx={{
                            fontSize: "0.7rem",
                            background:
                              "linear-gradient(90deg,  rgba(0,41,185,1) 0%, rgba(2,119,250,1) 100%)",
                            // width: "100%",
                            borderRadius: "20px",
                            color: "#fff",
                            height: "2.6em",
                          }}
                        >
                          Save License-Front
                        </Button>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={5}>
                      <DocumentInput
                        name="license_back"
                        label="Upload/Capture Driver License-Back*"
                        type="file"
                        formik={{ ...formik }}
                        deleteId={doc_id.license_back}
                        setDoc_id={setDoc_id}
                        doc_formik={doc_formik}
                        disableForm={disableForm}
                        doc_name={documentData?.license_back?.document_filename}
                        md={12}
                      />
                      <Box
                        sx={{
                          margin: "1rem 0.4rem 0.5rem auto",
                          width: "fit-content",
                        }}
                      >
                        <Button
                          onClick={() => {
                            formik.submitForm();
                          }}
                          variant="contained"
                          sx={{
                            fontSize: "0.7rem",
                            background:
                              "linear-gradient(90deg,  rgba(0,41,185,1) 0%, rgba(2,119,250,1) 100%)",
                            // width: "100%",
                            borderRadius: "20px",
                            color: "#fff",
                            height: "2.6em",
                          }}
                        >
                          Save License-Back
                        </Button>
                      </Box>
                    </Grid>
                    <InputField
                      name="expiration_date"
                      label="Expiration Date of Driver License*"
                      type="date"
                      disabled={disableForm}
                    />
                  </Grid>
                </Form>
              )}
            </Formik>
          </Box>

          <Box
            sx={{
              padding: "0.9rem",
              borderRadius: "10px",
              marginBottom: "0.5rem",
              backgroundColor: "#fff",
              boxShadow: "0 5px 32px 5px #f6f6f6",
            }}
          >
            <Typography
              sx={{
                fontSize: "1.2rem",
                marginLeft: "0.5rem",
                marginBottom: "1rem",
                color: "#d32f2f",
              }}
            >
              Atleast One Document is Mandatory
            </Typography>

            <Grid container sx={{ justifyContent: "space-between" }} rowGap={3}>
              <Grid item sm={12}>
                <Formik
                  initialValues={passport_init}
                  validationSchema={passport_Schema}
                  onSubmit={(value) => {
                    let formData = new FormData();
                    formData.append("type", "passport");
                    formData.append("expiration_date", value.passport_expiry);
                    formData.append("document", value.passport);
                    formData.append("applicant", applicantData?.id);

                    AxiosApi.post(
                      "/application/document_with_expiration",
                      formData
                    )
                      .then((res) => {
                        setDoc_id((prev) => ({ ...prev, passport: res.id }));
                        doc_formik.setFieldValue("a", "done");
                        toast.success('Document Saved Successfully!')
                      })
                      .catch((err) => {
                        document.getElementById("passport").value = "";
                      });
                  }}
                >
                  {(formik) => (
                    <Form>
                      <Grid
                        container
                        sx={{ justifyContent: "space-between", width: "100%" }}
                        rowGap={3}
                      >
                        <Grid item xs={12} md={5}>
                          <DocumentInput
                            name="passport"
                            label="Upload/Capture Passport"
                            type="file"
                            formik={{ ...formik }}
                            deleteId={doc_id.passport}
                            setDoc_id={setDoc_id}
                            doc_formik={doc_formik}
                            disableForm={disableForm}
                            doc_name={documentData?.passport?.document_filename}
                            md={12}
                          />
                          <Box
                            sx={{
                              margin: "1rem 0.4rem 0.5rem auto",
                              width: "fit-content",
                            }}
                          >
                            <Button
                              onClick={() => formik.submitForm()}
                              variant="contained"
                              sx={{
                                fontSize: "0.7rem",
                                background:
                                  "linear-gradient(90deg,  rgba(0,41,185,1) 0%, rgba(2,119,250,1) 100%)",
                                // width: "100%",
                                borderRadius: "20px",
                                color: "#fff",
                                height: "2.6em",
                              }}
                            >
                              Save Passport
                            </Button>
                          </Box>
                        </Grid>
                        <Grid item xs={12} md={5}>
                          <InputField
                            name="passport_expiry"
                            label="Expiration Date of Passport"
                            type="date"
                            disabled={disableForm}
                            md={12}
                          />
                        </Grid>
                      </Grid>
                    </Form>
                  )}
                </Formik>
              </Grid>
            </Grid>

            <Box
              sx={{
                backgroundColor: "#eaeaea",
                height: "1px",
                marginTop: "3rem",
              }}
            ></Box>

            <Formik
              initialValues={single_document}
              onSubmit={(value) => console.log(value)}
            >
              {(formik) => (
                <Form>
                  <Grid
                    container
                    sx={{
                      justifyContent: "space-between",
                      marginTop: "3rem",
                      paddingBottom: "2rem",
                    }}
                    rowGap={3}
                  >
                    <Grid item xs={12} md={5}>
                      <SingleDocumentInput
                        id="ssn"
                        name="ssn"
                        label="Upload/Capture Social Security Card"
                        type="file"
                        formik={{ ...formik }}
                        deleteId={doc_id.ssn}
                        setDoc_id={setDoc_id}
                        doc_formik={doc_formik}
                        disableForm={disableForm}
                        doc_name={documentData?.ssn?.document_filename}
                        md={12}
                        onChange={(e) => setSSN(e.target.files[0])}
                      />
                      <Box
                        sx={{
                          margin: "1rem 0.4rem 0.5rem auto",
                          width: "fit-content",
                        }}
                      >
                        <Button
                          // onClick={ () => formik.submitForm() }
                          onClick={() =>
                            handleSingleDocument("ssn", doc_formik, "b", formik)
                          }
                          variant="contained"
                          sx={{
                            fontSize: "0.7rem",
                            background:
                              "linear-gradient(90deg,  rgba(0,41,185,1) 0%, rgba(2,119,250,1) 100%)",
                            // width: "100%",
                            borderRadius: "20px",
                            color: "#fff",
                            height: "2.6em",
                          }}
                        >
                          Save Social Security Card
                        </Button>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={5}>
                      <SingleDocumentInput
                        id="birth_certificate"
                        name="birth_certificate"
                        label="Upload/Capture Birth Certicate"
                        type="file"
                        formik={{ ...formik }}
                        onChange={(e) => setBirthCertificate(e.target.files[0])}
                        deleteId={doc_id.birth_certificate}
                        setDoc_id={setDoc_id}
                        doc_formik={doc_formik}
                        disableForm={disableForm}
                        doc_name={
                          documentData?.birth_certificate?.document_filename
                        }
                        md={12}
                      />
                      <Box
                        sx={{
                          margin: "1rem 0.4rem 0.5rem auto",
                          width: "fit-content",
                        }}
                      >
                        <Button
                          // onClick={ () => formik.submitForm() }
                          onClick={() =>
                            handleSingleDocument(
                              "birth_certificate",
                              doc_formik,
                              "c",
                              formik
                            )
                          }
                          variant="contained"
                          sx={{
                            fontSize: "0.7rem",
                            background:
                              "linear-gradient(90deg,  rgba(0,41,185,1) 0%, rgba(2,119,250,1) 100%)",
                            // width: "100%",
                            borderRadius: "20px",
                            color: "#fff",
                            height: "2.6em",
                          }}
                        >
                          Save Birth Certifcate
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          </Box>
          <Box
            sx={{
              padding: "0.9rem",
              borderRadius: "10px",
              marginBottom: "2rem",
              marginTop: "2rem",
              backgroundColor: "#fff",
              boxShadow: "0 5px 32px 5px #f6f6f6",
            }}
          >
            <Typography
              sx={{
                fontSize: "1.2rem",
                marginLeft: "0.5rem",
                marginBottom: "1rem",
                color: "#d32f2f",
              }}
            >
              For DOT Drivers
            </Typography>

            <Formik
              initialValues={medical_init}
              validationSchema={medical_Schema}
              onSubmit={(value) => {
                console.log(value);
                let formData = new FormData();
                formData.append("type", "medical_certificate");
                formData.append("expiration_date", value.expiration_date);
                formData.append("document", value.medical_certificate);
                formData.append("applicant", applicantData?.id);
                AxiosApi.post("/application/document_with_expiration", formData)
                  .then((res) => {
                    setDoc_id((prev) => ({
                      ...prev,
                      medical_certificate: res.id,
                    }));
                    doc_formik.setFieldValue("d", "done");
                    toast.success('Document Saved Successfully!')
                  })
                  .catch((err) => {
                    document.getElementById("medical_certificate").value = "";
                  });
              }}
            >
              {(formik) => (
                <Form>
                  <Grid
                    container
                    sx={{ justifyContent: "space-between" }}
                    rowGap={3}
                  >
                    <Grid item xs={12} md={5}>
                      <DocumentInput
                        name="medical_certificate"
                        label="Upload/Capture Medical Certificate"
                        type="file"
                        formik={{ ...formik }}
                        deleteId={doc_id.medical_certificate}
                        setDoc_id={setDoc_id}
                        doc_formik={doc_formik}
                        disableForm={disableForm}
                        doc_name={
                          documentData?.medical_certificate?.document_filename
                        }
                        md={12}
                      />
                      <Box
                        sx={{
                          margin: "1rem 0.4rem 0.5rem auto",
                          width: "fit-content",
                        }}
                      >
                        <Button
                          onClick={() => formik.submitForm()}
                          variant="contained"
                          sx={{
                            fontSize: "0.7rem",
                            background:
                              "linear-gradient(90deg,  rgba(0,41,185,1) 0%, rgba(2,119,250,1) 100%)",
                            // width: "100%",
                            borderRadius: "20px",
                            color: "#fff",
                            height: "2.6em",
                          }}
                        >
                          Save Medical Certificate
                        </Button>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={5}>
                      <InputField
                        name="expiration_date"
                        label="Expiration Date of Medical Certificate"
                        type="date"
                        disabled={disableForm}
                        md={12}
                      />
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          </Box>
          <Box
            sx={{
              display: "flex",
              width: { xs: "100%", md: "100%" },
              justifyContent: "space-between",
              margin: "20px 0 0 auto",
              padding: "0 10px 20px 10px",
            }}
          >
            {disableForm ? (
              <Button
                variant="contained"
                endIcon={<KeyboardDoubleArrowRightIcon />}
                sx={{
                  fontSize: { xs: "0.9rem", md: "1rem" },
                  margin: "0 0 0 auto",
                  background:
                    "linear-gradient(90deg,  rgba(0,41,185,1) 0%, rgba(2,119,250,1) 100%)",
                  width: { xs: "100%", md: "fit-content" },
                  borderRadius: "20px",
                  color: "#fff",
                  height: "2.6em",
                }}
                onClick={() => navigate(-1)}
              >
                Close
              </Button>
            ) : (
              <Button
                variant="contained"
                endIcon={<KeyboardDoubleArrowRightIcon />}
                sx={{
                  fontSize: { xs: "0.9rem", md: "1rem" },
                  margin: "0 0 0 auto",
                  background:
                    "linear-gradient(90deg,  rgba(0,41,185,1) 0%, rgba(2,119,250,1) 100%)",
                  width: { xs: "100%", md: "fit-content" },
                  borderRadius: "20px",
                  color: "#fff",
                  height: "2.6em",
                }}
                onClick={() => {
                  if (Object.keys(doc_formik.errors).length !== 0) {
                    toast.error("Please Fill Required Field");
                  }
                  doc_formik.submitForm();
                }}
              >
                Save
              </Button>
            )}
          </Box>
          {auth.role[0] === "A" ? <Box sx={{ width: { xs: "100%", md: "fit-content" }, padding: "0 10px 20px 10px", }}> <ModalButton /></Box> : null}
        </Box>
      )}
    </Formik>
  );
};

export default DocumentForm;
