import * as React from "react";
import { useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Input from "../Text_input/TextInput";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { SelectField } from "../Select_input/Select";
import { getAllCountries, getStates } from "../../../assets/json/country";

export default function SimpleAccordion({
  name,
  title,
  subTitle,
  formik,
  disabled,
  cardState = false,
  initCountry,
}) {
  const [countryDetails, setCountryDetails] = useState(
    initCountry ? initCountry : ""
  );
  const [open, setOpen] = useState(cardState);

  const updatedCountries = getAllCountries().map((country) => ({
    title: country,
    value: country,
  }));

  const updatedStates = (countryId) => {
    const List = getStates(countryId)[0];
    return List.states.map((item) => ({
      title: item,
      value: item,
    }));
  };

  return (
    <Box
      sx={{
        marginBottom: "2rem !important",
        borderRadius: "20px",
      }}
    >
      <Accordion expanded={open} boxshadow={1}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          onClick={() => setOpen((prev) => !prev)}
        >
          <Grid
            container
            sx={{
              alignItems: "center",
              paddingLeft: { xs: "0.4rem", md: "1.5rem" },
            }}
          >
            <Grid item xs={12} md={6}>
              <Typography
                sx={{ fontWeight: "600", color: "rgba(0, 0, 0, 0.6)" }}
              >
                {title}
              </Typography>
            </Grid>
            {!open ? (
              <Grid item xs={12} md={6}>
                <Typography
                  sx={{
                    fontSize: { xs: "0.7rem", md: "1rem" },
                    color: "rgba(0, 0, 0, 0.6)",
                  }}
                >
                  {subTitle}
                </Typography>
              </Grid>
            ) : null}
          </Grid>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            padding: {
              xs: "0rem 0.8rem 1.2rem 0.8rem",
              md: "0rem 2rem 2rem 2rem",
            },
          }}
        >
          <Box>
            <Grid container sx={{ justifyContent: "space-between" }} rowGap={2}>
              <Input
                name={`${name}.line_1`}
                label="Address Line 1*"
                placeholder={"Enter Address"}
                disabled={disabled}
              />
              <Input
                name={`${name}.line_2`}
                label="Address Line 2"
                placeholder={"Enter Address"}
                disabled={disabled}
              />
              <SelectField
                name={`${name}.country`}
                label="Country*"
                options={updatedCountries}
                setCountryDetails={setCountryDetails}
                formik={{ ...formik }}
                disabled={disabled}
              />
              <SelectField
                name={`${name}.state`}
                label="State*"
                options={countryDetails ? updatedStates(countryDetails) : []}
                disabled={disabled}
              />
              <Input
                name={`${name}.city`}
                label="City*"
                placeholder={"Enter City"}
                disabled={disabled}
              />
              <Input
                name={`${name}.zipcode`}
                label="Zipcode*"
                placeholder={"Enter Zipcode"}
                disabled={disabled}
                type="number"
              />
              <Input
                name={`${name}.residence_from`}
                label="Residence From*"
                type="date"
                disabled={disabled}
              />
              {name === "previous_address" ? (
                <Input
                  name={`${name}.residence_to`}
                  label="Residence To*"
                  type="date"
                  disabled={disabled}
                />
              ) : null}
            </Grid>
            <Grid container></Grid>
          </Box>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}
