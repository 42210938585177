import React, { useRef, useState } from "react";
import * as yup from "yup";
import { Grid, Box, Typography, Button } from "@mui/material";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import InputField from "../../components/FormikControl/Text_input/TextInput";
import { Formik, Form, FieldArray } from "formik";
import PhoneNumber from "../../components/FormikControl/PhoneNumber/PhoneNumber";
import { SelectField } from "../../components/FormikControl/Select_input/Select";
import { getAllCountries, getStates } from "../../assets/json/country";
import useAxios from "../../hooks/useAxios";
import { useDispatch, useSelector } from "react-redux";
import { ApplicantLoginActions } from '../../redux/reducers/ApplicantLogin-Slice'

import {
  ModalButton,
  RadioButtonInput,
  RadioButtonSelect
} from "../../components";

import { Add, Remove } from "@mui/icons-material";
import styled from "@emotion/styled";

function EmploymentHistoryForm({
  auth,
  initValue,
  disabled,
  setvalue,
}) {

  const dispatch = useDispatch()
  // const formikRef = useRef();

  const applicationData = useSelector((state) => state.ApplicantLogin.applicationData);


  const pushValue = {
    applicant: auth.role[0] === "A" ? applicationData.id : initValue.employment_history[0].applicant,
    employer_name: "",
    employer_email: "",
    phone: "",
    line_1: "",
    line_2: "",
    country: "",
    state: "",
    city: "",
    zipcode: "",

    employed_from: "",
    employed_to: "",
    position_held: "",
    reason_for_leaving: "",
    additional_information: {
      question_1: "",
      answer_1: "",
      question_2: "",
      answer_2: "",
      question_3: "",
      answer_3: "",
      answer_3_from: "",
      answer_3_to: "",

      employment_status: "",
      question_4: "",
      answer_4: "",
    },
  }


  const [showFrom, setShowForm] = useState(initValue?.employment_history[0]?.employer_name ? true : false)

  const EmploymentHistoryFormValidation = yup.object().shape({
    employment_history: yup.array().of(
      yup.object().shape({
        employer_name: yup.string().required("Required"),
        employer_email: yup.string().email("Invalid email").required("Required"),
        phone: yup.string().required("Required"),
        country: yup.string().required("Required"),
        state: yup.string().required("Required"),
        city: yup.string().required("Required"),
        zipcode: yup.string().required("Required"),
        line_1: yup.string().required("Required"),
        employed_from: yup.string().required("Required"),
        employed_to: yup.string().required("Required"),
        position_held: yup.string().required("Required"),
        reason_for_leaving: yup.string().required("Required"),
        additional_information: yup.object({
          answer_1: yup.string().required("Required"),
          answer_2: yup.string().required("Required"),
          answer_3: yup.string().required("Required"),
          answer_3_from: yup.string().when('answer_3', {
            is: 'Yes',
            then: yup.string()
              .required('Required'),
            otherwise: yup.string(),
          }),
          answer_3_to: yup.string().when('answer_3', {
            is: 'Yes',
            then: yup.string()
              .required('Required'),
            otherwise: yup.string(),
          }),
          employment_status: yup.string().when('answer_3', {
            is: 'Yes',
            then: yup.string()
              .required('Required'),
            otherwise: yup.string(),
          }),

        }),
      }))
  });

  const AxiosApi = useAxios();

  const updatedCountries = getAllCountries().map((country) => ({
    title: country,
    value: country,
  }));


  const updatedStates = (countryId) => {
    const List = getStates(countryId)[0];
    return List.states.map((item) => ({
      title: item,
      value: item,
    }));
  };

  function ReturnNull(value) {
    return value === "" ? null : value;
  }

  const handleEmptyNext = () => {
    if (showFrom === false) setvalue(1)
  }


  const modifyResponse = (res) => {
    return JSON.parse(JSON.stringify(res).replace(/\:null/gi, "\:\"\"")) /* eslint-disable-line */
  }
  const handleNext = (values) => {

    let payLoad = []
    values?.employment_history?.forEach(element => {
      payLoad.push({
        ...element,
        phone: ReturnNull(element.phone)
      })
    });
    if (initValue?.employment_history[0]?.employer_name) {
      AxiosApi.put(`/application/employment_history`, payLoad).then((res) => {

        dispatch(ApplicantLoginActions.employementHistory(modifyResponse(res)))
        setvalue(1);
      })
    } else {
      if (auth.role[0] === "A") payLoad[0].applicant = applicationData.id
      AxiosApi.post(`/application/employment_history`, payLoad).then((res) => {
        dispatch(ApplicantLoginActions.employementHistory(modifyResponse(res)))
        setvalue(1);
      })
    }
  };



  const Item = styled(Box)({});

  return (
    <Box>

      <Box
        sx={{
          padding: "0.9rem",
          borderRadius: "10px",
          marginBottom: "2rem",
          backgroundColor: "#fff",
        }}
      >
        <Typography
          sx={{
            fontSize: "1.2rem",
            marginLeft: "0.5rem",
            marginBottom: "1rem",
          }}
        >
          Employment History
        </Typography>
        <RadioButtonSelect
          name={`employment_status`}
          RadioText="In the past 3 years, do you have any employment history?"
          optionOne="Yes"
          optionTwo="No"
          setShowField={setShowForm}
          defaultValue={initValue?.employment_history[0]?.employer_name ? 'Yes' : 'No'}
          disabled={disabled}
        />
        <Formik
          initialValues={initValue}
          validationSchema={EmploymentHistoryFormValidation}
          onSubmit={handleNext}

        >
          {(formik) => (
            <Form>
              {showFrom ? (
                <FieldArray name="employment_history">
                  {
                    // prettier-ignore
                    ({ push, remove }) => (

                      formik.values.employment_history.length > 0 &&
                      formik.values.employment_history.map((value, index) => {

                        return (
                          <React.Fragment key={index} >
                            <Grid
                              container
                              sx={{ justifyContent: "space-between" }}
                              rowGap={1.5}
                            >
                              <Grid item xs={12}>
                                <Typography
                                  sx={{
                                    fontSize: "1.1rem",
                                    fontWeight: '500',
                                    margin: '1.4rem 0 0.5rem 0.5rem',
                                    letterSpacing: '2px'
                                  }}
                                >
                                  Employer : {index + 1}
                                </Typography>
                              </Grid>

                              <InputField
                                name={`employment_history.${index}.employer_name`}
                                label="Employer Name*"
                                placeholder="Enter Employer Name"
                                disabled={disabled}
                              />

                              <InputField
                                name={`employment_history.${index}.employer_email`}
                                label="Employer Email*"
                                placeholder="Enter Employer Email"
                                disabled={disabled}
                              />

                              <PhoneNumber
                                name={`employment_history.${index}.phone`}
                                label="Employer Phone Number*"
                                handleChange={(e) => {
                                  formik.setFieldValue(`employment_history.${index}.phone`, '+' + e)
                                }
                                }
                                disabled={disabled}
                              />
                              <InputField
                                name={`employment_history.${index}.line_1`}
                                label="Address Line 1*"
                                placeholder={"Enter Address"}
                                disabled={disabled}
                              />
                              <InputField
                                name={`employment_history.${index}.line_2`}
                                label="Address Line 2"
                                placeholder={"Enter Address"}
                                disabled={disabled}
                              />
                              <SelectField
                                name={`employment_history.${index}.country`}
                                label="Country*"
                                options={updatedCountries}
                                formik={{ ...formik }}
                                disabled={disabled}
                                setCountryFieldArray={true}

                              />
                              <SelectField
                                placeholder="State"
                                name={`employment_history.${index}.state`}
                                label="State*"
                                options={formik?.values?.employment_history[index]?.country ? updatedStates(formik?.values?.employment_history[index]?.country) : []}
                                disabled={disabled}
                              />
                              <InputField
                                name={`employment_history.${index}.city`}
                                label="City*"
                                placeholder={"Enter City"}
                                disabled={disabled}
                              />
                              <InputField
                                name={`employment_history.${index}.zipcode`}
                                label="Zipcode*"
                                placeholder={"Enter Zipcode"}
                                disabled={disabled}
                                type="number"
                              />
                              <InputField
                                name={`employment_history.${index}.employed_from`}
                                label="Employed From*"
                                type="date"
                                disabled={disabled}
                              />
                              <InputField
                                name={`employment_history.${index}.employed_to`}
                                label="Employed To*"
                                type="date"
                                disabled={disabled}
                              />
                              <InputField
                                name={`employment_history.${index}.position_held`}
                                label="Position Held*"
                                disabled={disabled}
                                placeholder={"Enter Position Held"}
                              />
                              <InputField
                                name={`employment_history.${index}.reason_for_leaving`}
                                label="Reason for Leaving*"
                                disabled={disabled}
                                placeholder={"Enter Reason for Leaving"}
                              />
                              <Box sx={{ height: '1rem', width: '100%' }}></Box>
                              <RadioButtonInput
                                name={`employment_history.${index}.additional_information.answer_1`}
                                RadioText="Were you subject to FMCSRs while employed?"
                                optionOne="Yes"
                                optionTwo="No"
                                defaultValue={formik.values.employment_history[index].additional_information.answer_1}
                                formik={{ ...formik }}
                                disabled={disabled}
                              />

                              <RadioButtonInput
                                name={`employment_history.${index}.additional_information.answer_2`}
                                RadioText="Was your job designated as a safety-sensitive function in any DOT-regulated mode subject to the drug and alcohol testing requirements of 49 CFR Part 40?"
                                optionOne="Yes"
                                optionTwo="No"
                                defaultValue={formik.values.employment_history[index].additional_information.answer_2}
                                formik={{ ...formik }}
                                disabled={disabled}
                              />
                              <RadioButtonInput
                                name={`employment_history.${index}.additional_information.answer_3`}
                                RadioText="Account for Remaining Period of Time Required for Employement History (Gap of Employement)"
                                optionOne="Yes"
                                optionTwo="No"
                                defaultValue={formik.values.employment_history[index].additional_information.answer_3}
                                formik={{ ...formik }}
                                disabled={disabled}
                              />
                              {formik.values.employment_history[index].additional_information.answer_3 === 'Yes' ? (
                                <>
                                  <InputField
                                    name={`employment_history.${index}.additional_information.answer_3_from`}
                                    label="From*"
                                    type="date"
                                    disabled={disabled}
                                  />
                                  <InputField
                                    name={`employment_history.${index}.additional_information.answer_3_to`}
                                    label="To*"
                                    type="date"
                                    disabled={disabled}
                                  />
                                  <InputField
                                    name={`employment_history.${index}.additional_information.employment_status`}
                                    label="Employement Status*"
                                    placeholder={
                                      "Employment status during this period"
                                    }
                                    disabled={disabled}
                                  />
                                </>
                              ) : null}
                              <Grid container>
                                <Grid item xs={12} md={6}>
                                  <Item sx={{
                                    display: 'flex',
                                    justifyContent: { xs: 'flex-end', md: 'flex-start' }
                                  }}>
                                    {formik.values.employment_history.length > 1 ?
                                      <Button
                                        variant="outlined"
                                        endIcon={<Remove />}
                                        sx={{
                                          fontSize: { xs: "0.7rem", md: "1rem" },
                                          color: "#d32f2f",
                                          border: "1px solid #d32f2f",
                                          borderRadius: "20px",
                                          '&:hover': {
                                            border: "1px solid #d32f2f",
                                            background: '#d32f2f',
                                            color: '#fff'
                                          },
                                          marginTop: '10px',
                                          textTransform: 'none'

                                        }}
                                        onClick={() => remove(index)}>Remove Employer [ {index + 1} ]</Button>
                                      : null}
                                  </Item>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                  <Item sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-end'
                                  }}>
                                    <Button
                                      variant="contained"
                                      endIcon={<Add />}
                                      sx={{
                                        fontSize: { xs: "0.7rem", md: "1rem" },

                                        background:
                                          "linear-gradient(90deg,  rgba(0,41,185,1) 0%, rgba(2,119,250,1) 100%)",
                                        // width: "100%",
                                        borderRadius: "20px",
                                        color: "#fff",
                                        marginTop: '10px',

                                        // height: "2.6em",
                                      }}
                                      onClick={() => push(pushValue)}>Add Employer</Button>
                                  </Item>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Box sx={{ backgroundColor: '#cfd0d1', height: '2px', marginTop: '1rem' }}></Box>
                          </React.Fragment>
                        );
                      })
                    )
                  }
                </FieldArray>
              ) : null}


              <Box
                sx={{
                  display: "flex",
                  width: { xs: "100%", md: "100%" },
                  justifyContent: "space-between",
                  margin: "0 0 0 auto",
                  padding: "0 10px 20px 10px",
                  // flexDirection: { xs: "column", md: "row" },
                }}
              >

                {disabled ? (
                  <>
                    <Button
                      variant="contained"
                      endIcon={<KeyboardDoubleArrowRightIcon />}
                      sx={{
                        fontSize: { xs: "0.9rem", md: "1rem" },
                        margin: "2rem 0 0 auto",
                        background:
                          "linear-gradient(90deg,  rgba(0,41,185,1) 0%, rgba(2,119,250,1) 100%)",
                        width: { xs: "100%", md: "fit-content" },
                        borderRadius: "20px",
                        color: "#fff",
                        height: "2.6em",
                      }}
                      onClick={() => setvalue(1)}
                    >
                      Next
                    </Button>
                  </>
                ) : (
                  <>
                    <Button
                      variant="contained"
                      endIcon={<KeyboardDoubleArrowRightIcon />}
                      sx={{
                        fontSize: { xs: "0.9rem", md: "1rem" },
                        margin: "2rem 0 0 auto",
                        background:
                          "linear-gradient(90deg,  rgba(0,41,185,1) 0%, rgba(2,119,250,1) 100%)",
                        width: { xs: "100%", md: "fit-content" },
                        borderRadius: "20px",
                        color: "#fff",
                        height: "2.6em",
                      }}
                      type="submit"
                      onClick={handleEmptyNext}
                    >
                      Save & Next
                    </Button>
                  </>
                )}
              </Box>
              {auth.role[0] === "A" ?
                <Box sx={{ width: { xs: "100%", md: "fit-content" }, padding: "0 10px 20px 10px", }}>
                  <ModalButton />
                </Box>

                : null}
            </Form>
          )
          }
        </Formik >
      </Box >
    </Box>
  );
}

export default EmploymentHistoryForm;
