import styled from "@emotion/styled";
import { Box, Button, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import MMSLogo from "../../assets/images/mms-logo.png";
import { TextInput, PasswordInput, ButtonInput } from "../../components";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import { Link, useNavigate, useLocation } from "react-router-dom";
import useAxios from "../../hooks/useAxios";
import { toast } from "react-toastify";
import CoverImage from "../../assets/images/Cover.jpg";
import { useSelector } from "react-redux";

const CONFIRM_FORGOT_PASSWORD_URL = "/accounts/password_reset/confirm/";

function ResetPassword() {
  const AxiosApi = useAxios();
  const location = useLocation();
  const navigate = useNavigate();

  const companyLogo = useSelector((state) => state.ThemeSlice?.companyLogo);

  const Item = styled(Box)({});
  const validationSchema = Yup.object({
    password: Yup.string()
      .matches(
        /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#!$%^&-+=()_]).{8,}$/,
        "Enter atleast 1 capital, 1 numeric, 1 special character"
      )
      .required("Required")
      .trim(),
    repeatpassword: Yup.string()
      .required("Required")
      .oneOf([Yup.ref("password"), null], "Passwords must match"),
  });
  const initialValues = {
    password: "",
    repeatpassword: "",
  };

  return (
    <>
      <Box>
        <Grid container>
          <Grid item md={6}>
            <Item
              sx={{
                // justifyContent: "center",
                display: "flex",
                height: "100vh",
                flexDirection: "column",
              }}
            >
              <Box
                sx={{
                  width: "200px",
                  height: "200px",
                  margin: "0 auto 0 30px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Box
                  src={companyLogo ? companyLogo : MMSLogo}
                  component="img"
                  sx={{
                    width: !companyLogo ? "70px" : "200px",
                    maxHeight: "200px",
                    padding: "20px",
                    margin: "0 auto",
                  }}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  height: "100%",
                  flexDirection: "column",
                  width: "100%",
                  padding: "0 40px",
                  marginTop: "-70px",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: "600",
                    fontSize: "2.2em",
                    marginBottom: "10px",
                    color: "#3E3E3E",
                  }}
                >
                  Forgot Password
                </Typography>
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={(values) => {
                    console.log(values);
                    AxiosApi.post(CONFIRM_FORGOT_PASSWORD_URL, {
                      password: values.password,
                      token: location.state.token,
                    }).then(() => {
                      toast.success("Password change");
                      navigate("/login");
                    });
                  }}
                >
                  {(formik) => (
                    <Form>
                      <Box sx={{ marginBottom: "15px" }}></Box>
                      <Box sx={{ marginBottom: "20px" }}>
                        <PasswordInput
                          name="password"
                          label="New Password"
                          md={8}
                        />
                      </Box>
                      <Box sx={{ marginBottom: "20px" }}>
                        <PasswordInput
                          name="repeatpassword"
                          label="Confirm New Password"
                          md={8}
                        />
                      </Box>
                      <ButtonInput
                        label="Change Password"
                        md={8}
                        type="submit"
                      />
                    </Form>
                  )}
                </Formik>
                <Link to="/login" style={{ textDecoration: "none" }}>
                  <Typography
                    sx={{
                      marginTop: "30px",
                      color: "#000",
                      marginLeft: "20px",
                    }}
                  >
                    Back To Login Page
                  </Typography>
                </Link>
              </Box>
            </Item>
          </Grid>
          <Grid item md={6}>
            <Item>
              <Box
                component="img"
                src={CoverImage}
                sx={{
                  width: "100%",
                  height: "100vh",
                  background: "rgba( 255, 255, 255, 0.1 )",
                  boxShadow: "0 8px 32px 0 rgba( 31, 38, 135, 0.37 )",

                  objectFit: "cover",
                  objectPosition: "60% 0%",
                }}
              />
            </Item>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default ResetPassword;
