import {
  InputLabel,
  TextField,
  Grid,
  Box,
  InputAdornment,
} from "@mui/material";
import { useField } from "formik";
import React from "react";
import { makeStyles } from "@mui/styles";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import useAxios from "../../../hooks/useAxios";
import DownloadIcon from "@mui/icons-material/Download";
import { getDocumentData } from "../../../redux/thunks/ApplicantLogin-thunk";
import { useDispatch, useSelector } from "react-redux";
import downloadFile from "../../../utils/downloadFile";

const useStyles = makeStyles((theme) => ({
  inputRoot: {
    "&$disabled": {
      "-webkit-text-fill-color": "red !important",
      color: "black !important",
    },
  },
  disabled: {
    "&$disabled": {
      "-webkit-text-fill-color": "black !important",
      color: "black !important",
    },
  },
}));

function TextInput(props) {
  const {
    label,
    type,
    suffix,
    prefix,
    placeholder,
    name,
    multiline,
    md = 5,
    ...rest
  } = props;

  const [field, meta] = useField(name);

  const classes = useStyles();

  const config = {
    ...field,
    fullWidth: true,
    multiline: multiline,
    variant: "outlined",
    autoComplete: "off",
    size: "small",
    InputProps: {
      style: {
        borderRadius: "20px",
        backgroundColor: "#E8F3FE",
        width: "100%",
      },
      classes: {
        root: classes.inputRoot,
        disabled: classes.disabled,
      },
    },
  };

  if (meta && meta.touched && meta.error) {
    config.error = true;
    config.helperText = meta.error;
  }

  if (prefix)
    config.InputProps = {
      ...config.InputProps,
      startAdornment: (
        <InputAdornment position="start">{prefix}</InputAdornment>
      ),
    };

  if (suffix)
    config.InputProps = {
      ...config.InputProps,
      endAdornment: <InputAdornment position="end">{suffix}</InputAdornment>,
    };

  return (
    <Grid item xs={12} md={md}>
      <Grid item sx={{ width: "100%" }}>
        <Box sx={{ wordWrap: 'break-word' }}>
          <InputLabel sx={{ paddingLeft: "0.5rem", fontWeight: "600", whiteSpace: 'normal', fontSize: { xs: '0.8rem', md: '1rem' } }}>
            {label}
          </InputLabel>
          <TextField
            {...rest}
            {...config}
            inputProps={{
              style: { color: "#000" },
            }}
            type={type}
            placeholder={placeholder}
          />
        </Box>
      </Grid>
    </Grid >
  );
}

export default TextInput;

function DocumentInput(props) {
  const {
    label,
    type,
    name,
    formik,
    deleteId,
    setDoc_id,
    doc_formik,
    disableForm,
    md = 5,
    doc_name,
    ...rest
  } = props;

  const [field, meta] = useField(name);
  const classes = useStyles();

  const config = {
    fullWidth: true,
    variant: "outlined",
    autoComplete: "off",
    size: "small",
    InputProps: {
      style: {
        borderRadius: "20px",
        backgroundColor: "#E8F3FE",
        width: "100%",
      },
      classes: {
        root: classes.inputRoot,
        disabled: classes.disabled,
      },
    },
  };

  const AxiosApi = useAxios();
  const dispatch = useDispatch();
  const applicationData = useSelector(
    (state) => state.ApplicantLogin.applicationData
  );



  const handleDelete = () => {
    if (deleteId)
      AxiosApi.delete(
        `/application/document_with_expiration?id=${deleteId}`
      ).then(() => {
        if (name === "license") {
          setDoc_id((prev) => ({ ...prev, license: "" }));
          doc_formik.setFieldValue("d", "");
        }

        if (name === "passport") {
          setDoc_id((prev) => ({ ...prev, passport: "" }));
          doc_formik.setFieldValue("e", "");
        }

        dispatch(getDocumentData(applicationData.id));
        document.getElementById(field.name).value = "";
        formik.setFieldValue(field.name, "");
      });
  };

  const handleDowload = () => {
    if (deleteId)
      downloadFile(
        `/application/document_with_expiration?id=${deleteId}`,
        doc_name, AxiosApi
      );
  };

  config.InputProps = {
    ...config.InputProps,
    endAdornment: (
      <InputAdornment position="end">
        {deleteId ? (
          <>
            <IconButton
              edge="end"
              sx={{ marginRight: "0.6rem" }}
              onClick={handleDowload}
            >
              <DownloadIcon />
            </IconButton>
            <IconButton edge="end" onClick={handleDelete}>
              <DeleteIcon />
            </IconButton>
          </>
        ) : null}
      </InputAdornment>
    ),
  };

  if (meta && meta.touched && meta.error) {
    config.error = true;
    config.helperText = meta.error;
  }

  return (
    <Grid item xs={12} md={md}>
      <Grid item sx={{ width: "100%" }}>
        <Box sx={{ position: 'relative' }}>
          {doc_name && <Box sx={{ position: 'absolute', top: '2rem', zIndex: '10', left: '7.5rem', backgroundColor: '#E8F3FE', width: { xs: '35%', md: '55%' }, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{doc_name}</Box>}
          <InputLabel sx={{ paddingLeft: "0.5rem", fontWeight: "600" }}>
            {label}
          </InputLabel>
          <TextField
            {...rest}
            {...config}
            inputProps={{
              style: { color: "#000" },
            }}
            id={field.name}
            type={type}
            onChange={(e) =>
              formik.setFieldValue(field.name, e.target.files[0])
            }
            disabled={disableForm}
          />
        </Box>
      </Grid>
    </Grid>
  );
}

function SingleDocumentInput(props) {
  const {
    label,
    type,
    name,
    onChange,
    md = 5,
    formik,
    deleteId,
    setDoc_id,
    doc_formik,
    disableForm,
    id,
    doc_name,
  } = props;

  const AxiosApi = useAxios();

  const dispatch = useDispatch();

  const applicationData = useSelector(
    (state) => state.ApplicantLogin.applicationData
  );

  const [field, meta] = useField(name);

  const classes = useStyles();

  const config = {
    fullWidth: true,
    variant: "outlined",
    autoComplete: "off",
    size: "small",
    InputProps: {
      style: {
        borderRadius: "20px",
        backgroundColor: "#E8F3FE",
        width: "100%",
      },
      classes: {
        root: classes.inputRoot,
        disabled: classes.disabled,
      },
    },
  };

  const handleDelete = () => {
    if (deleteId)
      AxiosApi.delete(`/application/document?id=${deleteId}`).then(() => {
        if (name === "medical_certificate") {
          setDoc_id((prev) => ({ ...prev, medical_certificate: "" }));
          doc_formik.setFieldValue("a", "");
        }

        if (name === "ssn") {
          setDoc_id((prev) => ({ ...prev, ssn: "" }));
          doc_formik.setFieldValue("b", "");
        }

        if (name === "birth_certificate") {
          setDoc_id((prev) => ({ ...prev, birth_certificate: "" }));
          doc_formik.setFieldValue("c", "");
        }

        dispatch(getDocumentData(applicationData.id));
        document.getElementById(field.name).value = "";
        formik.setFieldValue(field.name, "");
      });
  };

  const handleDowload = () => {
    if (deleteId)
      downloadFile(`/application/document?id=${deleteId}`, doc_name, AxiosApi);
  };
  config.InputProps = {
    ...config.InputProps,
    endAdornment: (
      <InputAdornment position="end">
        {deleteId ? (
          <>
            <IconButton
              edge="end"
              sx={{ marginRight: "0.6rem" }}
              onClick={handleDowload}
            >
              <DownloadIcon />
            </IconButton>
            <IconButton edge="end" onClick={handleDelete}>
              <DeleteIcon />
            </IconButton>
          </>
        ) : null}
      </InputAdornment>
    ),
  };

  if (meta && meta.touched && meta.error) {
    config.error = true;
    config.helperText = meta.error;
  }

  return (
    <Grid item xs={12} md={md}>
      <Grid item sx={{ width: "100%" }}>
        <Box sx={{ position: 'relative' }}>
          {doc_name && <Box sx={{ position: 'absolute', top: '2rem', zIndex: '10', left: '7.5rem', backgroundColor: '#E8F3FE', width: { xs: '35%', md: '55%' }, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{doc_name}</Box>}
          <InputLabel sx={{ paddingLeft: "0.5rem", fontWeight: "600" }}>
            {label}
          </InputLabel>
          <TextField
            {...config}
            inputProps={{
              style: { color: "#000" },
            }}
            id={id}
            type={type}
            onChange={onChange}
            disabled={disableForm}
          />
        </Box>
      </Grid>
    </Grid>
  );
}


function SimpleDocumentInput(props) {
  const {
    id,
    label,
    type,
    name,
    deleteId,
    onChange,
    md = 5,
    doc_name,
    handleDowload,
    handleDelete
  } = props;




  const classes = useStyles();

  const config = {
    fullWidth: true,
    variant: "outlined",
    autoComplete: "off",
    size: "small",
    InputProps: {
      style: {
        borderRadius: "20px",
        backgroundColor: "#E8F3FE",
        width: "100%",
      },
      classes: {
        root: classes.inputRoot,
        disabled: classes.disabled,
      },
    },
  };

  const spinner = useSelector(
    (state) => state.ThemeSlice.spinner
  );

  config.InputProps = {
    ...config.InputProps,
    endAdornment: (
      <InputAdornment position="end">
        {deleteId ? (
          <>
            <IconButton
              edge="end"
              sx={{ marginRight: "0.6rem" }}
              onClick={handleDowload}
              disabled={spinner}
            >
              <DownloadIcon />
            </IconButton>
            <IconButton edge="end" onClick={handleDelete} disabled={spinner}>
              <DeleteIcon />
            </IconButton>
          </>
        ) : null}
      </InputAdornment>
    ),
  };



  return (
    <Grid item xs={12} md={md}>
      <Grid item sx={{ width: "100%" }}>
        <Box sx={{ position: 'relative' }}>
          {doc_name && <Box sx={{ position: 'absolute', top: '2rem', zIndex: '10', left: '7.5rem', backgroundColor: '#E8F3FE', width: { xs: '20%', md: '42%' }, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{doc_name}</Box>}
          <InputLabel sx={{ paddingLeft: "0.5rem", fontWeight: "600" }}>
            {label}
          </InputLabel>
          <TextField
            {...config}
            inputProps={{
              style: { color: "#000" },
            }}
            id={id}
            type={type}
            onChange={onChange}
          />
        </Box>
      </Grid>
    </Grid>
  );
}

export { DocumentInput, SingleDocumentInput, SimpleDocumentInput };
