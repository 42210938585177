import React, { useState, useEffect } from "react";
import { Group, PersonAdd } from "@mui/icons-material";
import Grid from "@mui/material/Grid";
// import Style from './dashboard.module.css'
import { Box, Typography } from "@mui/material";
import { SelectSmall } from "../../components";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import CircleNotificationsIcon from "@mui/icons-material/CircleNotifications";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import GridTable from "../../components/Grid_table/GridTable";
import TabView from "../../components/Tabs/Tabs";
import HeaderTab from "../../components/HeaderSection/Header";
import useAxios from "../../hooks/useAxios";
import InputField from "../../components/FormikControl/Text_input/TextInput";
import * as yup from "yup";
import PhoneNumber from "../../components/FormikControl/PhoneNumber/PhoneNumber";
import { ButtonInput } from "../../components";
import { Formik, Form, FieldArray } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { getTableData } from "../../redux/thunks/ManageApplicants-thunk";
import Pagination from "@mui/material/Pagination";
import useMediaQuery from "@mui/material/useMediaQuery";


function ManageApplicants() {
  const [data, setData] = useState([]);

  const axiosApi = useAxios();

  const dispatch = useDispatch();

  const tableData = useSelector((state) => state.ManageApplicants.tableData);

  console.log(tableData)

  const tableHeader = [
    { title: "S.No", wide: 0.5 },
    { title: "Name", wide: 2 },
    { title: "Email", wide: 3.5 },
    { title: "Status", wide: 1.5 },
    { title: "Invited", wide: 1.5 },
    { title: "Driver Application", wide: 2 },
    { title: "Actions", wide: 1 },
  ];

  useEffect(() => {
    async function getApplicants() {
      dispatch(getTableData());
    }

    getApplicants();
  }, []);

  useEffect(() => {
    if (tableData?.length) setData(tableData);
  }, [tableData]);

  // PopOver Props
  const PopOver = {
    set_1: [
      {
        icon: <CheckCircleIcon sx={{ color: "green" }} />,
        text: "Approve",
        status: "approved",
      },
      {
        icon: <CancelIcon sx={{ color: "#d1060a" }} />,
        text: "Reject",
        status: "rejected",
      }
    ],
    set_2: [
      {
        icon: <CircleNotificationsIcon sx={{ color: "#e8c91c" }} />,
        text: "Alert",
      },
      { icon: <RestartAltIcon />, text: "Reset" },
    ],
  };

  const headerTabProp = {
    startTitle: "Applicant Information",
    endTitle: "Invite Applicant",
  };

  const ApplicantsInfo = ({ setvalue }) => {
    const matches = useMediaQuery("(max-width:400px)");
    const [page, setPage] = useState(1);
    const [row, setRow] = useState(10);
    const [search, setSearch] = useState("");
    const [filterData, setFilterData] = useState(data);

    useEffect(() => {
      setFilterData(
        data.filter((item) =>
          item.name.toLowerCase().includes(search.toLowerCase())
        )
      );
    }, [search]);

    const count = Math.ceil(filterData.length / row);

    function paginator(items, current_page, per_page_items) {
      let page = current_page || 1,
        per_page = per_page_items,
        offset = (page - 1) * per_page,
        paginatedItems = items.slice(offset).slice(0, per_page_items),
        total_pages = Math.ceil(items.length / per_page);

      return {
        page: page,
        per_page: per_page,
        pre_page: page - 1 ? page - 1 : null,
        next_page: total_pages > page ? page + 1 : null,
        total: items.length,
        total_pages: total_pages,
        data: paginatedItems,
      };
    }

    const handlePagination = (e, value) => {
      setPage(paginator(filterData, value, row).page);
    };

    const GridTableProps = {
      tableHead: tableHeader,
      tableBody: paginator(filterData, page, row).data,
      viewApplication: true,
      popOver: PopOver,
      md: [2, 3.5, 1.5, 1.5],
    };

    return (
      <Box>
        {/* Header Section ---------------------------------------------------------------------------------------------------------------------------------------*/}
        <HeaderTab
          headerTabProp={headerTabProp}
          setSearch={setSearch}
          setvalue={setvalue}
          setPage={setPage}
          page={page}
        />

        {/* Grid Table ------------------------------------------------------------------------------------------------------------------------------------------- */}
        <GridTable tableProps={GridTableProps} />

        {/* Pagination ------------------------------------------------------------------------------------------------------------------------------------------- */}
        <Box
          sx={{
            display: "flex",
            alignItems: { xs: "end", md: "center" },
            justifyContent: "right",
            // marginTop: "1rem",
            flexDirection: { xs: "column", md: "row" },
          }}
        >
          <SelectSmall setRow={setRow} />
          <Pagination
            count={count}
            page={page}
            onChange={handlePagination}
            color="primary"
            variant="outlined"
            shape="rounded"
            size={matches ? "small" : "medium"}
          />
        </Box>
      </Box>
    );
  };

  const InviteApplicant = ({ setvalue }) => {
    const dispatch = useDispatch();

    const initialValues = {
      user: {
        first_name: "",
        middle_name: "",
        last_name: "",
        email: "",
        phone: "",
      },
      pseudo_name: "",
      mothers_maiden_name: "",
      dob: "",
      country: "",
      current_address: {
        line_1: "",
        line_2: "",
        city: "",
        state: "",
        country: "",
        zipcode: "",
        address_type: "current",
        residence_from: "",
        residence_to: "",
      },
      previous_address: {
        line_1: "",
        line_2: "",
        city: "",
        state: "",
        country: "",
        zipcode: "",
        address_type: "previous",
        residence_from: "",
        residence_to: "",
      },
      permanent_address: {
        line_1: "",
        line_2: "",
        city: "",
        state: "",
        country: "",
        zipcode: "",
        address_type: "permanent",
        residence_from: "",
        residence_to: "",
      },
    };
    // const phoneRegExp =
    //   /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;

    const validationSchema = yup.object().shape({
      user: yup.object().shape({
        first_name: yup.string().required("Required"),
        email: yup.string().email("Enter Valid Email Id").required("Required"),
        phone: yup.string().required("Required"),
        // .matches(phoneRegExp, "Enter Correct Number"),
      }),
    });
    function ReturnNull(value) {
      return value === "" ? null : value;
    }

    const handleSubmit = (values, { resetForm }) => {

      const payload_obj = {
        user: {
          phone: ReturnNull(values.user.phone),
          first_name: values.user.first_name,
          middle_name: values.user.middle_name,
          last_name: values.user.last_name,
          email: values.user.email,
        },
        status: "not_started",
        pseudo_name: values.pseudo_name,
        mothers_maiden_name: values.mothers_maiden_name,
        dob: ReturnNull(values.dob),
        country: values.country,
        current_address: {
          ...values.current_address,
          residence_from: ReturnNull(values.current_address.residence_from),
          residence_to: null,
        },
        previous_address: [{
          ...values.previous_address,
          residence_from: ReturnNull(values.previous_address.residence_from),
          residence_to: ReturnNull(values.previous_address.residence_to),
        }],
        permanent_address: {
          ...values.permanent_address,
          residence_from: ReturnNull(values.permanent_address.residence_from),
          residence_to: null,
        },
      };
      axiosApi
        .post(
          "/application/", payload_obj
        )
        .then((res) => {
          dispatch(getTableData());
          resetForm();
          setvalue(0);
        });
    };

    return (
      <Grid container>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {(formik) => (
            <Form>
              <Typography
                variant="h5"
                component="p"
                sx={{ marginBottom: "2rem", marginLeft: "0.5rem" }}
              >
                Invite Applicant
              </Typography>
              <Box
                sx={{
                  padding: "0.4rem",
                  borderRadius: "10px",
                  marginBottom: "2rem",
                }}
              >
                <Grid
                  container
                  sx={{ justifyContent: "space-between" }}
                  rowGap={1.5}
                >
                  <InputField
                    name="user.first_name"
                    label="First Name"
                    placeholder="First Name"
                  />
                  <InputField
                    name="user.middle_name"
                    label="Middle Name"
                    placeholder="Middle Name"
                  />
                  <InputField
                    name="user.last_name"
                    label="Last Name"
                    placeholder="Last Name"
                  />
                  <InputField
                    name="user.email"
                    label="Email"
                    placeholder=" Email"
                  />

                  <PhoneNumber
                    name="user.phone"
                    label="Phone Number"
                    handleChange={(e) =>
                      formik.setFieldValue("user.phone", "+" + e)
                    }
                  />
                </Grid>
                <Box sx={{ margin: "20px 20px 0 5px" }}>
                  <ButtonInput
                    type="submit"
                    label="Send Invite"
                    padding="20px"
                    md={5}
                  />
                </Box>
              </Box>
            </Form>
          )}
        </Formik>
      </Grid>
    );
  };

  const TabProps = {
    tab_details: [
      {
        icon: (
          <Group
            sx={{
              marginRight: "0.5rem",
              fontSize: { xs: "1.2rem", sm: "1.5rem", md: "1.7rem" },
            }}
          />
        ),
        text: "All Applicant",
      },
      {
        icon: (
          <PersonAdd
            sx={{
              marginRight: "0.5rem",
              fontSize: { xs: "1.2rem", sm: "1.5rem", md: "1.7rem" },
            }}
          />
        ),
        text: "Invite Applicant",
      },
    ],
    children: [<ApplicantsInfo />, <InviteApplicant />],
    sm: '0.96rem',
    md: '1.2rem'
  };

  return (
    <Box
      sx={{
        background: "rgba( 255, 255, 255, 0.1 )",

        boxShadow: "0 5px 32px 5px #e2e4f8",

        backdropFilter: "blur( 20px )",
        webkitBackdropFilter: "blur( 20px )",
        borderRadius: "10px",
        border: "1px solid rgba( 255, 255, 255, 0.18 )",
        margin: {
          xs: "0 10px 20px 10px",
          md: "0 30px 20px 30px",
        },
        padding: "20px 10px",
      }}
      boxShadow={3}
    >
      <TabView tabProps={TabProps} />
    </Box>
  );
}

export default ManageApplicants;
