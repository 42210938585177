import React, { useState } from "react";
import * as yup from "yup";
import { Grid, Box, Typography, Button } from "@mui/material";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import InputField from "../../components/FormikControl/Text_input/TextInput";
import { Formik, Form, FieldArray } from "formik";
import { SelectField } from "../../components/FormikControl/Select_input/Select";
import { getAllCountries, getStates } from "../../assets/json/country";
import useAxios from "../../hooks/useAxios";
import { useDispatch, useSelector } from "react-redux";
import { KeyboardDoubleArrowRight, KeyboardDoubleArrowLeft } from '@mui/icons-material';


import {
  ModalButton,
  RadioButtonInput,
  RadioButtonSelect,
} from "../../components";

import { Add, Remove } from "@mui/icons-material";
import styled from "@emotion/styled";
import { ApplicantLoginActions } from '../../redux/reducers/ApplicantLogin-Slice'


function AccidentHistoryForm({
  auth,
  disabled,
  initValue,
  setvalue,
}) {

  const dispatch = useDispatch()

  const [showAccidentHistory, setShowAccidentHistory] = useState(initValue?.accidents[0]?.nature ? true : false);


  const applicationData = useSelector((state) => state.ApplicantLogin.applicationData);

  const EmploymentHistoryFormValidation = yup.object().shape({

    accidents: yup.array().of(
      yup.object({
        accident_date: yup.string().required("Required"),
        nature: yup.string().required("Required"),
        country: yup.string().required("Required"),
        state: yup.string().required("Required"),
        city: yup.string().required("Required"),
        no_of_fatalities: yup.string().required("Required"),
        no_of_injuries: yup.string().required("Required"),
        hazmat_spill: yup.string().required("Required"),
      })),
  });



  const AxiosApi = useAxios();

  const updatedCountries = getAllCountries().map((country) => ({
    title: country,
    value: country,
  }));


  const updatedStates = (countryId) => {
    const List = getStates(countryId)[0];
    return List.states.map((item) => ({
      title: item,
      value: item,
    }));
  };



  const handleNext = (values) => {
    if (initValue?.accidents[0]?.nature) {
      AxiosApi.put(`/application/accident/${values.id}`, values).then((res) => {
        dispatch(ApplicantLoginActions.accidentHistory(res))
        setvalue(3);
      })
    } else {
      let obj = { ...values, applicant: applicationData?.id }
      AxiosApi.post(`/application/accident`, auth.role[0] === "A" ? obj : values).then((res) => {
        dispatch(ApplicantLoginActions.accidentHistory(res))
        setvalue(3);
      })
    }
  };

  const handleEmptyNext = () => {
    if (showAccidentHistory === false) setvalue(3)
  }



  const Item = styled(Box)({});

  return (
    <Box>
      <Formik
        initialValues={initValue}
        validationSchema={EmploymentHistoryFormValidation}
        onSubmit={handleNext}
      >
        {(formik) => (
          <Form>
            <Box
              sx={{
                padding: "0.9rem",
                borderRadius: "10px",
                marginBottom: "2rem",
                backgroundColor: "#fff",
              }}
            >
              <Typography
                sx={{
                  fontSize: "1.2rem",
                  marginLeft: "0.5rem",
                  marginBottom: "1rem",
                }}
              >
                Accident History
              </Typography>
              <RadioButtonSelect
                setShowField={setShowAccidentHistory}
                RadioText="Where you involved in any motor vehicle accidents in the past 3 years?"
                optionOne="Yes"
                optionTwo="No"
                defaultValue={initValue?.accidents[0]?.nature ? 'Yes' : 'No'}
                disabled={disabled}
              />
              {showAccidentHistory ? (
                <FieldArray name="accidents">
                  {
                    // prettier-ignore
                    ({ push, remove }) => (

                      formik.values?.accidents.length > 0 &&
                      formik.values?.accidents.map((value, index) => {
                        return (
                          <React.Fragment key={index} >
                            <Grid
                              container
                              sx={{ justifyContent: "space-between" }}
                              rowGap={1.5}
                              key={index}
                            >
                              <Grid item xs={12}>
                                <Typography
                                  sx={{
                                    fontSize: "1.1rem",
                                    fontWeight: '500',
                                    margin: '1.4rem 0 0 0.5rem'
                                  }}
                                >
                                  Accident : {index + 1}
                                </Typography>
                              </Grid>

                              <InputField
                                name={`accidents.${index}.accident_date`}
                                label="Accident Date*"
                                type='date'
                                disabled={disabled}
                              />

                              <InputField
                                name={`accidents.${index}.nature`}
                                label="Nature of the accident*"
                                placeholder="Nature of the accident"
                                disabled={disabled}
                              />
                              <SelectField
                                name={`accidents.${index}.country`}
                                label="Country*"
                                options={updatedCountries}
                                formik={{ ...formik }}
                                disabled={disabled}
                                setCountryFieldArray={true}
                              />
                              <SelectField
                                name={`accidents.${index}.state`}
                                label="State*"
                                options={formik?.values?.accidents[index].country ? updatedStates(formik?.values?.accidents[index].country) : []}
                                disabled={disabled}
                              />
                              <InputField
                                name={`accidents.${index}.city`}
                                label="City*"
                                placeholder={"Enter City"}
                                disabled={disabled}
                              />
                              <InputField
                                name={`accidents.${index}.no_of_fatalities`}
                                label='Number of Fatalities*'
                                placeholder={"Enter Number of Fatalities"}
                                disabled={disabled}
                                type='number'
                              />
                              <InputField
                                name={`accidents.${index}.no_of_injuries`}
                                label='Number of Injuries*'
                                placeholder={"Enter Number of Injuries"}
                                disabled={disabled}
                                type='number'
                              />


                              <RadioButtonInput
                                name={`accidents.${index}.hazmat_spill`}
                                RadioText="Hazmat Spill?*"
                                optionOne="Yes"
                                optionTwo="No"
                                defaultValue={formik.values?.accidents[index]?.hazmat_spill}
                                formik={{ ...formik }}
                                disabled={disabled}
                              />

                              <Grid container>
                                <Grid item xs={12} md={6}>
                                  <Item sx={{
                                    display: 'flex',
                                    justifyContent: { xs: 'flex-end', md: 'flex-start' }
                                  }}>
                                    {formik.values?.accidents.length > 1 ?
                                      <Button
                                        variant="outlined"
                                        endIcon={<Remove />}

                                        sx={{
                                          fontSize: { xs: "0.7rem", md: "1rem" },
                                          color: "#d32f2f",
                                          border: "1px solid #d32f2f",
                                          borderRadius: "20px",
                                          '&:hover': {
                                            border: "1px solid #d32f2f",
                                            background: '#d32f2f',
                                            color: '#fff'
                                          },
                                          marginTop: '10px',
                                          textTransform: 'none'

                                        }}
                                        onClick={() => remove(index)}>Remove Accident [ {index + 1} ]</Button>
                                      : null}
                                  </Item>



                                </Grid>
                                <Grid item xs={12} md={6}>
                                  <Item sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-end'
                                  }}>
                                    <Button
                                      variant="contained"
                                      endIcon={<Add />}
                                      sx={{
                                        fontSize: { xs: "0.7rem", md: "1rem" },

                                        background:
                                          "linear-gradient(90deg,  rgba(0,41,185,1) 0%, rgba(2,119,250,1) 100%)",
                                        // width: "100%",
                                        borderRadius: "20px",
                                        color: "#fff",
                                        marginTop: '10px',

                                        // height: "2.6em",
                                      }}
                                      onClick={() => push({
                                        accident_date: "",
                                        nature: "",
                                        country: "",
                                        state: "",
                                        city: "",
                                        no_of_fatalities: "",
                                        no_of_injuries: "",
                                        hazmat_spill: "No"
                                      })}>Add Accident</Button>
                                  </Item>


                                </Grid>

                              </Grid>
                            </Grid>
                            <Box sx={{ backgroundColor: '#cfd0d1', height: '2px', marginTop: '1rem' }}></Box>
                          </React.Fragment>
                        );
                      })
                      // const {values} = form;
                    )
                  }
                </FieldArray>
              ) : null}
            </Box>

            <Box
              sx={{
                display: "flex",
                width: { xs: "100%", md: "100%" },
                justifyContent: "space-between",
                margin: "0 0 0 auto",
                padding: "0 10px 20px 10px",
                flexDirection: { xs: "column", md: "row" },
              }}
            >

              {disabled ? (
                <>
                  <Item
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                    }}
                  >
                    <Button
                      variant="contained"
                      startIcon={<KeyboardDoubleArrowLeft />}
                      sx={{
                        fontSize: { xs: "0.9rem", md: "1rem" },
                        margin: "0 0 0 auto",
                        background:
                          "linear-gradient(90deg,  rgba(0,41,185,1) 0%, rgba(2,119,250,1) 100%)",
                        width: { xs: "100%", md: "fit-content" },
                        borderRadius: "20px",
                        color: "#fff",
                        height: "2.6em",
                      }}
                      onClick={() => setvalue(1)}
                    >
                      Previous
                    </Button>
                  </Item>
                  <Item
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    <Button
                      variant="contained"
                      endIcon={<KeyboardDoubleArrowRight />}
                      sx={{
                        fontSize: { xs: "0.9rem", md: "1rem" },
                        margin: { xs: "1rem 0 0 auto", md: "0 0 0 auto" },
                        background:
                          "linear-gradient(90deg,  rgba(0,41,185,1) 0%, rgba(2,119,250,1) 100%)",
                        width: { xs: "100%", md: "fit-content" },
                        borderRadius: "20px",
                        color: "#fff",
                        height: "2.6em",
                      }}
                      onClick={() => setvalue(3)}
                    >
                      Next
                    </Button>
                  </Item>
                </>
              ) : (
                <>
                  <Item
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                    }}
                  >
                    <Button
                      variant="contained"
                      startIcon={<KeyboardDoubleArrowLeft />}
                      sx={{
                        fontSize: { xs: "0.9rem", md: "1rem" },
                        margin: "0 0 0 auto",
                        background:
                          "linear-gradient(90deg,  rgba(0,41,185,1) 0%, rgba(2,119,250,1) 100%)",
                        width: { xs: "100%", md: "fit-content" },
                        borderRadius: "20px",
                        color: "#fff",
                        height: "2.6em",
                      }}
                      onClick={() => setvalue(1)}
                    >
                      Previous
                    </Button>
                  </Item>
                  <Item
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    <Button
                      variant="contained"
                      endIcon={<KeyboardDoubleArrowRightIcon />}
                      sx={{
                        fontSize: { xs: "0.9rem", md: "1rem" },
                        margin: { xs: "1rem 0 0 auto", md: "0 0 0 auto" },
                        background:
                          "linear-gradient(90deg,  rgba(0,41,185,1) 0%, rgba(2,119,250,1) 100%)",
                        borderRadius: "20px",
                        width: { xs: "100%", md: "fit-content" },
                        color: "#fff",
                        height: "2.6em",
                      }}
                      onClick={handleEmptyNext}
                      type="submit"
                    >
                      Save & Next
                    </Button>
                  </Item>
                </>
              )}
            </Box>
            {auth.role[0] === "A" ?
              <Box sx={{ width: { xs: "100%", md: "fit-content" }, padding: "0 10px 20px 10px", }}>
                <ModalButton />
              </Box>

              : null}
          </Form>
        )}
      </Formik>
    </Box>
  );
}

export default AccidentHistoryForm;
