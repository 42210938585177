import { ThemeActions } from '../reducers/Theme-Slice'
import { actionAxios } from "../../hooks/useAxios"





const getNotification = () => {
    return async (dispatch) => {



        let data = await actionAxios.get('/notifications/unread/').then((res) => {
            let data = res.map((item) => ({
                subject: item.subject,
                body: item.body,
                time: item.timesince,
                id: item.id
            }))
            return data
        })


        await dispatch(ThemeActions.setNotification(data))
    }
}

const getCompanyInfo = () => {
    return async (dispatch) => {

        const modifyResponse = (res) => {
            return JSON.parse(JSON.stringify(res).replace(/\:null/gi, "\:\"\"")) /* eslint-disable-line */
        }
        let data = await actionAxios.get("/company")
            .then((res) => modifyResponse(res))
            .catch((err) => console.log(err))


        await dispatch(ThemeActions.setCompanyInfo(data))
    }
}





export { getNotification, getCompanyInfo }