import { Dashboard, ManageApplicants, ManageUsers, CompanyProfile, ApplicationStatus, InviteApplication, OnboardApplication, CurrentEmployees } from '../pages'


const admin_routes = [
    {
        path: "/dashboard",
        component: <Dashboard />,
    },
    {
        path: "/",
        component: <Dashboard />,
    },
    {
        path: "/manageapplicants",
        component: <ManageApplicants />,
    },
    {
        path: "/manageuser",
        component: <ManageUsers />,
    },
    {
        path: "/companyprofile",
        component: <CompanyProfile />,
    },
    {
        path: "/inviteApplication",
        component: <InviteApplication />,
    },

    {
        path: "/onboardApplication",
        component: <OnboardApplication />,
    },

    {
        path: "/application-status",
        component: <ApplicationStatus />,
    },
    {
        path: '/currentemployees',
        component: <CurrentEmployees />
    }



]

export default admin_routes;