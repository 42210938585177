import { createContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { decrypt } from "../utils/encrypt_decrypt";

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
    const accessToken = localStorage.getItem("accessToken");
    const refreshToken = localStorage.getItem("refreshToken");
    const first_name = localStorage.getItem("first_name");
    const role = localStorage.getItem("role")
        ? decrypt(localStorage.getItem("role"))
        : [];
    const user = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : '';



    const navigate = useNavigate();


    const [auth, setAuth] = useState({
        accessToken,
        refreshToken,
        role,
        user,
        first_name
    });


    //--------------- This logoutUser function is to be called for correct logout functionality------------

    const logoutUser = () => {
        setAuth({
            accessToken: null,
            refreshToken: null,
            role: null,
            user: null,
            first_name: null,
        });
        localStorage.clear();
        navigate("/login", { replace: true });
    };

    return (
        <AuthContext.Provider value={{ auth, setAuth, logoutUser }}>
            {children}
        </AuthContext.Provider>
    );
};

export default AuthContext;