import { Dashboard, ManageApplicants, InviteApplication, OnboardApplication, UserProfile, CurrentEmployees } from '../pages'


const hr_routes = [
    {
        path: "/dashboard",
        component: <Dashboard />,
    },
    {
        path: "/",
        component: <Dashboard />,
    },
    {
        path: "/manageapplicants",
        component: <ManageApplicants />,
    },
    {
        path: "/inviteApplication",
        component: <InviteApplication />,
    },

    {
        path: "/onboardApplication",
        component: <OnboardApplication />,
    },
    {
        path: "/userprofile",
        component: <UserProfile />,
    },
    {
        path: '/currentemployees',
        component: <CurrentEmployees />
    }


]

export default hr_routes;