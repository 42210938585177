import React, { useEffect } from 'react';
import RouteProtection from './routes/RouteProtection'
import Total_Route_List from './routes/Total_routeList'
import { decrypt } from "../src/utils/encrypt_decrypt";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { GetRoute } from './utils/Get_routes';
import useAuth from './hooks/useAuth';
import Layout from './layout/Layout';
import useAxios from './hooks/useAxios';
import { ThemeActions } from '../src/redux/reducers/Theme-Slice'

import {
  ForgotPassword,
  LoginPage,
  LoadingPage,
  UnAuthorized,
  NotFoundPage
} from "./pages"
import ResetPassword from './pages/ResetPassword/ResetPassword';


function App() {
  const axiosAPI = useAxios() //---This initialize axios for axios use in non-react component and in asyn calls in Redux

  let routes = []
  const location = useLocation()
  const navigate = useNavigate()
  const auth = useAuth()
  const dispatch = useDispatch()


  let role = localStorage?.getItem("role")
    ? decrypt(localStorage.getItem("role"))
    : [];





  const applicant_status = useSelector((state) => state.ApplicantLogin.applicant_status);

  useEffect(() => {

    axiosAPI.get(`/company/logo/?domain=${window.location.hostname}`).then((res) => {
      dispatch(ThemeActions.setCompanyLogo(res))
    })

  }, [])


  useEffect(() => {
    let accept = false;

    if (Total_Route_List.includes(location.pathname) && location.pathname === routes.path) accept = true;

    if (!accept && Total_Route_List.includes(location.pathname) && auth.accessToken) navigate("/unauthorized");

  }, [location.pathname]);







  const app = () => {
    routes = GetRoute(role[0], applicant_status)
    console.log(routes)
    if (role) {
      return (
        <Routes>
          <Route path="/login" element={<LoginPage />} />
          <Route path="/forgotpassword" element={<ForgotPassword />} />
          <Route path="/resetpassword" element={<ResetPassword />} />
          <Route path="/" element={<Layout />}>
            <Route
              element={
                <RouteProtection
                  allowedRoles={[
                    "CA",
                    "HR",
                    "A"
                  ]}
                />
              }
            >
              {routes?.map(({ path, component }, key) => (
                <Route path={path} element={component} key={key} />
              ))}
            </Route>
            <Route path="/unauthorized" element={<UnAuthorized />} />
            {role.length ? <Route path="*" element={<NotFoundPage />} /> : <Route path="*" element={<LoadingPage />} />}
          </Route>
        </Routes>
      )
    } else if (!role) {
      return <LoginPage requesting_url={location.pathname} />
    }


  }

  return app()
}

export default App;
