import admin_routes from '../routes/admin_routes'
import hr_routes from '../routes/hr_routes'
import getDriverRoute from '../routes/driver_routes'



export const GetRoute = (role, status) => {
    switch (role) {
        case 'CA':
            return admin_routes;
        case 'HR':
            return hr_routes;
        case 'A':
            return getDriverRoute(status);
        default:
            return []
    }
}