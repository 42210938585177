import {
  InputLabel,
  TextField,
  Grid,
  Box,
  InputAdornment,
} from "@mui/material";
import { useField } from "formik";
import React, { useState } from "react";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

function PasswordInput(props) {
  const { label, type, name, multiline, placeholder, md = 5, ...rest } = props;

  const [field, meta] = useField(name);

  const [showPassWord, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassWord);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const config = {
    ...field,
    fullWidth: true,
    multiline: multiline,
    variant: "outlined",
    autoComplete: "off",
    size: "small",
    InputProps: {
      style: {
        borderRadius: "20px",
        backgroundColor: "#E8F3FE",
        width: "100%",
      },
      endAdornment: (
        <InputAdornment position="end">
          <IconButton
            aria-label="toggle password visibility"
            onClick={handleClickShowPassword}
            onMouseDown={handleMouseDownPassword}
            edge="end"
          >
            {showPassWord ? <VisibilityOff /> : <Visibility />}
          </IconButton>
        </InputAdornment>
      ),
    },
  };

  if (meta && meta.touched && meta.error) {
    config.error = true;
    config.helperText = meta.error;
  }

  return (
    <Grid item xs={12} md={md}>
      <Grid item sx={{ width: "100%" }}>
        <Box>
          <InputLabel sx={{ paddingLeft: "0.5rem", fontWeight: "600" }}>
            {label}
          </InputLabel>
          <TextField
            {...rest}
            {...config}
            type={showPassWord ? "text" : "password"}
            inputProps={{
              style: { color: "#000" },
            }}
            placeholder={placeholder}
          />
        </Box>
      </Grid>
    </Grid>
  );
}

export default PasswordInput;
