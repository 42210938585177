import { Cancel } from '@mui/icons-material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Box, Pagination, useMediaQuery } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { SelectSmall } from '../../components';
import GridTable from '../../components/Grid_table/GridTable';
import HeaderTab from '../../components/HeaderSection/Header';
import { getCurrentEmployees } from '../../redux/thunks/ManageUsers-thunk';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import BlockIcon from '@mui/icons-material/Block';
import ArticleIcon from '@mui/icons-material/Article';
import FlagIcon from '@mui/icons-material/Flag';
import ConfirmationModel from '../../components/ModalButton/ConfirmationModel'
import ContactMailIcon from '@mui/icons-material/ContactMail';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';


const CurrentEmployees = () => {

  const tableData = useSelector((state) => state.ManageUsers.currentEmployees);
  const matches = useMediaQuery("(max-width:400px)");
  const [data, setData] = useState(tableData)
  const [page, setPage] = useState(1);
  const [row, setRow] = useState(10);
  const [search, setSearch] = useState("");
  const [filterData, setFilterData] = useState(tableData || []);
  const dispatch = useDispatch()



  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: { xs: 300, md: 400 },
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 2,
    borderRadius: '20px'
  };

  useEffect(() => {
    async function getApplicants() {
      dispatch(getCurrentEmployees());
    }

    getApplicants();
  }, []);



  useEffect(() => {
    setData(tableData);
  }, [tableData]);

  useEffect(() => {
    if (data) {
      setFilterData(
        data.filter((item) =>
          item.name.toLowerCase().includes(search.toLowerCase())
        )
      );
    }

  }, [search, data]);

  const count = Math.ceil(filterData.length / row);

  function paginator(items, current_page, per_page_items) {
    let page = current_page || 1,
      per_page = per_page_items,
      offset = (page - 1) * per_page,
      paginatedItems = items.slice(offset).slice(0, per_page_items),
      total_pages = Math.ceil(items.length / per_page);

    return {
      page: page,
      per_page: per_page,
      pre_page: page - 1 ? page - 1 : null,
      next_page: total_pages > page ? page + 1 : null,
      total: items.length,
      total_pages: total_pages,
      data: paginatedItems,
    };
  }

  const handlePagination = (e, value) => {
    setPage(paginator(filterData, value, row).page);
  };

  const tableHeader = [
    { title: "S.No", wide: 0.5 },
    { title: "Name", wide: 1 },
    { title: "Email", wide: 3 },
    { title: "Position", wide: 1.5 },
    { title: "Status", wide: 1 },
    { title: "Start Date", wide: 2 },
    { title: "End Date", wide: 1 },
    { title: "Rehire Status", wide: 1 },
    { title: "Actions", wide: 1 },
  ];

  // PopOver Props
  const PopOver = {
    set_1: [{
      icon: <HowToRegIcon sx={{ color: "green" }} />,
      text: "Hired",
      status: "hired",
    },

    {
      icon: <CheckCircleIcon sx={{ color: "green" }} />,
      text: "Active",
      status: 'active'
    },
    {
      icon: <FlagIcon sx={{ color: "#f29f22" }} />,
      text: "Rehire",
      status: 'hire'
    },
    {
      icon: <ContactMailIcon sx={{ color: "#1760a5" }} />,
      text: "Invite Details",
      status: "invite",
    },
    {
      icon: <ArticleIcon sx={{ color: "#1760a5" }} />,
      text: "Onboard Details",
      status: "onboard",
    },
    {
      icon: <CloudUploadIcon sx={{ color: "#2a79f7" }} />,
      text: "PIP Document",
      status: 'upload'
    },

    ],
    set_2: [
      {
        icon: <Cancel sx={{ color: "#d1060a" }} />,
        text: "Inactive",
        status: 'inactive'
      },
      {
        icon: <BlockIcon sx={{ color: "#d1060a" }} />,
        text: "Terminate",
        status: 'terminated'
      }],


  };

  const GridTableProps = {
    tableHead: tableHeader,
    tableBody: paginator(filterData, page, row).data,
    viewApplication: false,
    popOver: PopOver,
    md: [1, 3, 1.5, 1, 2, 1, 1],
    smTitleWide: '30%'
  };

  const headerTabProp = {
    startTitle: "Current Employees",

  };



  return (
    <>
      <Box
        sx={{
          background: "rgba( 255, 255, 255, 0.1 )",

          boxShadow: "0 5px 32px 5px #e2e4f8",

          backdropFilter: "blur( 20px )",
          webkitBackdropFilter: "blur( 20px )",
          borderRadius: "10px",
          border: "1px solid rgba( 255, 255, 255, 0.18 )",
          margin: { xs: "0 10px 20px 10px", sm: "2rem 30px 20px 30px" },
          padding: "20px 0",
          borderRadius: "10px",
        }}
      >
        <Box sx={{ width: { xs: "95%", md: "100%" }, padding: '0 10px' }} >
          {/* Header Section ---------------------------------------------------------------------------------------------------------------------------------------*/}
          <HeaderTab
            headerTabProp={headerTabProp}
            setSearch={setSearch}
            setPage={setPage}
            page={page}
          />

          {/* Grid Table ------------------------------------------------------------------------------------------------------------------------------------------- */}
          <GridTable tableProps={GridTableProps} />

          {/* Pagination ------------------------------------------------------------------------------------------------------------------------------------------- */}
          <Box
            sx={{
              display: "flex",
              alignItems: { xs: "end", md: "center" },
              justifyContent: "right",
              flexDirection: { xs: "column", md: "row" },
            }}
          >
            <SelectSmall setRow={setRow} />
            <Pagination
              count={count}
              page={page}
              onChange={handlePagination}
              color="primary"
              variant="outlined"
              shape="rounded"
              size={matches ? "small" : "medium"}
            />
          </Box>
        </Box>
      </Box>
      <ConfirmationModel />
    </>
  );
}

export default CurrentEmployees