import React, { useState } from "react";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import { PropTypes } from "prop-types";
import { styled } from "@mui/material/styles";
import { toast } from "react-toastify";
import useAuth from "../../hooks/useAuth";

function TabView({ tabProps }) {
  const [value, setValue] = useState(0);
  const { auth } = useAuth();

  const { tab_details, children, sm, md } = tabProps;

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeApplicant = (event, newValue) => {
    if (newValue < value) {
      setValue(newValue);
    } else {
      toast.error("Save Form to Continue");
    }
  };

  function TabPanel(props) {
    const { children, value, index, ...other } = props;
    let newChild = {
      ...children,
      props: { ...children.props, setvalue: setValue },
    };
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ marginTop: 5 }}>{newChild}</Box>}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
    setValue: PropTypes.func,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  const StyledTabs = styled((props) => (
    <Tabs
      {...props}
      TabIndicatorProps={{
        children: <span className="MuiTabs-indicatorSpan" />,
      }}
    />
  ))({
    "& .MuiTabs-indicator": {
      display: "flex",
      justifyContent: "center",
      backgroundColor: "transparent",
    },
    "& .MuiTabs-indicatorSpan": {
      // maxWidth: 80,
      width: "100%",
      backgroundColor: "#0277fa",
    },
  });

  const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
    ({ theme }) => ({
      textTransform: "none",
      fontWeight: "400",
      fontSize: "1rem",
      marginRight: theme.spacing(1),
      color: "rgba(255, 255, 255, 0.7)",
      "&.Mui-selected": {
        color: theme.palette.primary.button,
      },
      "&.Mui-focusVisible": {
        backgroundColor: "rgba(100, 95, 228, 0.32)",
      },
    })
  );
  return (
    <Container sx={{ padding: "0", marginBottom: "0.6rem" }} maxWidth="xl">
      <Box sx={{ width: "100%" }}>
        <StyledTabs
          value={value}
          onChange={auth.role[0] === "A" ? handleChangeApplicant : handleChange}
          // textColor="secondary"
          indicatorColor="secondary"
          aria-label="secondary tabs example"
          sx={{ width: "100%" }}
        >
          {tab_details.map((item, index) => (
            <StyledTab
              sx={{ color: "#000" }}
              value={index}
              key={index}
              label={
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {item.icon}
                  <Typography
                    sx={{
                      textTransform: "capitalize",
                      fontSize: { xs: "0.8rem", sm: sm, md: md },
                    }}
                  >
                    {item.text}
                  </Typography>
                </Box>
              }
              {...a11yProps(index)}
            />
          ))}
        </StyledTabs>
      </Box>
      {children.map((item, index) => (
        <TabPanel
          value={value}
          index={index}
          children={item}
          sx={{ padding: "0" }}
          key={index}
          setvalue={setValue}
        />
      ))}
    </Container>
  );
}

export default TabView;
