import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useField } from "formik";
import { Box, Grid, Typography } from "@mui/material";
import { useState } from "react";

export const SelectSmall = ({ setRow }) => {
  const [optionValue, setOption] = useState(10);

  const handleChange = (event) => {
    setOption(event.target.value);
    setRow(event.target.value)
  };

  return (
    <FormControl sx={{ m: 0.5, minWidth: 120 }} size="small">
      <InputLabel id="demo-select-small" sx={{ fontSize: "0.8rem" }}>
        Rows
      </InputLabel>
      <Select
        labelId="demo-select-small"
        id="demo-select-small"
        value={optionValue}
        label="Age"
        onChange={handleChange}
      >
        <MenuItem value={10}>10</MenuItem>
        <MenuItem value={20}>20</MenuItem>
        <MenuItem value={30}>30</MenuItem>
      </Select>
    </FormControl>
  );
};

export const SelectField = (props) => {
  const { label, name, options } = props;
  const [field, meta] = useField(name);

  const [selectProp, setSelectProp] = useState(meta);
  // const menuProps = {
  //   background: "#000",
  //   color: "#fff",
  // };

  return (
    <>
      <Grid container>
        <Grid item xs={12} md={5}>
          <Box>
            <InputLabel sx={{ paddingLeft: "0.5rem", fontWeight: "600" }}>
              {label}
            </InputLabel>
            <Box
              sx={{
                border:
                  selectProp.touched && meta.error ? "1px solid red" : "none",
                borderRadius: "20px",
              }}
            >
              <Select
                labelId="select-normal"
                id="select"
                sx={{
                  borderRadius: "20px",
                  backgroundColor: "#E8F3FE",
                  width: "100%",
                  height: "2.6em",
                }}
                {...field}
                onBlur={() => setSelectProp({ ...meta, touched: true })}
              // MenuProps={menuProps}
              //   label="Age"
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {options.map((item, index) => (
                  <MenuItem key={index} value={item.value}>
                    {item.title}
                  </MenuItem>
                ))}
              </Select>
            </Box>
          </Box>
          {selectProp.touched && meta.error && (
            <Box>
              <Typography
                sx={{
                  color: "#d32f2f",
                  fontSize: "0.75rem",
                  fontWeight: "400",
                  marginLeft: "14px",
                }}
              >
                {meta.error}
              </Typography>
            </Box>
          )}
        </Grid>
      </Grid>
    </>
  );
};
