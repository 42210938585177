import React from 'react'
import { Box, Typography } from "@mui/material";
function NotFound() {
    return (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '90vh', width: '100%' }}>
            <Typography variant='h1' sx={{
                color: '#0277fa', fontSize: { xs: '2rem', sm: '4rem' }, fontWeight: '400'
            }}>
                Not Found !
            </Typography>
        </Box>
    )
}

export default NotFound