import { KeyboardDoubleArrowRight, KeyboardDoubleArrowLeft, FormatStrikethroughRounded } from '@mui/icons-material';
import { Box, Button, Typography, Grid } from '@mui/material'
import { Form, Formik } from 'formik'
import React, { useState, useRef } from 'react'
import { ModalButton, RadioButtonSelect } from '../../components'
import InputField from "../../components/FormikControl/Text_input/TextInput";
import useAxios from "../../hooks/useAxios";
import { ApplicantLoginActions } from '../../redux/reducers/ApplicantLogin-Slice'
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import { useDispatch, useSelector } from "react-redux";
import styled from "@emotion/styled";
import * as yup from 'yup'

const AdverseLicense = ({
  auth,
  initValue,
  disabled,
  setvalue
}) => {

  const formikRef = useRef()


  const applicationData = useSelector((state) => state.ApplicantLogin.applicationData);

  const [showFrom, setShowForm] = useState(initValue.details?.adverse_lic_actions === 'Yes' ? true : false)
  const [showSuspend, setShowSuspend] = useState(initValue.details?.lic_suspended === 'Yes' ? true : false)

  const AxiosApi = useAxios();
  const dispatch = useDispatch()

  console.log(initValue)

  const validation = yup.object().shape({
    details: yup.object({
      a_year: yup.string().when('adverse_lic_actions', {
        is: 'Yes',
        then: yup.string()
          .required('Required'),
        otherwise: yup.string(),
      }),
      a_reason: yup.string().when('adverse_lic_actions', {
        is: 'Yes',
        then: yup.string()
          .required('Required'),
        otherwise: yup.string(),
      }),
      s_year: yup.string().when('lic_suspended', {
        is: 'Yes',
        then: yup.string()
          .required('Required'),
        otherwise: yup.string(),
      }),
      s_reason: yup.string().when('lic_suspended', {
        is: 'Yes',
        then: yup.string()
          .required('Required'),
        otherwise: yup.string(),
      }),

    })
  })

  const handleNext = (values) => {
    if (initValue?.id) {
      AxiosApi.put(`/application/adverse_licensing_action/${values.id}`, values).then((res) => {
        dispatch(ApplicantLoginActions.adverseLicensing(res))
        setvalue(5);
      })
    } else {
      let obj = { ...values, applicant: applicationData?.id }
      AxiosApi.post(`/application/adverse_licensing_action`, auth.role[0] === "A" ? obj : values).then((res) => {
        dispatch(ApplicantLoginActions.adverseLicensing(res))
        setvalue(5);
      })
    }
  }


  const handleEmptyNext = () => {
    if (showFrom === false) setvalue(4)
  }



  const handleAdverse = (e, setFieldValue) => {
    setShowForm(e)
    setFieldValue('details.adverse_lic_actions', e ? 'Yes' : 'No')
  }


  const handleSuspend = (e, setFieldValue) => {
    setShowSuspend(e)
    setFieldValue('details.lic_suspended', e ? 'Yes' : 'No')
  }

  const Item = styled(Box)({});

  return (
    <Box sx={{
      padding: "0.9rem",
      borderRadius: "10px",
      marginBottom: "2rem",
      backgroundColor: "#fff",
    }}>
      <Formik
        initialValues={initValue}
        validationSchema={validation}
        onSubmit={handleNext}
        innerRef={formikRef}
      >{({ setFieldValue, ...formik }) => (


        <Form>
          <Box sx={{
            borderRadius: "10px",
            backgroundColor: "#fff",
          }}>

            <Typography
              sx={{
                fontSize: "1.2rem",
                marginLeft: "0.5rem",
                marginBottom: "1rem",
              }}
            >
              Adverse Licensing Actions
            </Typography>
            <RadioButtonSelect
              name={`details.adverse_lic_actions`}
              RadioText="Have you ever been denied a license, permit, or privilege to operate a motor vehicle?"
              optionOne="Yes"
              optionTwo="No"
              setShowField={(e) => handleAdverse(e, setFieldValue)}
              disabled={disabled}
              defaultValue={initValue.details?.adverse_lic_actions === 'Yes' ? 'Yes' : 'No'}
            />
          </Box>
          {showFrom && <>
            <Grid container
              sx={{ justifyContent: "space-between" }}
              rowGap={1.5}>
              <InputField
                name={`details.a_year`}
                label='When(Enter Year)*'
                type='number'
                disabled={disabled}
              />
              <InputField
                name={`details.a_reason`}
                label='Reason*'
                type='string'
                disabled={disabled}
              />
            </Grid>
          </>}
          <Box sx={{ marginTop: '2rem' }} >
          </Box>
          <RadioButtonSelect
            name={`details.lic_suspended`}
            RadioText="Has any license, permit, or privilege ever been suspended or revoked ?"
            optionOne="Yes"
            optionTwo="No"
            setShowField={(e) => handleSuspend(e, setFieldValue)}
            disabled={disabled}
            defaultValue={initValue.details?.lic_suspended === 'Yes' ? 'Yes' : 'No'}
          />

          {

            showSuspend && <>
              <Grid container
                sx={{ justifyContent: "space-between" }}
                rowGap={1.5}>
                <InputField
                  name={`details.s_year`}
                  label='When(Enter Year)*'
                  type='number'
                  disabled={disabled}
                />
                <InputField
                  name={`details.s_reason`}
                  label='Reason*'
                  type='string'
                  disabled={disabled}
                />
              </Grid>
            </>}

          <Box
            sx={{
              display: "flex",
              width: { xs: "100%", md: "100%" },
              justifyContent: "space-between",
              margin: "2rem 0 0 auto",
              padding: "0 10px 20px 10px",
              flexDirection: { xs: "column", md: "row" },
            }}
          >

            {disabled ? (
              <>
                <Item
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                  }}
                >
                  <Button
                    variant="contained"
                    startIcon={<KeyboardDoubleArrowLeft />}
                    sx={{
                      fontSize: { xs: "0.9rem", md: "1rem" },
                      margin: "0 0 0 auto",
                      background:
                        "linear-gradient(90deg,  rgba(0,41,185,1) 0%, rgba(2,119,250,1) 100%)",
                      width: { xs: "100%", md: "fit-content" },
                      borderRadius: "20px",
                      color: "#fff",
                      height: "2.6em",
                    }}
                    onClick={() => setvalue(3)}
                  >
                    Previous
                  </Button>
                </Item>
                <Item
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <Button
                    variant="contained"
                    endIcon={<KeyboardDoubleArrowRight />}
                    sx={{
                      fontSize: { xs: "0.9rem", md: "1rem" },
                      margin: { xs: "1rem 0 0 auto", md: "0 0 0 auto" },
                      background:
                        "linear-gradient(90deg,  rgba(0,41,185,1) 0%, rgba(2,119,250,1) 100%)",
                      width: { xs: "100%", md: "fit-content" },
                      borderRadius: "20px",
                      color: "#fff",
                      height: "2.6em",
                    }}
                    onClick={() => setvalue(5)}
                  >
                    Next
                  </Button>
                </Item>
              </>
            ) : (
              <>
                <Item
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                  }}
                >
                  <Button
                    variant="contained"
                    startIcon={<KeyboardDoubleArrowLeft />}
                    sx={{
                      fontSize: { xs: "0.9rem", md: "1rem" },
                      margin: "0 0 0 auto",
                      background:
                        "linear-gradient(90deg,  rgba(0,41,185,1) 0%, rgba(2,119,250,1) 100%)",
                      width: { xs: "100%", md: "fit-content" },
                      borderRadius: "20px",
                      color: "#fff",
                      height: "2.6em",
                    }}
                    onClick={() => setvalue(3)}
                  >
                    Previous
                  </Button>
                </Item>
                <Item
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <Button
                    variant="contained"
                    endIcon={<KeyboardDoubleArrowRightIcon />}
                    sx={{
                      fontSize: { xs: "0.9rem", md: "1rem" },
                      margin: { xs: "1rem 0 0 auto", md: "0 0 0 auto" },
                      background:
                        "linear-gradient(90deg,  rgba(0,41,185,1) 0%, rgba(2,119,250,1) 100%)",
                      borderRadius: "20px",
                      width: { xs: "100%", md: "fit-content" },
                      color: "#fff",
                      height: "2.6em",
                    }}
                    onClick={handleEmptyNext}
                    type="submit"
                  >
                    Save & Next
                  </Button>
                </Item>
              </>
            )}
          </Box>
          {auth.role[0] === "A" ?
            <Box sx={{ width: { xs: "100%", md: "fit-content" }, padding: "0 10px 20px 10px", }}>
              <ModalButton />
            </Box>

            : null}
        </Form>)}
      </Formik>
    </Box>
  )
}

export default AdverseLicense