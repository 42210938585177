import styled from '@emotion/styled'
import { Add, Remove, KeyboardDoubleArrowRight, KeyboardDoubleArrowLeft } from '@mui/icons-material'
import { Box, Button, Grid, Typography } from '@mui/material'
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import { FieldArray, Form, Formik } from 'formik'
import React, { useState } from 'react'
import { getAllCountries, getStates } from '../../assets/json/country'
import { ModalButton, RadioButtonSelect } from '../../components'
import { SelectField } from '../../components/FormikControl/Select_input/Select'
import InputField from "../../components/FormikControl/Text_input/TextInput";
import useAxios from "../../hooks/useAxios";
import { ApplicantLoginActions } from '../../redux/reducers/ApplicantLogin-Slice'
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";

const TrafficViolation = ({
  auth,
  initValue,
  disabled,
  setvalue,
}) => {

  const [showFrom, setShowForm] = useState(initValue?.convictions[0]?.country ? true : false)

  const updatedStates = (countryId) => {
    const List = getStates(countryId)[0];
    return List.states.map((item) => ({
      title: item,
      value: item,
    }));
  };

  const dispatch = useDispatch()

  const AxiosApi = useAxios();

  const updatedCountries = getAllCountries().map((country) => ({
    title: country,
    value: country,
  }));


  const applicationData = useSelector((state) => state.ApplicantLogin.applicationData);


  const TrafficViolationFormValidation = yup.object().shape({
    convictions: yup.array().of(yup.object().shape({
      country: yup.string().required('Required'),
      state: yup.string().required('Required'),
      date: yup.string().required('Required'),
      charge: yup.number().typeError("Enter digits").min(0, "Min Value 0").required("Required"),
      penalty: yup.number().typeError("Enter digits").min(0, "Min Value 0").required("Required")
    }))
  })

  const Item = styled(Box)({});


  const pushValue = {
    country: "",
    state: "",
    date: "",
    charge: '',
    penalty: ''
  }


  const handleNext = (values) => {
    if (initValue?.convictions[0]?.country) {
      AxiosApi.put(`/application/traffic_violation/${values.id}`, values).then((res) => {
        dispatch(ApplicantLoginActions.trafficViolation(res))
        setvalue(4);
      })
    } else {
      let obj = { ...values, applicant: applicationData?.id }
      AxiosApi.post(`/application/traffic_violation`, auth.role[0] === "A" ? obj : values).then((res) => {
        dispatch(ApplicantLoginActions.trafficViolation(res))
        setvalue(4);
      })
    }
  }


  const handleEmptyNext = () => {
    if (showFrom === false) setvalue(4)
  }

  return (
    <Box>
      <Box sx={{
        padding: "0.9rem",
        borderRadius: "10px",
        marginBottom: "2rem",
        backgroundColor: "#fff",
      }}>
        <Typography
          sx={{
            fontSize: "1.2rem",
            marginLeft: "0.5rem",
            marginBottom: "1rem",
          }}
        >
          Traffic Violation
        </Typography>
        <RadioButtonSelect
          name={`employment_status`}
          RadioText="Any traffic convictions and/or forfeitures in the past 3 years? (Other than parking violations) ?"
          optionOne="Yes"
          optionTwo="No"
          setShowField={setShowForm}
          defaultValue={initValue?.convictions[0]?.country ? 'Yes' : 'No'}
          disabled={disabled}

        />

        <Formik
          initialValues={initValue}
          validationSchema={TrafficViolationFormValidation}
          onSubmit={handleNext}
        >
          {({ values, ...formik }) => (

            <Form>
              {showFrom && <FieldArray name='convictions'>
                {({ push, remove }) => (

                  values.convictions.length > 0 &&
                  values.convictions.map((res, index) => <React.Fragment key={index}>
                    <Grid
                      container
                      sx={{ justifyContent: "space-between" }}
                      rowGap={1.5}
                    >
                      <Grid item xs={12}>
                        <Typography
                          sx={{
                            fontSize: "1.1rem",
                            fontWeight: '500',
                            margin: '1.4rem 0 0.5rem 0.5rem',
                            letterSpacing: '2px'
                          }}
                        >
                          Conviction : {index + 1}
                        </Typography>
                      </Grid>
                      <SelectField
                        name={`convictions.${index}.country`}
                        label="Country*"
                        options={updatedCountries}
                        formik={{ ...formik }}
                        disabled={disabled}
                      />

                      <SelectField
                        placeholder="State*"
                        name={`convictions.${index}.state`}
                        label="Location (State of violation)*"
                        options={values?.convictions[index]?.country ? updatedStates(values?.convictions[index]?.country) : []}
                        disabled={disabled}
                      />
                      <InputField
                        name={`convictions.${index}.date`}
                        label="Date*"
                        type='date'
                        disabled={disabled}
                      />
                      <InputField
                        name={`convictions.${index}.charge`}
                        label='Charge*'
                        type='number'
                        disabled={disabled}

                      />

                      <InputField
                        name={`convictions.${index}.penalty`}
                        label='Penalty (Forfieted bond, collateral and/or points)*'
                        type='number'
                        disabled={disabled}
                      />
                      <Grid container>
                        <Grid item xs={12} md={6}>
                          <Item sx={{
                            display: 'flex',
                            justifyContent: { xs: 'flex-end', md: 'flex-start' }
                          }}>
                            {values.convictions.length > 1 ?
                              <Button
                                variant="outlined"
                                endIcon={<Remove />}
                                sx={{
                                  fontSize: { xs: "0.7rem", md: "1rem" },
                                  color: "#d32f2f",
                                  border: "1px solid #d32f2f",
                                  borderRadius: "20px",
                                  '&:hover': {
                                    border: "1px solid #d32f2f",
                                    background: '#d32f2f',
                                    color: '#fff'
                                  },
                                  marginTop: '10px',
                                  textTransform: 'none'

                                }}
                                onClick={() => remove(index)}>Remove Conviction [ {index + 1} ]</Button>
                              : null}
                          </Item>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Item sx={{
                            display: 'flex',
                            justifyContent: 'flex-end'
                          }}>
                            <Button
                              variant="contained"
                              endIcon={<Add />}
                              sx={{
                                fontSize: { xs: "0.7rem", md: "1rem" },

                                background:
                                  "linear-gradient(90deg,  rgba(0,41,185,1) 0%, rgba(2,119,250,1) 100%)",
                                // width: "100%",
                                borderRadius: "20px",
                                color: "#fff",
                                marginTop: '10px',

                                // height: "2.6em",
                              }}
                              onClick={() => push(pushValue)}>Add Conviction</Button>
                          </Item>
                        </Grid>
                      </Grid>

                    </Grid>
                  </React.Fragment>
                  )

                )}
              </FieldArray>
              }


              <Box
                sx={{
                  display: "flex",
                  width: { xs: "100%", md: "100%" },
                  justifyContent: "space-between",
                  margin: "2rem 0 0 auto",
                  padding: "0 10px 20px 10px",
                  flexDirection: { xs: "column", md: "row" },
                }}
              >
                {disabled ? (
                  <>
                    <Item
                      sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                      }}
                    >
                      <Button
                        variant="contained"
                        startIcon={<KeyboardDoubleArrowLeft />}
                        sx={{
                          fontSize: { xs: "0.9rem", md: "1rem" },
                          margin: "0 0 0 auto",
                          background:
                            "linear-gradient(90deg,  rgba(0,41,185,1) 0%, rgba(2,119,250,1) 100%)",
                          width: { xs: "100%", md: "fit-content" },
                          borderRadius: "20px",
                          color: "#fff",
                          height: "2.6em",
                        }}
                        onClick={() => setvalue(2)}
                      >
                        Previous
                      </Button>
                    </Item>
                    <Item
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                    >
                      <Button
                        variant="contained"
                        endIcon={<KeyboardDoubleArrowRight />}
                        sx={{
                          fontSize: { xs: "0.9rem", md: "1rem" },
                          margin: { xs: "1rem 0 0 auto", md: "0 0 0 auto" },
                          background:
                            "linear-gradient(90deg,  rgba(0,41,185,1) 0%, rgba(2,119,250,1) 100%)",
                          width: { xs: "100%", md: "fit-content" },
                          borderRadius: "20px",
                          color: "#fff",
                          height: "2.6em",
                        }}
                        onClick={() => setvalue(4)}
                      >
                        Next
                      </Button>
                    </Item>
                  </>
                ) : (
                  <>
                    <Item
                      sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                      }}
                    >
                      <Button
                        variant="contained"
                        startIcon={<KeyboardDoubleArrowLeft />}
                        sx={{
                          fontSize: { xs: "0.9rem", md: "1rem" },
                          margin: "0 0 0 auto",
                          background:
                            "linear-gradient(90deg,  rgba(0,41,185,1) 0%, rgba(2,119,250,1) 100%)",
                          width: { xs: "100%", md: "fit-content" },
                          borderRadius: "20px",
                          color: "#fff",
                          height: "2.6em",
                        }}
                        onClick={() => setvalue(2)}
                      >
                        Previous
                      </Button>
                    </Item>
                    <Item
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                    >
                      <Button
                        variant="contained"
                        endIcon={<KeyboardDoubleArrowRightIcon />}
                        sx={{
                          fontSize: { xs: "0.9rem", md: "1rem" },
                          margin: { xs: "1rem 0 0 auto", md: "0 0 0 auto" },
                          background:
                            "linear-gradient(90deg,  rgba(0,41,185,1) 0%, rgba(2,119,250,1) 100%)",
                          borderRadius: "20px",
                          width: { xs: "100%", md: "fit-content" },
                          color: "#fff",
                          height: "2.6em",
                        }}
                        onClick={handleEmptyNext}
                        type="submit"
                      >
                        Save & Next
                      </Button>
                    </Item>
                  </>
                )}
              </Box>
              {auth.role[0] === "A" ?
                <Box sx={{ width: { xs: "100%", md: "fit-content" }, padding: "0 10px 20px 10px", }}>
                  <ModalButton />
                </Box>

                : null}
            </Form>
          )}
        </Formik>

      </Box>
    </Box>
  )
}

export default TrafficViolation