import { Button, Grid } from "@mui/material";
import React from "react";

function ButtonInput(props) {
  const { md = 6, label, padding = "default", type } = props;
  return (
    <>
      <Grid item xs={12} md={md}>
        <Grid item sx={{ width: "100%" }}>
          <Button
            sx={{
              background:
                "linear-gradient(90deg,  rgba(0,41,185,1) 0%, rgba(2,119,250,1) 100%)",
              width: "100%",
              borderRadius: "20px",
              color: "#fff",
              height: "3em",
              padding: { padding },
            }}
            type={type}
          >
            {label}
          </Button>
        </Grid>
      </Grid>
    </>
  );
}

export default ButtonInput;
