import React, { useEffect, useState } from "react";
import { Link, Outlet, useNavigate } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  Typography,
  Avatar
} from "@mui/material";
import SideBar from "../components/Sidebar/SideBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import { decrypt, encrypt } from "../utils/encrypt_decrypt";
import useAuth from "../hooks/useAuth";
import axios from "axios";
import useAxios from "../hooks/useAxios";
import { useDispatch } from "react-redux";
import BellNotification from "../components/BellNotification/BellNotification";
import { getNotification } from "../../src/redux/thunks/Theme-thunk";
import { ApplicantLoginActions } from "../redux/reducers/ApplicantLogin-Slice"
import {
  LoadingPage
} from "../pages"

import MenuIcon from "@mui/icons-material/Menu";
import styled from "@emotion/styled";

const drawerWidth = 240;

function Layout() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const AxiosApi = useAxios();

  let accessToken = localStorage.getItem("accessToken");
  let role = localStorage.getItem("role")
    ? decrypt(localStorage.getItem("role"))[0]
    : ["D"];

  const { setAuth, auth } = useAuth();

  const StyledToolbar = styled(Toolbar)({
    display: "flex",
    justifyContent: "space-between",
  });

  const Icons = styled(Box)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    gap: "10px",
    [theme.breakpoints.up("sm")]: {
      display: "flex",
      gap: "20px",
    },
  }));

  const [mobileOpen, setMobileOpen] = useState(false);



  const [applicantMode, setApplicantMode] = useState(true);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleDriverLogin = (route, refresh) => {
    axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/accounts/token/refresh/`,
        {
          refresh: refresh,
        },
        {
          headers: {
            "Content-Type": "application/json",
            LMD_HOST_NAME: window.location.hostname,
          },
        }
      )
      .then((res) => {
        localStorage.setItem("accessToken", res?.data?.access);
        localStorage.setItem("refreshToken", refresh);
        localStorage.setItem("role", encrypt(["A"]));
        setAuth({
          accessToken: res?.data?.access,
          refreshToken: refresh,
          role: ["A"],
        });
        dispatch(ApplicantLoginActions.applicant_status(res?.data?.application_status))
        if (
          res.data.application_status === "rejected" ||
          res.data.application_status === "declined" ||
          res.data.application_status === 'completed' ||
          res.data.application_status === 'on_boarded'
        ) {
          navigate("/application-status", { replace: true });
        } else {
          navigate(`/${route}`, {
            state: { user: false, applicant: true },
            replace: true,
          });
        }
      });
  }

  useEffect(() => {
    if (auth.role[0] !== "A" && auth.accessToken) {
      dispatch(getNotification());
    }
  }, []);

  useEffect(() => {
    if (window.location.href.includes("/application/?refresh=")) {
      let refresh = window.location.href.split("=")[1].replace("&access", "");
      handleDriverLogin('inviteApplication', refresh)
    } else if (window.location.href.includes("/application/onboard/?refresh=")) {
      let refresh = window.location.href.split("=")[1].replace("&access", "");
      handleDriverLogin('onboardApplication', refresh)
    } else if (
      window.location.href.includes("/password_reset_confirm?token=")
    ) {
      let token = window.location.href.split("=")[1];
      navigate("/resetpassword", { state: { token } });
    } else if (accessToken && (role === "CA" || role === "HR")) {
      setApplicantMode(false);
    } else if (accessToken === null) {
      navigate("/login", { state: { requesting_url: window.location.href } });
    }
  }, []);

  const drawer = (
    <div>
      <SideBar />
    </div>
  );



  const container =
    window !== undefined ? () => window.document.body : undefined;



  return applicantMode ? (
    role === "A" ? <Outlet /> : <LoadingPage />
  ) : (
    <Box
      sx={{
        display: "flex",
        background: "#fcfbfc",
        height: { xs: "100%", sm: "100vh" },
      }}
    >
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
          boxShadow: "none",
          background: "none",
        }}
      >
        <StyledToolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon sx={{ fill: "#000" }} />
          </IconButton>
          <Typography
            variant="h6"
            sx={{ display: { xs: "none", sm: "block" } }}
          >
            {""}
          </Typography>
          {/* <Code sx={{ display: { xs: "none", sm: "none" } }} /> */}

          <Icons>
            <BellNotification />
            <Link
              to={auth.role[0] === "CA" ? "/companyprofile" : "/userprofile"}
            >
              <Avatar
                sx={{
                  width: 35,
                  height: 35,
                  background: "#fff",
                  boxShadow: "2px 6px 30px 5px #ebebeb",
                }}
              >
                <PersonOutlineOutlinedIcon sx={{ fill: "#00100B" }} />
              </Avatar>
            </Link>
          </Icons>
        </StyledToolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          // p: 3,
          // marginTop: 3,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        <Toolbar
          sx={{
            background: { xs: "#fff", sm: "none" },
            marginBottom: { xs: "25px", sm: "0" },
          }}
        />

        {/* <Toolbar /> */}
        <Outlet />
      </Box>
    </Box>
  );
}

export default Layout;
