import { createSlice } from "@reduxjs/toolkit";


const initialState = {
    PIP_Document: '',
}

const CurrentEmployeeSlice = createSlice({

    name: "CurrentEmployee",
    initialState,
    reducers: {
        PIP_Document(state, action) {
            state.PIP_Document = action.payload
        },

    }

})


export const CurrentEmployeeActions = CurrentEmployeeSlice.actions

export default CurrentEmployeeSlice