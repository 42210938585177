import { ManageUserActions } from '../reducers/ManageUser-Slice'
import { actionAxios } from "../../hooks/useAxios"





const getUserTableData = () => {
    return async (dispatch) => {
        const modifyResponse = (res) => {
            let tempData = [];

            res.forEach((item) => (
                tempData.push({
                    id: item?.id,
                    name: `${capitalizeFirstLetter(item?.first_name)} ${capitalizeFirstLetter(item?.last_name)}`,
                    email: item?.email,
                    phone: item?.phone === null ? '' : item?.phone,
                    role: item?.roles[0]
                })
            ))
            return tempData;
        }

        const requests = async () => {
            let temData = []
            const endPoint = '/company/hr/'
            temData = await actionAxios.get(endPoint)
                .then((res) => modifyResponse(res))
                .catch((err) => console.log(err))

            return temData;

        }

        await dispatch(ManageUserActions.tableData(await requests()))
    }
}

function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}
function humanize(str) {
    if (str === '') return ''
    var i, frags = str.split('_');
    for (i = 0; i < frags.length; i++) {
        frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
    }
    return frags.join(' ');
}

const getCurrentEmployees = () => {
    return async (dispatch) => {
        const modifyResponse = (res) => {
            let tempData = [];
            console.log('from line number 47 manageuser thunk', res)
            res.forEach((item) => (
                tempData.push({
                    id: item?.id,
                    application: item?.application,
                    pip_document: item?.pip_document,
                    name: capitalizeFirstLetter(item?.name),
                    email: item?.email,
                    position: item?.position,
                    status: humanize(item?.status),
                    start_date: item?.start_date === null ? '' : item?.start_date,
                    end_date: item?.end_date === null ? '' : item?.end_date,
                    rehire_status: item?.rehire_status ? item.rehire_status : '---',
                    not_rehire_reason: item?.not_rehire_reason ? item?.not_rehire_reason : 'Not Available'
                })
            ))
            return tempData;
        }

        const requests = async () => {
            let temData = []
            const endPoint = '/company/current_employee/'
            temData = await actionAxios.get(endPoint)
                .then((res) => modifyResponse(res))
                .catch((err) => console.log(err))

            return temData;

        }

        await dispatch(ManageUserActions.currentEmployees(await requests()))
    }
}




const getUserData = (id) => {
    return async (dispatch) => {
        // const modifyResponse = (res) => {
        //     let tempData = [];

        //     res.forEach((item) => (
        //         tempData.push({
        //             name: `${item.user.first_name}${item.user.last_name}`,
        //             email: item.user.email,
        //             status: item.status
        //         })
        //     ))
        //     return tempData;
        // }

        const requests = async () => {

            const endPoint = `/application/${id}/`
            await actionAxios.get(endPoint)
                .then((res) => { return res; })
                .catch((err) => { console.log(err); return {}; })


        }

        await dispatch(ManageUserActions.userData(await requests()))
    }
}


export { getUserTableData, getUserData, getCurrentEmployees }