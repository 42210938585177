import * as React from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { useState } from "react";
import { useField } from "formik";
import { Typography } from "@mui/material";

export function RadioButtonSelect({
  setShowField,
  ...props
}) {

  const [active, setActive] = useState('')
  return (
    <FormControl sx={{ width: '100%' }}>
      <FormLabel id="radio-button-select">{props.RadioText}</FormLabel>
      <RadioGroup
        defaultValue={props.defaultValue}
        name="radio-buttons-select"
        onChange={(value) => {
          if (value.target.value === "Yes") {
            setShowField(true);
          } else if (value.target.value === "Other") {
            setShowField(true);
          } else {
            setShowField(false);
          }
          props.formik && props.formik.setFieldValue(props.name, value?.target?.value)
          setActive(value.target?.value)
        }}
      >
        <FormControlLabel
          value={props.optionOne}
          control={<Radio />}
          label={props.optionOne}
          sx={{
            width: '100%', borderRadius: '15px', backgroundColor: active === props.optionOne ? '#E8F3FE !important' : '', marginBottom: '0.5rem', marginTop: '0.5rem', '&:hover': {
              backgroundColor: '#f5f6f7'
            }
          }}
          disabled={props.disabled}
        />
        <FormControlLabel
          value={props.optionTwo}
          control={<Radio />}
          label={props.optionTwo}
          sx={{
            width: '100%', borderRadius: '15px', backgroundColor: active === props.optionTwo ? '#E8F3FE !important' : '', marginBottom: '0.5rem', '&:hover': {
              backgroundColor: '#f5f6f7'
            }
          }}
          disabled={props.disabled}
        />
        {props?.optionThree ? (
          <FormControlLabel
            value={props?.optionThree}
            control={<Radio />}
            label={props?.optionThree}
            sx={{
              width: '100%', borderRadius: '15px', backgroundColor: active === props?.optionThree ? '#E8F3FE !important' : '', marginBottom: '0.5rem', '&:hover': {
                backgroundColor: '#f5f6f7'
              }
            }}
            disabled={props.disabled}
          />
        ) : null}
        {props.optionFour ? (
          <FormControlLabel
            value={props.optionFour}
            control={<Radio />}
            label={props.optionFour}
            sx={{
              width: '100%', borderRadius: '15px', backgroundColor: active === props?.optionFour ? '#E8F3FE !important' : '', marginBottom: '0.5rem', '&:hover': {
                backgroundColor: '#f5f6f7'
              }
            }}
            disabled={props.disabled}
          />
        ) : null}
      </RadioGroup>
    </FormControl>
  );
}

export function RadioButtonInput(props) {
  const [field, meta] = useField(props.name);

  const [active, setActive] = useState('')

  return (
    <FormControl sx={{ width: '100%', marginBottom: '1rem' }}>
      <FormLabel id={props.RadioButtonGroup} sx={{ marginBottom: '0.5rem' }}>{props.RadioText}</FormLabel>
      {meta && meta.touched && meta.error && <Typography sx={{ color: '#d1060a', fontSize: '0.75rem' }}>{meta.error}</Typography>}

      <RadioGroup
        name="radio-buttons-group"
        value={props.defaultValue}
        onChange={(val) => {
          props.formik.setFieldValue(props.name, val.target.value)
          setActive(val.target.value)
        }}

      >
        <FormControlLabel
          value={props.optionOne}
          control={<Radio />}
          label={props.optionOne}
          sx={{
            width: '100%', borderRadius: '15px', backgroundColor: active === props.optionOne ? '#E8F3FE !important' : '', marginBottom: '0.5rem', '&:hover': {
              backgroundColor: '#f5f6f7'
            }
          }}
          disabled={props.disabled}
        />
        <FormControlLabel
          value={props.optionTwo}
          control={<Radio />}
          label={props.optionTwo}
          sx={{
            width: '100%', borderRadius: '15px', backgroundColor: active === props.optionTwo ? '#E8F3FE !important' : '', '&:hover': {
              backgroundColor: '#f5f6f7'
            }
          }}
          disabled={props.disabled}
        />
        {props.optionThree ?
          <FormControlLabel
            value={props.optionThree}
            control={<Radio />}
            label={props.optionThree}
            sx={{
              width: '100%', borderRadius: '15px', backgroundColor: active === props.optionThree ? '#E8F3FE !important' : '', '&:hover': {
                backgroundColor: '#f5f6f7'
              }
            }}
            disabled={props.disabled}
          />
          : null}
        {props.optionFour ?
          <FormControlLabel
            value={props.optionFour}
            control={<Radio />}
            label={props.optionFour}
            sx={{
              width: '100%', borderRadius: '15px', backgroundColor: active === props.optionFour ? '#E8F3FE !important' : '', '&:hover': {
                backgroundColor: '#f5f6f7'
              }
            }}
            disabled={props.disabled}
          /> : null}
      </RadioGroup>
    </FormControl>
  );
}
