import { InviteApplication, ApplicationStatus, OnboardApplication } from '../pages'



const driver_routes = [
    {
        path: "/application-status",
        component: <ApplicationStatus />,
    },
    {
        path: "/inviteApplication",
        component: <InviteApplication />,
    },

    {
        path: "/onboardApplication",
        component: <OnboardApplication />,
    }


]

const driver_routes_disabled = [
    {
        path: "/application-status",
        component: <ApplicationStatus />,
    }
]

const getDriverRoute = (status) => {
    if (status === 'rejected' || status === 'declined') {
        return driver_routes_disabled
    } else {
        return driver_routes
    }
}

export default getDriverRoute;
