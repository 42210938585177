import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useField } from "formik";
import { Box, Grid, Typography } from "@mui/material";
import { useState } from "react";
import { makeStyles } from '@mui/styles';



const useStyles = makeStyles(theme => ({
    inputRoot: {
        '&$disabled': {
            '-webkit-text-fill-color': 'red !important',
            'color': 'black !important'
        },
    },
    disabled: {
        '&$disabled': {
            '-webkit-text-fill-color': 'black !important',
            'color': 'black !important'
        },
    }
}));


export const SelectSmall = () => {
    const [row, setRow] = useState(10);



    const handleChange = (event) => {
        setRow(event.target.value);
    };



    return (
        <FormControl sx={{ m: 0.5, minWidth: 120 }} size="small">
            <InputLabel id="demo-select-small" sx={{ fontSize: "0.8rem" }}>
                Rows
            </InputLabel>
            <Select
                labelId="demo-select-small"
                id="demo-select-small"
                value={row}
                label="Age"
                onChange={handleChange}
            >
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={20}>20</MenuItem>
                <MenuItem value={30}>30</MenuItem>
            </Select>
        </FormControl>
    );
};



export const SelectField = (props) => {
    const { label, name, options, setCountryDetails, formik, placeholder, disabled, setCountryFieldArray } = props;
    const [field, meta] = useField(name);
    const classes = useStyles();

    const config = {
        ...field,
        classes: {
            root: classes.inputRoot,
            disabled: classes.disabled
        }

    }

    if (setCountryDetails) {

        config.onChange = (e) => {
            formik.setFieldValue(`${name.split('.')[0]}.state`, '')
            formik.setFieldValue(name, e.target.value);
            setCountryDetails(e.target.value)
        }
    }

    if (setCountryFieldArray) {

        config.onChange = (e) => {
            formik.setFieldValue(`${name.split('.')[0]}.${name.split('.')[1]}.state`, '')
            formik.setFieldValue(name, e.target.value);
        }
    }
    return (
        <>
            <Grid item xs={12} md={5} >
                <Grid item sx={{ width: '100%' }} >
                    <Box>
                        <InputLabel sx={{ paddingLeft: "0.5rem", fontWeight: "600" }}>
                            {label}
                        </InputLabel>
                        <Box
                            sx={{
                                border:
                                    meta.touched && meta.error ? "1px solid red" : "none",
                                borderRadius: "20px",
                            }}
                        >
                            <Select
                                labelId="select-normal"
                                id="select"
                                sx={{
                                    borderRadius: "20px",
                                    backgroundColor: "#E8F3FE",
                                    width: "100%",
                                    height: "2.6em",
                                }}
                                placeholder={placeholder}
                                {...config}
                                disabled={disabled}

                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {options.length && options.map((item, index) => (
                                    <MenuItem key={index} value={item.value}>
                                        {item.title}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Box>
                    </Box>
                    {meta.touched && meta.error && (
                        <Box>
                            <Typography
                                sx={{
                                    color: "#d32f2f",
                                    fontSize: "0.75rem",
                                    fontWeight: "400",
                                    marginLeft: "14px",
                                }}
                            >
                                {meta.error}
                            </Typography>
                        </Box>
                    )}
                </Grid>
            </Grid>
        </>
    );
};