import React from "react";
import {
  Typography,
  Box,
  Grid,
  LinearProgress,
  linearProgressClasses,
} from "@mui/material";
import styled from "@emotion/styled";
import CardMedia from "@mui/material/CardMedia";

function Card(props) {
  const CardItem = styled(Box)(({ theme }) => ({
    // boxShadow: "0 8px 32px 0 rgba( 31, 38, 135, 0.37 )",
    background: "rgba( 255, 255, 255, 0.1 )",

    boxShadow: "0 5px 32px 5px #e2e4f8",

    backdropFilter: "blur( 20px )",
    webkitBackdropFilter: "blur( 20px )",
    borderRadius: "10px",
    border: "1px solid rgba( 255, 255, 255, 0.18 )",
    // minWidth: "300px",
    paddingBottom: "20px",
    background: "#fff",
    transition: "all 1000ms",
    // boxShadow: "2px 2px 30px 5px #D9D9D9",
    "&:hover": {
      background: "#3592FB",
      "& .card_title_number": {
        color: "#fff",
      },
      "& .card_title": {
        color: "#fff",
      },
      "& .card_percentage_pending": {
        color: "#fff",
      },
      "& .card_percentage_total": {
        color: "#fff",
      },
      [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        background: theme.palette.mode === "light" ? "#fff" : "#fff",
      },
      "& .MuiLinearProgress-determinate": {
        background: theme.palette.mode === "light" ? "#cfcfcf" : "#e8e8e8",
      },
      "& .card_percentagebar_value": {
        boxShadow: "none",
      },
    },
  }));
  const Item = styled(Box)(({ theme }) => ({
    paddingBottom: "5px",
  }));
  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      background: theme.palette.mode === "light" ? "#fff" : "#fff",
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      background:
        theme.palette.mode === "light"
          ? "linear-gradient(90deg,  rgba(0,41,185,1) 0%, rgba(2,119,250,1) 100%)"
          : "#fff",
    },
    backgroundColor: "#fff",
    boxShadow: "2px 6px 40px 5px #D4E8FE",
  }));
  return (
    <>
      <CardItem>
        <Box sx={{ width: "100%", padding: "25px" }}>
          <Grid container>
            <Grid item xs={12}>
              <Item>
                <CardMedia
                  component="div"
                  sx={{
                    padding: "20px 0",
                  }}
                >
                  <Typography
                    variant="h1"
                    sx={{
                      fontSize: "2em",
                      fontWeight: "500",
                      paddingBottom: "5px",
                    }}
                    className="card_title_number"
                  >
                    {props.titleNumber}
                  </Typography>
                  <Typography
                    variant="p"
                    sx={{ fontSize: "1em", fontWeight: "500" }}
                    className="card_title"
                  >
                    {props.title}
                  </Typography>
                </CardMedia>
              </Item>
            </Grid>

            <Grid item xs={6} md={6}>
              {/* <Item>
                <Typography
                  variant="body2"
                  sx={{ fontSize: "1em", fontWeight: "500" }}
                  className="card_percentage_pending"
                >
                  {props.percentagePending} Pending
                </Typography>
              </Item>
            </Grid> */}
            </Grid>
            <Grid item xs={6}>
              <Item>
                <Typography
                  variant="body2"
                  sx={{
                    textAlign: "right",
                    fontSize: "1em",
                    fontWeight: "500",
                  }}
                  className="card_percentage_total"
                >
                  {props.percentageTotal + "%"}
                </Typography>
              </Item>
            </Grid>
            <Grid item xs={12}>
              <Item>
                <BorderLinearProgress
                  variant="determinate"
                  value={props.percentageBarValue}
                  className="card_percentagebar_value"
                />
              </Item>
            </Grid>
          </Grid>
        </Box>
      </CardItem>
    </>
  );
}

export default Card;
