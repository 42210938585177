import React from "react";
import { Box, Button, Stack, Typography } from "@mui/material";
import { SelectSmall } from "../../components";
import Pagination from "@mui/material/Pagination";
import HeaderTab from "../../components/HeaderSection/Header";
import GridTable from "../../components/Grid_table/GridTable";
import CancelIcon from "@mui/icons-material/Cancel";
import EditIcon from "@mui/icons-material/Edit";
import TabView from "../../components/Tabs/Tabs";
import { Group, PersonAdd } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { getUserTableData } from "../../redux/thunks/ManageUsers-thunk";
import { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import InputField from "../../components/FormikControl/Text_input/TextInput";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import { ButtonInput } from "../../components";
import PhoneNumber from "../../components/FormikControl/PhoneNumber/PhoneNumber";
import useAxios from "../../hooks/useAxios";
import useMediaQuery from "@mui/material/useMediaQuery";
import { ManageUserActions } from "../../redux/reducers/ManageUser-Slice";

const AllUsers = ({ data, setvalue }) => {
  const matches = useMediaQuery("(max-width:400px)");
  const [page, setPage] = useState(1);
  const [row, setRow] = useState(10);
  const [search, setSearch] = useState("");
  const [filterData, setFilterData] = useState(data);

  useEffect(() => {
    setFilterData(
      data.filter((item) =>
        item.name.toLowerCase().includes(search.toLowerCase())
      )
    );
  }, [search]);

  const count = Math.ceil(filterData.length / row);

  function paginator(items, current_page, per_page_items) {
    let page = current_page || 1,
      per_page = per_page_items,
      offset = (page - 1) * per_page,
      paginatedItems = items.slice(offset).slice(0, per_page_items),
      total_pages = Math.ceil(items.length / per_page);

    return {
      page: page,
      per_page: per_page,
      pre_page: page - 1 ? page - 1 : null,
      next_page: total_pages > page ? page + 1 : null,
      total: items.length,
      total_pages: total_pages,
      data: paginatedItems,
    };
  }

  const handlePagination = (e, value) => {
    setPage(paginator(filterData, value, row).page);
  };

  const tableHeader = [
    { title: "S.No", wide: 0.5 },
    { title: "Name", wide: 2 },
    { title: "Email", wide: 4.5 },
    { title: "Phone", wide: 2 },
    { title: "Role", wide: 2 },
    { title: "Actions", wide: 1 },
  ];

  // PopOver Props
  const PopOver = {
    set_1: [{ icon: <CancelIcon sx={{ color: "#d1060a" }} />, text: "Delete" }],
    set_2: [{ icon: <EditIcon />, text: "Edit" }],
  };

  const GridTableProps = {
    tableHead: tableHeader,
    tableBody: paginator(filterData, page, row).data,
    viewApplication: false,
    popOver: PopOver,
    md: [2, 4.5, 2, 2],
  };

  const headerTabProp = {
    startTitle: "User Table",
    endTitle: "Add User",
  };

  return (
    <Box sx={{ width: { xs: "95%", md: "100%" }, margin: "0 auto" }}>
      {/* Header Section ---------------------------------------------------------------------------------------------------------------------------------------*/}
      <HeaderTab
        headerTabProp={headerTabProp}
        setSearch={setSearch}
        setvalue={setvalue}
        setPage={setPage}
        page={page}
      />

      {/* Grid Table ------------------------------------------------------------------------------------------------------------------------------------------- */}
      <GridTable tableProps={GridTableProps} setvalue={setvalue} />

      {/* Pagination ------------------------------------------------------------------------------------------------------------------------------------------- */}
      <Box
        sx={{
          display: "flex",
          alignItems: { xs: "end", md: "center" },
          justifyContent: "right",
          flexDirection: { xs: "column", md: "row" },
        }}
      >
        <SelectSmall setRow={setRow} />
        <Pagination
          count={count}
          page={page}
          onChange={handlePagination}
          color="primary"
          variant="outlined"
          shape="rounded"
          size={matches ? "small" : "medium"}
        />
      </Box>
    </Box>
  );
};

const AddUser = ({ setvalue, initValue, setInitValue }) => {
  const userData = useSelector((state) => state.ManageUsers.userData);
  const axiosApi = useAxios();
  const dispatch = useDispatch();
  const handleSubmit = (value, { resetForm }) => {
    let newObj = {
      middle_name: value.middle_name,
      last_name: value.last_name,
      phone: value.phone ? value.phone : null,
    };
    userData
      ? axiosApi.patch(`/company/hr/${value.id}/`, newObj).then((res) => {
        dispatch(getUserTableData());
        setInitValue({
          first_name: "",
          middle_name: "",
          last_name: "",
          email: "",
          phone: "",
        });
        dispatch(ManageUserActions.userData(""));
        setvalue(0);
      })
      : axiosApi.post("/company/hr/", value).then((res) => {
        dispatch(getUserTableData());
        resetForm();
        setvalue(0);
      });
  };

  const validationSchema = Yup.object({
    first_name: Yup.string().required("Required"),
    email: Yup.string().email("Enter Valid Email").required("Required").trim(),
    phone: Yup.string().required("Required"),
  });

  const handleCancelEdit = () => {
    setInitValue({
      first_name: "",
      middle_name: "",
      last_name: "",
      email: "",
      phone: "",
    });
    dispatch(ManageUserActions.userData(""));
    setvalue(0);
  };

  return (
    <Grid container>
      <Formik
        initialValues={initValue}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {(formik) => (
          <Form>
            <Box
              sx={{
                padding: "0.4rem",
                borderRadius: "10px",
                marginBottom: "2rem",
              }}
            >
              <Typography
                sx={{
                  fontSize: "1.4rem",
                  marginLeft: "0.5rem",
                  marginBottom: "1rem",
                  fontWeight: "500",
                }}
              >
                {userData ? "Edit User" : "Add User"}
              </Typography>
              <Grid
                container
                sx={{ justifyContent: "space-between" }}
                rowGap={1.5}
              >
                <InputField
                  name="first_name"
                  label=" First Name"
                  placeholder="First Name"
                  disabled={userData ? true : false}
                />
                <InputField
                  name="middle_name"
                  label="Middle Name"
                  placeholder="Enter Middle Name"
                />
                <InputField
                  name="last_name"
                  label="Last Name"
                  placeholder="Enter Last Name"
                />
                <InputField
                  name="email"
                  label="Email"
                  placeholder="Email"
                  disabled={userData ? true : false}
                />
                <PhoneNumber
                  name="phone"
                  label="Phone Number"
                  handleChange={(e) => formik.setFieldValue("phone", "+" + e)}
                />
              </Grid>
              <Stack
                sx={{ margin: "30px 20px 0 5px" }}
                direction={{ xs: "column", md: "row" }}
                spacing={{ xs: 2, md: 3 }}
              >
                {userData ? (
                  <Button
                    sx={{
                      borderRadius: "20px",
                      width: { xs: "100%", md: "12rem" },
                      height: "3em",
                      padding: "20px",
                    }}
                    variant="outlined"
                    type="button"
                    onClick={handleCancelEdit}
                  >
                    Cancel Edit
                  </Button>
                ) : null}

                <ButtonInput
                  type="submit"
                  label={userData ? "Save User" : "Add User"}
                  padding="20px"
                  md={userData ? 2 : 5}
                />
              </Stack>
            </Box>
          </Form>
        )}
      </Formik>
    </Grid>
  );
};

export default function ManageUsers() {
  const [data, setData] = useState([]);
  const [initValue, setInitValue] = useState({
    first_name: "",
    middle_name: "",
    last_name: "",
    email: "",
    phone: "",
  });

  const dispatch = useDispatch();

  const tableData = useSelector((state) => state.ManageUsers.tableData);
  const userData = useSelector((state) => state.ManageUsers.userData);
  useEffect(() => {
    async function getApplicants() {
      dispatch(getUserTableData());
    }

    getApplicants();
  }, []);

  useEffect(() => {
    setData(tableData);
  }, [tableData]);
  useEffect(() => {
    if (userData) setInitValue(userData);
  }, [userData]);

  const TabProps = {
    tab_details: [
      {
        icon: (
          <Group
            sx={{
              marginRight: "0.5rem",
              fontSize: { xs: "1.2rem", sm: "1.5rem", md: "1.7rem" },
            }}
          />
        ),
        text: "All Users",
      },
      {
        icon: (
          <PersonAdd
            sx={{
              marginRight: "0.5rem",
              fontSize: { xs: "1.2rem", sm: "1.5rem", md: "1.7rem" },
            }}
          />
        ),
        text: userData ? "Edit User" : "Add User",
      },
    ],
    children: [
      <AllUsers data={data} />,
      <AddUser initValue={initValue} setInitValue={setInitValue} />,
    ],
    sm: '0.96rem',
    md: '1.2rem'
  };

  return (
    <Box
      sx={{
        background: "rgba( 255, 255, 255, 0.1 )",

        boxShadow: "0 5px 32px 5px #e2e4f8",

        backdropFilter: "blur( 20px )",
        webkitBackdropFilter: "blur( 20px )",
        borderRadius: "10px",
        border: "1px solid rgba( 255, 255, 255, 0.18 )",
        margin: { xs: "0 10px 20px 10px", sm: "0 30px 20px 30px" },
        padding: "20px 0",
        borderRadius: "10px",
      }}
    >
      <TabView tabProps={TabProps} />
    </Box>
  );
}
