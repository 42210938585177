import { createSlice } from "@reduxjs/toolkit";


const initialState = {
    applicationData: '',
    employementHistory: '',
    experience: '',
    accidentHistory: '',
    trafficViolation: '',
    adverseLicensing: '',
    applicant_status: '',
    readAndSign: '',
    documentData: {
        medical_certificate: {
            id: '',
            document_filename: '',
        },
        birth_certificate: {
            id: '',
            document_filename: '',
        },
        license: {
            id: '',
            document_filename: '',
            expiration_date: ''
        },
        passport: {
            id: '',
            document_filename: '',
            expiration_date: ''
        },
        ssn: {
            id: '',
            document_filename: '',
        },
    },
}

const ApplicantLoginSlice = createSlice({

    name: "ApplicantLogin",
    initialState,
    reducers: {
        applicationData(state, action) {
            state.applicationData = action.payload
        },
        documentData(state, action) {
            state.documentData = action.payload
        },
        employementHistory(state, action) {
            state.employementHistory = action.payload
        },
        experience(state, action) {
            state.experience = action.payload
        },
        accidentHistory(state, action) {
            state.accidentHistory = action.payload
        },
        trafficViolation(state, action) {
            state.trafficViolation = action.payload
        },
        adverseLicensing(state, action) {
            state.adverseLicensing = action.payload
        },
        readAndSign(state, action) {
            state.readAndSign = action.payload
        },
        applicant_status(state, action) {
            state.applicant_status = action.payload
        }

    }

})


export const ApplicantLoginActions = ApplicantLoginSlice.actions

export default ApplicantLoginSlice