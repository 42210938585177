const Total_Route_List = [
    '/manageapplicants',
    '/dashboard',
    '/inviteApplication',
    '/onboardApplication',
    '/companyprofile',
    '/manageuser',
    '/currentemployees'

]


export default Total_Route_List