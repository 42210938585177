import React, { useState, useEffect } from 'react';
import Popover from '@mui/material/Popover';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import { changeStatus } from '../../redux/thunks/ManageApplicants-thunk'
import { useDispatch, useSelector } from "react-redux";
import { createAction } from '../../redux/thunks/ManageApplicants-thunk';
import useAxios from '../../hooks/useAxios';
import { ManageUserActions } from '../../redux/reducers/ManageUser-Slice';
import { Avatar, Badge, Typography } from '@mui/material';
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import { getNotification } from "../../redux/thunks/Theme-thunk";
import useAuth from '../../hooks/useAuth';
import styled from '@emotion/styled';



export default function BellNotification({ popOverProps, id, setvalue }) {

    const [anchorEl, setAnchorEl] = useState(null);
    const [readID, setreadID] = useState([])
    const AxiosApi = useAxios()
    const { auth } = useAuth()


    const notification = useSelector((state) => state.ThemeSlice.notification);

    useEffect(() => {
        if (auth.role[0] !== "A" && auth.accessToken && !notification.length) {
            dispatch(getNotification());
        }
    }, []);


    const handleClick = (event) => {
        dispatch(getNotification())
        setAnchorEl(event.currentTarget);
    };


    const handleClose = () => {
        setAnchorEl(null);
        if (readID.length) {
            AxiosApi.patch('/notifications/mark-as-read/', { id: readID }).then(() => {
                dispatch(getNotification())
                setreadID([])
            })
        }

    };

    const open = Boolean(anchorEl);
    const _popid = open ? 'simple-popover' : undefined;

    const dispatch = useDispatch()

    const StyledPopover = styled(Popover)(({ theme }) => ({
        '& .MuiPopover-paper': {
            borderRadius: '10px',
            boxShadow:
                "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
            marginTop: '7px'
        }
    }))

    return (
        <>
            <Badge badgeContent={notification.length} color="error" >
                <Avatar
                    sx={{
                        width: 35,
                        height: 35,
                        background: "#fff",
                        boxShadow: "2px 6px 30px 5px #ebebeb",
                    }}
                    onClick={handleClick}
                >
                    <NotificationsOutlinedIcon sx={{ fill: "#00100B" }} />
                </Avatar>
            </Badge>



            <StyledPopover
                id={_popid}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <Box sx={{
                    height: "60vh",
                    width: { xs: '90vw', sm: '25vw' },
                    overflowY: "scroll",
                    scrollBehavior: "smooth",
                    overflowX: "hidden",
                    borderRadius: "10px",
                    boxShadow: "none",
                }}>
                    <Box
                        sx={{
                            width: "100%",

                            height: "3em",
                            display: "flex",
                            alignItems: "center",
                            paddingLeft: "1rem",
                        }}
                    >
                        Notifications
                    </Box>
                    <Divider />

                    <List sx={{ padding: '0' }}>
                        {notification.map((item, index) => (
                            <React.Fragment key={index}>
                                <ListItem alignItems="flex-start" sx={{ padding: '1rem', cursor: 'pointer', backgroundColor: readID.includes(item.id) ? '#fff' : '#e8f3fe' }} onClick={() => setreadID((prev) => [...prev, item.id])} key={index}>
                                    <ListItemText
                                        primary={
                                            <Typography
                                                component="span"
                                                sx={{
                                                    fontSize: { xs: "0.8rem", sm: "0.9rem" },
                                                    marginBottom: "0.6rem",
                                                    fontWeight: "500",
                                                }}
                                            >
                                                {item.subject}
                                            </Typography>
                                        }
                                        secondary={
                                            <>
                                                <Box
                                                    sx={{ display: "flex", position: "relative" }}
                                                    component="span"
                                                >
                                                    <Typography
                                                        sx={{
                                                            display: "inline",
                                                            fontSize: { xs: "0.7rem", sm: "0.8rem" },
                                                        }}
                                                        component="span"
                                                        variant="body2"
                                                        color="text.primary"
                                                        marginBottom="0.5rem"
                                                    >
                                                        {item.body}
                                                    </Typography>
                                                    <Typography
                                                        component="span"
                                                        sx={{
                                                            fontSize: "0.7rem",
                                                            position: "absolute",
                                                            bottom: "-50%",
                                                        }}
                                                    >
                                                        {item.time}
                                                    </Typography>
                                                </Box>
                                            </>
                                        }
                                    />
                                </ListItem>
                                <Divider />
                            </React.Fragment>))
                        }

                    </List>
                </Box>
            </StyledPopover>
        </ >
    );
}
