import styled from "@emotion/styled";
import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { TextInput, PasswordInput, ButtonInput } from "../../../components";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import { Link, useNavigate } from "react-router-dom";
import useAxios from "../../../hooks/useAxios";
import useAuth from "../../../hooks/useAuth";
import { encrypt } from "../../../utils/encrypt_decrypt";
import CoverImage from "../../../assets/images/Cover.jpg";
import { useSelector } from "react-redux";

const LOGIN_URL = "/accounts/token/";

function Login() {
  const { setAuth } = useAuth();
  let accessToken = localStorage.getItem("accessToken");
  let userDetail = localStorage.getItem("user");
  let refreshToken = null;
  let role = null;

  const [imgLoaded, setImgLoaded] = useState(false)

  const companyLogo = useSelector((state) => state.ThemeSlice?.companyLogo);

  const axiosapi = useAxios();
  const navigate = useNavigate();


  useEffect(() => {
    if (companyLogo) setImgLoaded(true)
  }, [companyLogo])

  const Handle_LoginType = (data) => {
    accessToken = data.access;
    userDetail = data.user;
    refreshToken = data.refresh;

    role = data.user.roles ? encrypt(data.user.roles) : [];

    localStorage.setItem("accessToken", accessToken);
    localStorage.setItem("refreshToken", refreshToken);
    localStorage.setItem("user", JSON.stringify(userDetail));
    localStorage.setItem("role", role);
    localStorage.setItem("first_name", data?.user?.first_name);

    setAuth({
      accessToken,
      refreshToken,
      user: userDetail,
      role: data.user.roles,
      first_name: data?.user?.first_name,
    });

    localStorage.removeItem("startTime");
    navigate("/dashboard", { replace: true });
  };

  const Item = styled(Box)({});
  const validationSchema = Yup.object({
    email: Yup.string().email("Enter Valid Email").required("Required").trim(),
    password: Yup.string()
      .matches(
        /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#!$%^&-+=()_]).{8,}$/,
        "Enter atleast 1 capital, 1 numeric, 1 special character"
      )
      .required("Required")
      .trim(),
  });
  const initialValues = {
    email: "",
    password: "",
  };
  return (
    <>
      <Box>
        <Grid container>
          <Grid item md={6}>
            <Item
              sx={{
                // justifyContent: "center",
                display: "flex",
                height: "100vh",
                flexDirection: "column",
              }}
            >
              <Box sx={{
                width: "200px",
                height: "200px",
                margin: '0 auto 0 30px',
                display: 'flex',
                justifyContent: "center",
                alignItems: 'center'
              }}>
                {
                  !imgLoaded ?
                    <Typography>Loading...</Typography>
                    :
                    <Box
                      component="img"
                      src={companyLogo}
                      sx={{
                        padding: "20px",
                        width: "200px",
                        maxHeight: "200px",
                      }}
                    />
                }
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  height: "100%",
                  flexDirection: "column",
                  width: "100%",
                  padding: "0 50px",
                  marginTop: "-70px",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: "600",
                    fontSize: "2.2em",
                    marginBottom: "10px",
                    color: "#3E3E3E",
                  }}
                >
                  Login
                </Typography>
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={(values) => {
                    axiosapi
                      .post(LOGIN_URL, {
                        email: values.email.trim().toLowerCase(),
                        password: values.password,
                      })
                      .then((data) => Handle_LoginType(data));
                  }}
                >
                  {(formik) => (
                    <Form>
                      <Box sx={{ marginBottom: "15px" }}>
                        <TextInput
                          name="email"
                          type="email"
                          label="Email"
                          md={8}
                        />
                      </Box>
                      <Box sx={{ marginBottom: "20px" }}>
                        <PasswordInput
                          name="password"
                          label="Password"
                          md={8}
                        />
                      </Box>
                      <ButtonInput label="Login" md={8} type={"submit"} />
                    </Form>
                  )}
                </Formik>
                <Link to="/forgotpassword" style={{ textDecoration: "none" }}>
                  <Typography
                    sx={{
                      marginTop: "30px",
                      color: "#000",
                      marginLeft: "20px",
                    }}
                  >
                    Forgot Password
                  </Typography>
                </Link>
              </Box>
            </Item>
          </Grid>
          <Grid item md={6}>
            <Item>
              <Box
                component="img"
                src={CoverImage}
                sx={{
                  width: "100%",
                  height: "100vh",
                  background: "rgba( 255, 255, 255, 0.1 )",
                  boxShadow: "0 8px 32px 0 rgba( 31, 38, 135, 0.37 )",

                  objectFit: "cover",
                  objectPosition: "60% 0%",
                }}
              />
            </Item>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default Login;
