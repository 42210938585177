import React from 'react'
import { Box, Typography } from "@mui/material";
function Loading() {

    return (


        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '90vh', width: '100%' }}>
            <Typography variant='h1' sx={{
                color: '#0277fa', fontSize: { xs: '2rem', sm: '4rem' }, fontWeight: '400', letterSpacing: '1rem'
            }}>
                Loading...
            </Typography>
        </Box>
    )

}

export default Loading