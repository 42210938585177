import { Box, Checkbox, Typography, Button, Grid } from '@mui/material'
import { Form, Formik } from 'formik'
import React, { useState } from 'react'
import InputField from "../../components/FormikControl/Text_input/TextInput";
import FormControlLabel from "@mui/material/FormControlLabel";
import SignatureCanvas from 'react-signature-canvas'
import { ModalButton } from '../../components'
import { useRef } from 'react';
import { KeyboardDoubleArrowRight, KeyboardDoubleArrowLeft } from '@mui/icons-material';
import dataURLtoBlob from 'blueimp-canvas-to-blob'
import { useNavigate } from 'react-router-dom';
import styled from "@emotion/styled";
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from "react-redux";
import useAxios from "../../hooks/useAxios";
import SaveIcon from '@mui/icons-material/Save';
import { ApplicantLoginActions } from '../../redux/reducers/ApplicantLogin-Slice'
import dayjs from 'dayjs';
import { useEffect } from 'react';
import { getCompanyInfo } from "../../redux/thunks/Theme-thunk";

const Signed = ({
  auth,
  initValue,
  disabled,
  setvalue
}) => {
  const sigPad = useRef()

  const Axiosapi = useAxios()

  const dispatch = useDispatch()

  const navigate = useNavigate()
  const todayDate = new Date().toLocaleDateString()


  console.log(initValue)


  const applicationData = useSelector((state) => state.ApplicantLogin.applicationData);
  const readAndSignData = useSelector((state) => state.ApplicantLogin.readAndSign);
  const companyData = useSelector((state) => state.ThemeSlice.companyInfo);

  const [showSignaturePad, setShowSignaturePad] = useState(readAndSignData.id ? false : true)

  const clearSign = () => {
    if (readAndSignData.id) setShowSignaturePad(true)
    sigPad.current.clear()
  }

  useEffect(() => {
    if (auth.role[0] !== "A") {
      dispatch(getCompanyInfo())
    }
  }, [])

  const handleSubmit = (values) => {
    if (!values.ssn) toast.error('Please Enter SSN Number')
    if (!values.accept) toast.error('Please Accept the Acknowledgement')
    if (sigPad?.current?.isEmpty()) toast.error('Please Sign inside the Box to Continue')


    if (values.accept && !sigPad?.current?.isEmpty() && values.ssn) {
      const data = new FormData()

      if (!values.signature) {
        const blob = dataURLtoBlob(sigPad.current.getTrimmedCanvas().toDataURL('image/png'))
        data.append('signature', blob, 'signature.png')
      }


      data.append('accept', values.accept)
      data.append('ssn', values.ssn)
      data.append('date', dayjs(todayDate).format('YYYY-MM-DD'))
      data.append('applicant', auth.role[0] === "A" ? applicationData?.id : values.applicant)
      data.append('full_name', values.full_name ? values.full_name : applicationData?.user?.first_name + ' ' + applicationData?.user?.middle_name + ' ' + applicationData?.user?.last_name)
      data.append('hiring_location', auth.role[0] === "A" ? applicationData?.company?.name : companyData?.name)

      if (readAndSignData?.full_name) {
        Axiosapi.patch(`/company/current_employee/`, {
          application: auth.role[0] === "A" ? applicationData?.id : values.applicant,
          status: 'on_boarded'
        }).then(() => {
          if (auth.role[0] === "A") {
            navigate('/application-status')
          } else {
            navigate('/manageapplicants')
          }
          toast.success('Success!!')
        })
      } else {
        Axiosapi.post('/application/esignature', data).then((res) => {
          dispatch(ApplicantLoginActions.readAndSign(res))
          Axiosapi.patch(`/company/current_employee/`, {
            application: auth.role[0] === "A" ? applicationData?.id : values.applicant,
            status: 'on_boarded'
          }).then(() => {
            if (auth.role[0] === "A") {
              navigate('/application-status')
            } else {
              navigate('/manageapplicants')
            }
            toast.success('Success!!')
          })
        }
        )
      }


    }


  }




  const Item = styled(Box)({});

  // console.log(sigPad?.current?.isEmpty())

  return (
    <Box sx={{
      padding: "0.9rem",
      borderRadius: "10px",
      marginBottom: "2rem",
      backgroundColor: "#fff"
    }}>
      <Box >
        <Typography
          sx={{
            fontSize: "1.2rem",
            marginBottom: "1rem",
            fontWeight: '600'
          }}
        >
          To Be Read and Signed By Applicant
        </Typography>

      </Box>
      <Formik initialValues={initValue} onSubmit={handleSubmit}>
        {({ setFieldValue, ...formik }) => (
          <Form>

            <Grid container
              sx={{ justifyContent: "space-between" }}
              rowGap={1.5}>

              <InputField
                name={`full_name`}
                label='Your Name*'
                type='string'
                disabled={true}
                placeholder={applicationData ? applicationData?.user?.first_name + ' ' + applicationData?.user?.middle_name + ' ' + applicationData?.user?.last_name : ''}
              // style={{ marginBottom: '15px' }}
              />

              <InputField
                name={`ssn`}
                label='Your SSN Number*'
                type='number'
                disabled={disabled}
                style={{ marginBottom: '15px' }}
              />
            </Grid>

            <FormControlLabel
              sx={{ marginTop: '4rem' }}
              control={
                <Checkbox
                  name={`accept`}
                  checked={formik.values.accept}
                  onChange={(e) => setFieldValue('accept', !formik.values.accept)}
                />
              }
              label="I acknowledge that my name and my SSN which is displayed above, is accurate, and by checking the Acceptance Box, and by clicking on the “Finish” Button below, I am electronically signing the above document.
            I understand that my electronic signature wil be binding as though I had physically signed this document by hand. I agree that a printout of this authorization may be accepted with the same authority as the original."
            />
            <Typography
              sx={{
                fontSize: "1rem",
                marginBottom: "1rem",
                fontWeight: '600',
                marginTop: '3rem'
              }}
            >
              Date:-  {initValue?.date ? initValue?.date : todayDate}
            </Typography>
            <Typography
              sx={{
                fontSize: "1rem",
                marginBottom: "1rem",
                fontWeight: '600',
              }}
            >
              Hiring Location:- {auth.role[0] === "A" ? applicationData?.company?.name : companyData?.name}
            </Typography>


            <div id="consentSignatureCanvas" >
              <div
                style={{
                  marginTop: '50px',
                  display: 'flex',
                  flexFlow: 'column',
                  justifyContent: 'center',
                  width: "100%"
                }}
              > <Typography
                sx={{
                  fontSize: "1rem",
                  marginBottom: "1rem",
                  fontWeight: '600',
                }}
              >
                  Signature:-
                </Typography>
                <Box sx={{ border: '1px solid black', borderRadius: '10px', padding: '0.2rem' }}>
                  {
                    showSignaturePad ?
                      <SignatureCanvas
                        onEnd={(e) => {
                          //   this.props.handleSign(e)
                          console.log(e)
                        }}
                        backgroundColor={'rgb(255,255,255)'}
                        ref={sigPad}
                        canvasProps={{
                          width: (window.screen.width <= 767) ? 300 : 1100,
                          height: (window.screen.width <= 767) ? 200 : 300,
                          className: 'consentSignatureCanvasStyle'
                        }}
                      /> : <Box
                        component="img"
                        src={readAndSignData.signature}
                        sx={{
                          padding: "20px",
                          width: '80%',
                          overflow: 'hidden'
                        }}
                      />
                  }

                </Box>
                {<Button
                  variant="contained"
                  sx={{
                    fontSize: { xs: "0.9rem", md: "1rem" },
                    margin: "0.5rem 0 0 auto",
                    background:
                      "linear-gradient(90deg,  rgba(0,41,185,1) 0%, rgba(2,119,250,1) 100%)",
                    borderRadius: "5px",
                    color: "#fff",
                    height: "1.5rem",
                    width: '100%',
                    textTransform: 'none'
                  }}
                  onClick={clearSign}
                >
                  Clear Signature
                </Button>
                }
              </div>
            </div>
            <Box
              sx={{
                display: "flex",
                width: { xs: "100%", md: "100%" },
                justifyContent: "space-between",
                margin: "2rem 0 0 auto",
                padding: "0 10px 20px 10px",
                flexDirection: { xs: "column", md: "row" },
              }}
            >



              {disabled ? (
                <>
                  <Item
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                    }}
                  >
                    <Button
                      variant="contained"
                      startIcon={<KeyboardDoubleArrowLeft />}
                      sx={{
                        fontSize: { xs: "0.9rem", md: "1rem" },
                        margin: "0 0 0 auto",
                        background:
                          "linear-gradient(90deg,  rgba(0,41,185,1) 0%, rgba(2,119,250,1) 100%)",
                        borderRadius: "20px",
                        width: { xs: "100%", md: "fit-content" },
                        color: "#fff",
                        height: "2.6em",
                      }}
                      onClick={() => setvalue(4)}
                    >
                      Previous
                    </Button>
                  </Item>
                  <Item
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    <Button
                      variant="contained"
                      endIcon={<KeyboardDoubleArrowRight />}
                      sx={{
                        fontSize: { xs: "0.9rem", md: "1rem" },
                        margin: { xs: "1rem 0 0 auto", md: "0 0 0 auto" },
                        background:
                          "linear-gradient(90deg,  rgba(0,41,185,1) 0%, rgba(2,119,250,1) 100%)",
                        borderRadius: "20px",
                        width: { xs: "100%", md: "fit-content" },
                        color: "#fff",
                        height: "2.6em",
                      }}
                      onClick={() => navigate('/manageapplicants')}
                    >
                      Close
                    </Button>
                  </Item>
                </>
              ) : (
                <>
                  <Item
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                    }}
                  >
                    <Button
                      variant="contained"
                      startIcon={<KeyboardDoubleArrowLeft />}
                      sx={{
                        fontSize: { xs: "0.9rem", md: "1rem" },
                        margin: "0 0 0 auto",
                        background:
                          "linear-gradient(90deg,  rgba(0,41,185,1) 0%, rgba(2,119,250,1) 100%)",
                        width: { xs: "100%", md: "fit-content" },
                        borderRadius: "20px",
                        color: "#fff",
                        height: "2.6em",
                      }}
                      onClick={() => setvalue(4)}
                    >
                      Previous
                    </Button>
                  </Item>
                  <Item
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    <Button
                      variant="contained"
                      endIcon={<SaveIcon />}
                      sx={{
                        fontSize: { xs: "0.9rem", md: "1rem" },
                        margin: { xs: "1rem 0 0 auto", md: "0 0 0 auto" },
                        background:
                          "linear-gradient(90deg,  rgba(0,41,185,1) 0%, rgba(2,119,250,1) 100%)",
                        borderRadius: "20px",
                        width: { xs: "100%", md: "fit-content" },
                        color: "#fff",
                        height: "2.6em",
                      }}
                      type="submit"
                    >
                      Save & Next
                    </Button>
                  </Item>
                </>
              )}
            </Box>
            {auth.role[0] === "A" ?
              <Box sx={{ width: { xs: "100%", md: "fit-content" }, padding: "0 10px 20px 10px", }}>
                <ModalButton />
              </Box>

              : null}

          </Form>
        )}

      </Formik>


    </Box>
  )
}

export default Signed