import { ApplicantLoginActions } from '../reducers/ApplicantLogin-Slice'
import { actionAxios } from "../../hooks/useAxios"
// import { encrypt } from '../../utils/encrypt_decrypt'




const getApplicationData = (req_type) => {

    console.log(req_type)

    return async (dispatch) => {
        const modifyResponse = (res) => {
            return JSON.parse(JSON.stringify(res).replace(/\:null/gi, "\:\"\"")) /* eslint-disable-line */
        }


        const requests = async () => {
            let temData = {}
            const endPoint = '/application/'
            const endPoint_id = `/application/${req_type}/`
            temData = await actionAxios.get(req_type === 'Applicant' ? endPoint : endPoint_id)
                .then((res) => {
                    if (req_type === 'Applicant') dispatch(ApplicantLoginActions.applicant_status(res[0]?.status))
                    return modifyResponse(res)
                })
                .catch((err) => console.log(err))


            return req_type === 'Applicant' ? { ...temData[0] } : temData

        }



        await dispatch(ApplicantLoginActions.applicationData(await requests()))

    }
}


const getEmploymentHistory = (id) => {

    return async (dispatch) => {
        const modifyResponse = (res) => {
            return JSON.parse(JSON.stringify(res).replace(/\:null/gi, "\:\"\"")) /* eslint-disable-line */
        }

        const requests = async () => {
            let temData = {}
            const endPoint_id = `/application/employment_history?applicant_id=${id}`
            temData = await actionAxios.get(endPoint_id)
                .then((res) => modifyResponse(res))
                .catch((err) => console.log(err))
            return temData ? temData : []

        }

        await dispatch(ApplicantLoginActions.employementHistory(await requests()))

    }
}


const getExperience = (id) => {


    return async (dispatch) => {
        const modifyResponse = (res) => {
            return JSON.parse(JSON.stringify(res).replace(/\:null/gi, "\:\"\"")) /* eslint-disable-line */
        }


        const requests = async () => {
            let temData = {}
            const endPoint_id = `/application/experience?applicant_id=${id}`
            temData = await actionAxios.get(endPoint_id)
                .then((res) => modifyResponse(res))
                .catch((err) => console.log(err))


            return temData ? temData : []

        }

        await dispatch(ApplicantLoginActions.experience(await requests()))

    }
}

const getAccident = (id) => {


    return async (dispatch) => {
        const modifyResponse = (res) => {
            return JSON.parse(JSON.stringify(res).replace(/\:null/gi, "\:\"\"")) /* eslint-disable-line */
        }


        const requests = async () => {
            let temData = {}
            const endPoint_id = `/application/accident?applicant_id=${id}`
            temData = await actionAxios.get(endPoint_id)
                .then((res) => modifyResponse(res))
                .catch((err) => console.log(err))


            return temData ? temData : []

        }

        await dispatch(ApplicantLoginActions.accidentHistory(await requests()))

    }
}



const getTrafficViolation = (id) => {


    return async (dispatch) => {
        const modifyResponse = (res) => {
            return JSON.parse(JSON.stringify(res).replace(/\:null/gi, "\:\"\"")) /* eslint-disable-line */
        }


        const requests = async () => {
            let temData = {}
            const endPoint_id = `/application/traffic_violation?applicant_id=${id}`
            temData = await actionAxios.get(endPoint_id)
                .then((res) => modifyResponse(res))
                .catch((err) => console.log(err))


            return temData ? temData : []

        }

        await dispatch(ApplicantLoginActions.trafficViolation(await requests()))

    }
}

const getAdverseLicensing = (id) => {


    return async (dispatch) => {
        const modifyResponse = (res) => {
            return JSON.parse(JSON.stringify(res).replace(/\:null/gi, "\:\"\"")) /* eslint-disable-line */
        }


        const requests = async () => {
            let temData = {}
            const endPoint_id = `/application/adverse_licensing_action?applicant_id=${id}`
            temData = await actionAxios.get(endPoint_id)
                .then((res) => modifyResponse(res))
                .catch((err) => console.log(err))


            return temData ? temData : []

        }

        await dispatch(ApplicantLoginActions.adverseLicensing(await requests()))

    }
}


const getSign = (id) => {


    return async (dispatch) => {
        const modifyResponse = (res) => {
            return JSON.parse(JSON.stringify(res).replace(/\:null/gi, "\:\"\""))  /* eslint-disable-line */
        }


        const requests = async () => {
            let temData = {}
            const endPoint_id = `/application/esignature?applicant_id=${id}`
            temData = await actionAxios.get(endPoint_id)
                .then((res) => modifyResponse(res))
                .catch((err) => console.log(err))


            return temData ? temData : []

        }

        await dispatch(ApplicantLoginActions.readAndSign(await requests()))

    }
}

const getStatusAndNavigate = (navigate) => {

    const endPoint = '/application/'
    actionAxios.get(endPoint)
        .then((res) => {
            if (res[0].status === 'rejected' || res[0].status === 'completed') {
                navigate('/application-status')
            } else {
                navigate('/application', { state: { user: false, applicant: true } })
            }


        })
        .catch((err) => console.log(err))

}



const getDocumentData = (req_type) => {


    return async (dispatch) => {
        const modified_object = {
            medical_certificate: {
                id: '',
                document_filename: '',
                expiration_date: ''
            },
            birth_certificate: {
                id: '',
                document_filename: '',
            },
            license_front: {
                id: '',
                document_filename: '',
                expiration_date: ''
            },
            license_back: {
                id: '',
                document_filename: '',
                expiration_date: ''
            },
            passport: {
                id: '',
                document_filename: '',
                expiration_date: ''
            },
            ssn: {
                id: '',
                document_filename: '',
            },

        }
        const modifyResponse = (res) => {

            res?.documents.forEach((item) => {
                if (item.type === 'medical_certificate') {
                    modified_object.medical_certificate.document_filename = item.document_filename;
                    modified_object.medical_certificate.id = item.id;
                    modified_object.medical_certificate.expiration_date = item.expiration_date;
                }
                if (item.type === 'birth_certificate') {
                    modified_object.birth_certificate.document_filename = item.document_filename;
                    modified_object.birth_certificate.id = item.id;
                }
                if (item.type === 'license_front') {
                    modified_object.license_front.document_filename = item.document_filename;
                    modified_object.license_front.id = item.id;
                    modified_object.license_front.expiration_date = item.expiration_date;

                }
                if (item.type === 'license_back') {
                    modified_object.license_back.document_filename = item.document_filename;
                    modified_object.license_back.id = item.id;
                    modified_object.license_back.expiration_date = item.expiration_date;

                }
                if (item.type === 'passport') {
                    modified_object.passport.document_filename = item.document_filename;
                    modified_object.passport.id = item.id;
                    modified_object.passport.expiration_date = item.expiration_date;
                }
                if (item.type === 'ssn') {
                    modified_object.ssn.document_filename = item.document_filename;
                    modified_object.ssn.id = item.id;
                }
            })
            return modified_object;

        }


        const requests = async () => {
            let temData = {};

            const endPoint_id = `/application/all_documents?applicant_id=${req_type}`

            temData = await actionAxios.get(endPoint_id)
                .then((res) => modifyResponse(res))
                .catch((err) => modified_object)


            return temData;

        }

        await dispatch(ApplicantLoginActions.documentData(await requests()))

    }
}




export {
    getApplicationData,
    getDocumentData,
    getStatusAndNavigate,
    getEmploymentHistory,
    getExperience,
    getAccident,
    getTrafficViolation,
    getAdverseLicensing,
    getSign
}