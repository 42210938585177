import * as React from "react";
import { Edit } from "@mui/icons-material";
import * as yup from "yup";
import { Grid, Box, Typography, Button } from "@mui/material";
import InputField from "../../components/FormikControl/Text_input/TextInput";
import PhoneNumber from "../../components/FormikControl/PhoneNumber/PhoneNumber";
import { Formik, Form } from "formik";
import ApartmentIcon from "@mui/icons-material/Apartment";
import TabView from "../../components/Tabs/Tabs";
import { PasswordInput, ButtonInput } from "../../components";
import { useState } from "react";
import useAxios from "../../hooks/useAxios";
import { useEffect } from "react";
import * as Yup from "yup";

const CHANGE_PASSWORD_URL = "/accounts/change-password/";

const UserProfileForm = ({
  initValue,
  initPasswordValue,
  disableForm,
  setDisableForm,
}) => {
  const [message, setMessage] = useState("");

  const SUCCESS_MESSAGE = "Password changed successfully";

  const AxiosApi = useAxios();

  const passwordValidationSchema = yup.object({
    current_password: yup.string().required("Required"),

    new_password: Yup.string()
      .matches(
        /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#!$%^&-+=()_]).{8,}$/,
        "Enter atleast 1 capital, 1 numeric, 1 special character"
      )
      .required("Required")
      .trim(),
    confirm_new_password: Yup.string()
      .required("Required")
      .oneOf([Yup.ref("new_password"), null], "Passwords must match"),
  });

  return (
    <Box>
      <Formik
        initialValues={initValue}
        // validationSchema={validationSchema}
        onSubmit={(value) => {
          let newObj = {
            logo: value.logo ? value.logo : null,
            last_name: value.last_name,
            middle_name: value.middle_name,
            phone: value.phone,
          };
          AxiosApi.patch(`/accounts/user/`, newObj).then(() =>
            setDisableForm(true)
          );
        }}
      >
        {(formik) => (
          <Form>
            <Box
              sx={{
                padding: "0.4rem",
                borderRadius: "10px",
                marginBottom: "2rem",
              }}
            >
              <Grid
                container
                sx={{ justifyContent: "space-between" }}
                rowGap={1.5}
              >
                <InputField
                  name="first_name"
                  label="First Name"
                  placeholder="First Name"
                  disabled
                />

                <InputField
                  name="middle_name"
                  label="Middle Name"
                  placeholder="Middle Name"
                  disabled={disableForm}
                />
                <InputField
                  name="last_name"
                  label="Last Name"
                  placeholder="Last Name"
                  disabled={disableForm}
                />
                <InputField
                  name="email"
                  label=" Email"
                  placeholder=" Email"
                  disabled
                />
                <PhoneNumber
                  name="phone"
                  label="Phone Number"
                  disabled={disableForm}
                  handleChange={(e) => formik.setFieldValue("phone", e)}
                />
              </Grid>
              {!disableForm ? (
                <Box sx={{ display: "flex", marginTop: "15px" }}>
                  <Box sx={{ margin: "10px 20px 0 5px" }}>
                    <Button
                      variant="outlined"
                      sx={{
                        height: "2.6em",
                        borderRadius: "20px",
                        padding: "20px",
                      }}
                      onClick={() => setDisableForm(true)}
                    >
                      {" "}
                      Cancel
                    </Button>
                  </Box>
                  <Box sx={{ margin: "10px 20px 0 5px" }}>
                    <ButtonInput label="Save" padding="20px" type="submit" />
                  </Box>
                </Box>
              ) : null}
            </Box>
          </Form>
        )}
      </Formik>
      <Box>
        <Grid container width="100%">
          <Grid item md={5} xs={12}>
            <Box>
              <Typography
                sx={{
                  fontSize: "1.2rem",
                  marginLeft: "0.5rem",
                  marginBottom: "1rem",
                }}
              >
                Change Password
              </Typography>
            </Box>
            <Formik
              initialValues={initPasswordValue}
              validationSchema={passwordValidationSchema}
              onSubmit={(values) => {
                AxiosApi.post(CHANGE_PASSWORD_URL, {
                  current_password: values.current_password,
                  new_password: values.new_password,
                }).then(() => setMessage(SUCCESS_MESSAGE));
              }}
            >
              {(formik) => (
                <Form>
                  <Box sx={{ marginBottom: "20px" }}>
                    <PasswordInput
                      name="current_password"
                      label=" Current Password"
                      md={12}
                      xs={12}
                    />
                  </Box>
                  <Box sx={{ marginBottom: "20px" }}>
                    <PasswordInput
                      name="new_password"
                      label=" New Password"
                      md={12}
                      xs={12}
                    />
                  </Box>
                  <Box sx={{ marginBottom: "20px" }}>
                    <PasswordInput
                      name="confirm_new_password"
                      label="Confirm New Password"
                      md={12}
                      xs={12}
                    />
                  </Box>
                  <ButtonInput
                    label="Change Password"
                    md={12}
                    xs={12}
                    type="submit"
                  />
                  <Typography
                    sx={{
                      marginTop: "30px",
                      marginLeft: "20px",
                      color: "green",
                    }}
                  >
                    {message}
                  </Typography>
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

function UserProfile(props) {
  const [disablePersonalForm, setDisablePersonalForm] = useState(true);

  const [user, setUser] = useState({
    id: "",
    first_name: "",
    middle_name: "",
    last_name: "",
    email: "",
    phone: "",
  });

  const AxiosApi = useAxios();

  useEffect(() => {
    AxiosApi.get("/accounts/user/").then((res) =>
      setUser(JSON.parse(JSON.stringify(res).replace(/\:null/gi, ':""')))
    );
  }, []);

  const initialPasswordValues = {
    current_password: "",
    new_password: "",
    confirm_new_password: "",
  };

  const TabProps = {
    tab_details: [
      {
        icon: (
          <ApartmentIcon
            sx={{
              marginRight: "0.5rem",
              fontSize: { xs: "1.2rem", sm: "1.5rem", md: "1.7rem" },
            }}
          />
        ),
        text: "User Profile",
      },
    ],
    children: [
      <UserProfileForm
        disableForm={disablePersonalForm}
        setDisableForm={setDisablePersonalForm}
        initValue={user}
        initPasswordValue={initialPasswordValues}
      />,
    ],
    sm: '1rem',
    md: '1.2rem'
  };

  return (
    <Box
      sx={{
        margin: { xs: "0 10px 20px 10px", sm: "0 30px 20px 30px" },
        padding: "20px 0",
        borderRadius: "10px",
        background: "rgba( 255, 255, 255, 0.1 )",

        boxShadow: "0 5px 32px 5px #e2e4f8",

        backdropFilter: "blur( 20px )",
        webkitBackdropFilter: "blur( 20px )",
        border: "1px solid rgba( 255, 255, 255, 0.18 )",
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center", marginBottom: "1rem" }}>
        <Typography
          component={"p"}
          variant={"h6"}
          sx={{
            marginRight: { xs: "1rem", md: "3rem" },
            marginLeft: { xs: "1rem", md: "3rem" },
            fontSize: { xs: "1rem", md: "1.5rem" },
          }}
        >
          User Information
        </Typography>
        {disablePersonalForm ? (
          <Button
            endIcon={<Edit />}
            variant={"contained"}
            onClick={() => setDisablePersonalForm(false)}
            sx={{
              background:
                "linear-gradient(90deg,  rgba(0,41,185,1) 0%, rgba(2,119,250,1) 100%)",
              // width: "100%",
              borderRadius: "20px",
              color: "#fff",
              height: "2.6em",
              marginLeft: "10px",
            }}
          >
            Edit
          </Button>
        ) : null}
      </Box>

      <TabView tabProps={TabProps} />
    </Box>
  );
}

export default UserProfile;
