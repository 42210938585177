import { configureStore } from "@reduxjs/toolkit";
import ManageApplicantsSlice from "../reducers/ManageApplicants-Slice";
import ApplicantLoginSlice from "../reducers/ApplicantLogin-Slice";
import ManageUserSlice from "../reducers/ManageUser-Slice"
import ThemeSlice from "../reducers/Theme-Slice";
import CurrentEmployee from "../reducers/CurrentEmployee-Slice"

const store = configureStore({
    reducer: {
        ManageApplicants: ManageApplicantsSlice.reducer,
        ApplicantLogin: ApplicantLoginSlice.reducer,
        ManageUsers: ManageUserSlice.reducer,
        ThemeSlice: ThemeSlice.reducer,
        CurrentEmployee: CurrentEmployee.reducer
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
});

export default store;