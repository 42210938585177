import React, { useEffect, useState } from "react";

import { Grid, Box, Typography, Button } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import DescriptionIcon from "@mui/icons-material/Description";

import TabView from "../../components/Tabs/Tabs";

import {
  getApplicationData,
  getDocumentData,
} from "../../redux/thunks/ApplicantLogin-thunk";
import useAuth from "../../hooks/useAuth";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { Edit } from "@mui/icons-material";
import EditOffIcon from "@mui/icons-material/EditOff";
import Logo from "../../assets/images/loader.gif";
import PersonalForm from "./PersonalForm";
import DocumentForm from "./DocumentForm";
import { ApplicantLoginActions } from '../../redux/reducers/ApplicantLogin-Slice'


function DriveRApplication(props) {

  const [imgLoaded, setImgLoaded] = useState(false)


  const dispatch = useDispatch();
  const { auth } = useAuth();

  const location = useLocation();

  const [disablePersonalForm, setDisablePersonalForm] = useState(
    auth.role[0] === "A" ? false : true
  );

  const companyLogo = useSelector((state) => state.ThemeSlice?.companyLogo);

  useEffect(() => {
    if (companyLogo) setImgLoaded(true)
  }, [companyLogo])

  useEffect(() => {

    dispatch(
      getApplicationData(
        location?.state?.user ? location?.state?.user : "Applicant"
      )
    );

    return () => {
      dispatch(ApplicantLoginActions.applicationData(''))
      dispatch(ApplicantLoginActions.documentData({
        medical_certificate: {
          id: '',
          document_filename: '',
        },
        birth_certificate: {
          id: '',
          document_filename: '',
        },
        license: {
          id: '',
          document_filename: '',
          expiration_date: ''
        },
        passport: {
          id: '',
          document_filename: '',
          expiration_date: ''
        },
        ssn: {
          id: '',
          document_filename: '',
        },
      },))
    }
  }, []);

  const initialValuesPersonal = {
    user: {
      first_name: "",
      middle_name: "",
      last_name: "",
      email: "",
      phone: "",
    },
    pseudo_name: "",
    mothers_maiden_name: "",
    other_email: "",
    dob: "",
    country: "",
    current_address: {
      line_1: "",
      line_2: "",
      city: "",
      state: "",
      country: "",
      zipcode: "",
      address_type: "current",
      residence_from: "",
      residence_to: "",
    },
    previous_address: [{
      line_1: "",
      line_2: "",
      city: "",
      state: "",
      country: "",
      zipcode: "",
      residence_from: "",
      residence_to: "",
    }],
    permanent_address: {
      line_1: "",
      line_2: "",
      city: "",
      state: "",
      country: "",
      zipcode: "",
      address_type: "permanent",
      residence_from: "",
      residence_to: "",
    },
  };

  const [initValue, setInitValue] = useState(initialValuesPersonal);

  const applicationData = useSelector(
    (state) => state.ApplicantLogin.applicationData
  );
  const documentData = useSelector(
    (state) => state.ApplicantLogin.documentData
  );



  useEffect(() => {
    if (applicationData) {
      setInitValue(applicationData);
      dispatch(getDocumentData(applicationData.id));
    }
  }, [applicationData]);

  const TabProps = {
    tab_details: [
      {
        icon: (
          <PersonIcon
            sx={{
              marginRight: "0.5rem",
              fontSize: { xs: "1.2rem", sm: "1.5rem", md: "1.7rem" },
            }}
          />
        ),
        text: "Personal Information",
      },
      {
        icon: (
          <DescriptionIcon
            sx={{
              marginRight: "0.5rem",
              fontSize: { xs: "1.2rem", sm: "1.5rem", md: "1.7rem" },
            }}
          />
        ),
        text: "Documents",
      },
    ],
    children: [
      <PersonalForm
        auth={auth}
        initValue={initValue}
        disableForm={disablePersonalForm}
      />,
      <DocumentForm
        auth={auth}
        disableForm={disablePersonalForm}
        applicantData={initValue}
        documentData={documentData}
      />,
      ,
    ],
  };

  return (
    <Box sx={{ padding: "0 1rem 1rem 1rem" }}>
      {auth.role[0] === "A" ? (
        !imgLoaded ?
          <Typography sx={{
            padding: "20px",
            width: "200px",
            maxHeight: "200px",
          }} >Loading...</Typography>
          :
          <Box
            component="img"
            src={companyLogo}
            sx={{
              padding: "20px",
              width: { xs: "170px", md: "200" },
              maxHeight: "200px",
            }}
          />

      ) : null}

      <Box
        sx={{
          background: "rgba( 255, 255, 255, 0.1 )",

          boxShadow: "0 5px 32px 5px #e2e4f8",

          backdropFilter: "blur( 20px )",
          webkitBackdropFilter: "blur( 20px )",
          borderRadius: "10px",
          border: "1px solid rgba( 255, 255, 255, 0.18 )",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            marginBottom: "1rem",
            paddingTop: "20px",
          }}
        >
          <Typography
            component={"p"}
            variant={"h6"}
            sx={{
              marginRight: { xs: "0", md: "3rem" },
              marginLeft: { xs: "1rem", md: "3rem" },
              fontSize: { xs: "1rem", md: "1.5rem" },
            }}
          >
            Fill the forms to continue
          </Typography>
          {disablePersonalForm ? (
            <Button
              endIcon={<Edit />}
              variant={"contained"}
              onClick={() => setDisablePersonalForm(false)}
              sx={{
                background:
                  "linear-gradient(90deg,  rgba(0,41,185,1) 0%, rgba(2,119,250,1) 100%)",
                // width: "100%",
                borderRadius: "20px",
                color: "#fff",
                height: "2.6em",
                marginLeft: "6px",
                fontSize: {
                  xs: "0.8rem",
                  md: "1rem",
                },
              }}
            >
              Edit
            </Button>
          ) : auth.role[0] !== "A" ? (
            <>
              <Button
                endIcon={<EditOffIcon />}
                variant={"contained"}
                onClick={() => setDisablePersonalForm(true)}
                sx={{
                  background:
                    "linear-gradient(90deg,  rgba(0,41,185,1) 0%, rgba(2,119,250,1) 100%)",
                  // width: "100%",
                  borderRadius: "20px",
                  color: "#fff",
                  height: "2.6em",
                  display: { xs: "none", md: "flex" },
                  marginLeft: "10px",
                  fontSize: {
                    xs: "0.6rem",
                    md: "1rem",
                  },
                }}
              >
                Cancel Edit
              </Button>
              <EditOffIcon
                sx={{ marginLeft: "20px", display: { xs: "flex", md: "none" } }}
                onClick={() => setDisablePersonalForm(true)}
              />
            </>
          ) : null}
        </Box>

        <TabView tabProps={TabProps} />
      </Box>
    </Box>
  );
}

export default DriveRApplication;
