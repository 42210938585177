import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import AddCircleIcon from '@mui/icons-material/AddCircle';




function HeaderTab({ headerTabProp, setSearch, setvalue, setPage, page }) {
    const { startTitle, endTitle } = headerTabProp
    return (
        <Grid container sx={{ justifyContent: { md: 'space-between' }, display: { xs: 'block', md: 'flex' }, alignItems: 'center' }} spacing={1} >

            <Grid item>
                <Typography variant='h5' component='p' sx={{ fontSize: { xs: '1rem', sm: '1rem', md: '1.2rem' }, fontWeight: '500' }} >
                    {startTitle}
                </Typography>
            </Grid>
            <Grid item md={4}>
                <Box sx={{ padding: { xs: '3px 10px', md: '5px 10px' }, display: 'flex', alignItems: 'center', backgroundColor: '#c1cbd6', height:'2rem', borderRadius: '7px' }}  >
                    <SearchIcon sx={{ marginRight: '7px',fontSize:'1rem' }} />
                    <InputBase sx={{ width: '100%' }} placeholder="Search..." onChange={(e) => {
                        if (page > 1) setPage(1);
                        setSearch(e.target.value)
                    }
                    }
                    />
                </Box>
            </Grid>
            <Grid item>
            {endTitle&& <Button sx={{ display: { xs: 'none', md: 'flex' }, textTransform: 'capitalize' }} endIcon={<AddCircleIcon />} onClick={() => setvalue(1)}>
                    {endTitle}
                </Button>}
            </Grid>
        </Grid>
    )
}

export default HeaderTab