
import {
  Button,
  Tooltip,
} from "@mui/material";
import React from "react";
import DeclineReasonModel from './DeclineReasonModel'
import { ThemeActions } from '../../redux/reducers/Theme-Slice'
import { useDispatch } from "react-redux";



function ModalButton() {
  const dispatch = useDispatch()
  return (
    <>
      <Tooltip onClick={() => dispatch(ThemeActions.setDecline_model(true))} title="Cancel/Not Interested">
        <Button
          variant="outlined"
          sx={{
            fontSize: { xs: "0.7rem", md: "1rem" },
            color: "#d32f2f",
            border: "1px solid #d32f2f",
            borderRadius: "20px",
            width: '100%',
          }}
        >
          Cancel/Not Interested
        </Button>
      </Tooltip>
      <DeclineReasonModel />
    </>
  );
}

export default ModalButton;
